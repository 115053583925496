import styled, { css } from 'styled-components'

import {
  File as FileInit,
  Eye as EyeInit,
  Download as DownloadInit,
  Trash as TrashInit,
  IconButton,
} from '@gmini/ui-kit'

export const ActionPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 0 10px;
  position: absolute;
  opacity: 0;
  bottom: 46px;
  z-index: 1;
`

export const ActionButton = styled(IconButton)`
  && {
    background: rgba(0, 0, 0, 0.25);

    &:hover {
      background: rgba(0, 0, 0, 0.5);
    }
  }
`

const ActionButtonIcon = css`
  && path {
    fill: #fff;
  }
`

export const Eye = styled(EyeInit)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 42px;
  right: 0;
  width: 48px;
  height: 48px;
  margin: auto;
  cursor: pointer;
  opacity: 0;
  z-index: 100000;

  & path {
    fill: rgba(255, 255, 255, 0.5);
  }
`
export const Download = styled(DownloadInit)`
  ${ActionButtonIcon}
`
export const Trash = styled(TrashInit)`
  ${ActionButtonIcon}
`

export const Image = styled.img`
  width: 100%;
  height: 108px;
  border-radius: 2px;
  position: relative;
`

export const AttachmentInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 42px;
  background: #fff;
  border-radius: 2px;
  padding: 4px;
`

export const AttachmentInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

export const AttachmentInfoText = styled.div`
  width: 116px;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
`

export const AttachmentInfoTextCutInvisible = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: fit-content;
`

export const AttachmentInfoTextCut = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 16px;
`

export const AttachmentInfoTitle = styled(AttachmentInfoText)`
  color: #000335;
`

export const AttachmentInfoUploadAt = styled(AttachmentInfoText)`
  color: #80819a;
`

export const AttachmentInfoSelect = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
`

export const Container = styled.div`
  width: 150px;
  height: 150px;
  position: relative;
  display: flex;
  flex-direction: column;
  background: #e5e7f1;
  border: 1px solid #e5e7f1;
  border-radius: 4px;

  &:hover {
    ${ActionPanel} {
      opacity: 1;
    }

    ${Eye} {
      opacity: 1;
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 108px;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 2px;
    }
  }
`

export const File = styled(FileInit)`
  width: 75px;
  height: 75px;

  && path {
    stroke: #e5e7f1;
  }
`

export const FileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 108px;
  gap: 5px 0;
  color: rgba(53, 59, 96, 0.4);
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.15px;
  text-transform: uppercase;
`
