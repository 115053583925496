import { useStore } from 'effector-react'
import { AttributesService } from '@gmini/helpers'

import {
  CapitalizedTextWrapper,
  Tooltip,
  WarningOutlinedRotate,
} from '@gmini/ui-kit'

import { useMemo } from 'react'

import { SimpleBubble } from '../SimpleBubble'

import {
  ItemAttributeValueWrapper,
  TooltipItem,
  TooltipItemName,
  TooltipItemValue,
  TooltipItemWrapper,
  TooltipTitleText,
  TooltipTitleWrapper,
} from './RenderAttributes.styled'

const ATTRIBUTES_TEXT = 'Атрибуты'

type RenderAttributesProps = {
  attributeValueIds: number[]
  attributeValueById$: AttributesService['attributeValueById$']
  hideDeletedAttributes?: boolean
}

export const RenderAttributes = ({
  attributeValueIds,
  attributeValueById$,
  hideDeletedAttributes,
}: RenderAttributesProps) => {
  const attributeValueById = useStore(attributeValueById$)

  const attributeValues = useMemo(() => {
    const values = attributeValueIds.map(valueId => attributeValueById[valueId])
    return hideDeletedAttributes ? values.filter(v => !v?.deleted) : values
  }, [attributeValueById, attributeValueIds, hideDeletedAttributes])

  const tooltipInfo = useMemo(
    () =>
      Object.values(
        attributeValues.reduce(
          (acc: Record<number, { name: string; values: string[] }>, item) => {
            if (!item) {
              return acc
            }

            const attributeId = item?.attribute.id
            if (!acc[attributeId]) {
              acc[attributeId] = {
                name: item.attribute.name,
                values: [],
              }
            }

            acc[attributeId].values.push(item.name)

            return acc
          },
          {},
        ),
      ),
    [attributeValues],
  )

  return (
    <ItemAttributeValueWrapper>
      {attributeValues.map(
        value =>
          value && (
            <Tooltip
              key={value.id}
              styleContent={{
                display: 'inline-block',
              }}
              style={{ padding: '8px 12px' }}
              title={
                <>
                  <TooltipTitleWrapper>
                    <WarningOutlinedRotate color='rgba(255, 255, 255, 0.25)' />
                    <TooltipTitleText>{ATTRIBUTES_TEXT}</TooltipTitleText>
                  </TooltipTitleWrapper>
                  <TooltipItemWrapper>
                    {tooltipInfo.map(attribute => (
                      <TooltipItem key={attribute.name}>
                        <CapitalizedTextWrapper>
                          <TooltipItemName>{attribute.name}:</TooltipItemName>
                        </CapitalizedTextWrapper>
                        <TooltipItemValue>
                          {attribute.values.join(', ')}
                        </TooltipItemValue>
                      </TooltipItem>
                    ))}
                  </TooltipItemWrapper>
                </>
              }
            >
              <SimpleBubble title={value.name} withoutTooltip />
            </Tooltip>
          ),
      )}
    </ItemAttributeValueWrapper>
  )
}
