import * as smApi from '@gmini/sm-api-sdk'

import { difference, equals } from 'ramda'

import {
  AssigneeListItem,
  DateFilterItemsCode,
  IssueListFilterDeadlineOptionCode,
  PreparedIssueStatus,
  deadlineFilterMap,
  issueDateFilterMap,
} from '@gmini/helpers'

import { combine } from 'effector'

import { filterService } from '../components/issueFilter.store'
import {
  AppliedFilters,
  IssueListFilters,
} from '../components/organisms/IssueList/IssueList.types'

const {
  appliedFilters: { appliedFilters$ },
  savedFilters: { savedFilters$ },
} = filterService

type ParseFilters = {
  filters: Partial<{
    statuses: string[]
    updatedDateCode: DateFilterItemsCode | null
    createdDateCode: DateFilterItemsCode | null
    deadlineCode: IssueListFilterDeadlineOptionCode | null
    authorIds: string[]
    assignees: AssigneeListItem[]
  }>
  issueStatusList: PreparedIssueStatus[]
  userList: smApi.Auth.UserData[]
}

export const parsedFilters = ({
  filters,
  issueStatusList,
  userList,
}: ParseFilters) => {
  const {
    statuses,
    updatedDateCode,
    createdDateCode,
    deadlineCode,
    authorIds,
  } = filters

  const status = statuses
    ? statuses?.reduce((acc: AppliedFilters['status'], statusName) => {
        const filter = issueStatusList.find(item => item.status === statusName)
        if (filter) {
          acc.push({ name: filter.name, bgColor: filter.bgColor })
        }
        return acc
      }, [])
    : []

  const updatedAt = updatedDateCode ? issueDateFilterMap[updatedDateCode] : ''

  const createdAt = createdDateCode ? issueDateFilterMap[createdDateCode] : ''

  const deadline = deadlineCode ? deadlineFilterMap[deadlineCode] : ''

  const authorValue = authorIds
    ? authorIds.map(author => {
        const filter = userList.find(user => user.id === author)
        return filter ? filter.name : ''
      })
    : []

  const assignees = filters.assignees
    ? filters.assignees.map(el => el.label)
    : []

  return {
    statuses: status,
    updatedDateCode: updatedAt,
    createdDateCode: createdAt,
    deadlineCode: deadline,
    authorIds: authorValue,
    assignees,
  }
}

const existingFilters: (keyof Omit<
  IssueListFilters,
  | 'filter'
  | 'createdDateRange'
  | 'updatedDateRange'
  | 'deadlineRange'
  | 'sortByOperator'
  | 'sortByFieldName'
>)[] = [
  'statuses',
  'authorIds',
  'assignees',
  'createdDateCode',
  'updatedDateCode',
  'deadlineCode',
  'attributeValueIds',
]

const joined = <T extends { source: string; assigneeId: string }[]>(array: T) =>
  array.map(el => `${el.assigneeId}_${el.source}`)
const compareTwoArrays = (
  a1: Array<string | number>,
  a2: Array<string | number>,
) => {
  const elementsUniqueToFirstArray = difference(a1, a2)
  const elementsUniqueToSecondArray = difference(a2, a1)

  const isEqual =
    elementsUniqueToFirstArray.length === 0 &&
    elementsUniqueToSecondArray.length === 0

  return isEqual
}

export const isFilterExist$ = combine(
  { appliedFilters$, savedFilters$ },
  ({ appliedFilters$: appliedFilter, savedFilters$: savedFilters }) => {
    const appliedAssignees = joined(appliedFilter.assignees)
    const a = savedFilters.map(savedFilter => {
      const b = existingFilters.map(existingFilter => {
        if (existingFilter === 'assignees') {
          const savedAssignee = joined(savedFilter.assignees || [])
          return compareTwoArrays(savedAssignee, appliedAssignees)
        } else if (existingFilter === 'statuses') {
          return compareTwoArrays(
            savedFilter.statuses || [],
            appliedFilter.statuses,
          )
        } else if (existingFilter === 'authorIds') {
          return compareTwoArrays(
            savedFilter.authorIds || [],
            appliedFilter.authorIds,
          )
        } else if (existingFilter === 'attributeValueIds') {
          return compareTwoArrays(
            savedFilter.attributeValueIds || [],
            appliedFilter.attributeValueIds,
          )
        }
        return equals(
          savedFilter[existingFilter],
          appliedFilter[existingFilter],
        )
      })
      return b.every(Boolean)
    })
    return a.some(Boolean)
  },
)
