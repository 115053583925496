import styled from 'styled-components'
import { Checkbox as CheckboxInit } from '@gmini/ui-kit'

type CheckboxProps = {
  visible?: boolean
}

export const Checkbox = styled(CheckboxInit)`
  opacity: ${(props: CheckboxProps) => (props.visible ? 1 : 0)};
`
