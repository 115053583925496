import styled, { css } from 'styled-components'

type HorizontalLineProps = {
  mt?: string
  mb?: string
}

export const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background: #e5e7f1;

  ${(props: HorizontalLineProps) =>
    props.mt &&
    css`
      margin-top: ${props.mt};
    `}

  ${(props: HorizontalLineProps) =>
    props.mt &&
    css`
      margin-bottom: ${props.mb};
    `}
`
