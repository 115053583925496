import styled from 'styled-components'
import { IconButton } from '@gmini/ui-kit'

type StyledIconButtonProps = {
  disabled?: boolean
}

export const StyledIconButton = styled(IconButton)`
  & rect {
    fill: #9a9db0;
    transition: all 0.2s ease-out;
    ${({ disabled }: StyledIconButtonProps) =>
      disabled ? 'fill: #e1e2e7;' : ''}
  }
`
