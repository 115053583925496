import styled, { css } from 'styled-components'

type RenderAssigneesWrapperProps = {
  maxWidth?: string
}

export const RenderAssigneesWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0 5px;

  ${(props: RenderAssigneesWrapperProps) =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
    `}
`

type FirstAssigneeProps = {
  crossOuted?: boolean
  indented?: boolean
}

export const FirstAssignee = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  line-height: 21px;

  ${(props: FirstAssigneeProps) =>
    props.crossOuted &&
    css`
      && span {
        text-decoration: line-throw;
      }
    `}

  ${(props: FirstAssigneeProps) =>
    props.indented &&
    css`
      gap: 0 5px;
    `}
`
