import { Eye } from '@gmini/ui-kit'
import styled from 'styled-components'

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
`

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const StyledEye = styled(Eye)`
  margin-left: 8px;
`
