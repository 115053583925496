import styled, { css } from 'styled-components'

type FilterUserItemStyledProps = {
  disabled?: boolean
}

export const FilterUserItemStyled = styled.label`
  margin-right: 4px;
  gap: 12px;
  display: flex;
  align-items: center;
  padding: 6px 46px 6px 21px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #000335;
  cursor: pointer;

  ${(props: FilterUserItemStyledProps) =>
    props.disabled &&
    css`
      cursor: default;
      color: rgba(0, 3, 53, 0.3);
    `}
`
