import styled, { css } from 'styled-components'

export const AppliedFiltersScrollableWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: -10px;
  width: 100%;
  height: 100%;
`
export const AppliedFiltersScrollableContent = css`
  display: flex;
  align-items: center;
  gap: 0 8px;
  padding-bottom: 10px;
`

type ResetAllButtonWrapperProps = {
  hidden?: boolean
}

export const ResetAllButtonWrapper = styled.div`
  position: sticky;
  right: 0;
  background: #fff;
  padding-left: 8px;

  ${(props: ResetAllButtonWrapperProps) =>
    props.hidden &&
    css`
      display: none;
    `}
`
