import * as ismApi from '@gmini/ism-api-sdk'

// eslint-disable-next-line @typescript-eslint/ban-types
export const sortByFieldEntity = <T extends {}>(
  list: T[],
  params: { sortByFieldName: keyof T; sortByOperator: ismApi.SortByOperator },
) =>
  list.sort((a, b) => {
    const aFieldValue = a[params.sortByFieldName]
    const bFieldValue = b[params.sortByFieldName]

    if (
      params.sortByOperator === ismApi.SortByOperator.ASC &&
      typeof aFieldValue === 'string' &&
      typeof bFieldValue === 'string'
    ) {
      return aFieldValue.localeCompare(bFieldValue)
    }

    if (
      params.sortByOperator === ismApi.SortByOperator.DESC &&
      typeof aFieldValue === 'string' &&
      typeof bFieldValue === 'string'
    ) {
      return bFieldValue.localeCompare(aFieldValue)
    }

    if (
      params.sortByOperator === ismApi.SortByOperator.ASC &&
      typeof aFieldValue === 'number' &&
      typeof bFieldValue === 'number'
    ) {
      return aFieldValue - bFieldValue
    }

    if (
      params.sortByOperator === ismApi.SortByOperator.ASC &&
      typeof aFieldValue === 'number' &&
      typeof bFieldValue === 'number'
    ) {
      return bFieldValue - aFieldValue
    }

    return 1
  })
