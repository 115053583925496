import { AssigneeListItem } from '@gmini/helpers'
import { AllPeople, Contractor, LongText } from '@gmini/ui-kit'
import * as chmApi from '@gmini/chm-api-sdk'
import { assertNever } from '@gmini/utils'

import { AssigneeCounter } from '../AssigneeCounter'

import { FirstAssignee, RenderAssigneesWrapper } from './RenderAssignees.styled'

type RenderAssigneesProps = {
  assignees: AssigneeListItem[]
  maxWidth?: string
  isCrossOutedFirstAssigneeText?: (assignee: AssigneeListItem) => boolean
  isCrossOutedTooltipText?: (assignee: AssigneeListItem) => boolean
}

export const RenderAssignees = ({
  assignees,
  maxWidth,
  isCrossOutedFirstAssigneeText,
  isCrossOutedTooltipText,
}: RenderAssigneesProps) => {
  const firstAssignee = assignees[0]
  const extraAssignees = assignees.slice(1)

  let firstAssigneeIcon = null

  switch (firstAssignee?.source) {
    case chmApi.AssigneeSource.USER: {
      firstAssigneeIcon = null
      break
    }
    case chmApi.AssigneeSource.ROLE: {
      firstAssigneeIcon = <AllPeople width='20px' height='20px' />
      break
    }
    case chmApi.AssigneeSource.COMPANY: {
      firstAssigneeIcon = <Contractor width='20px' height='20px' />
      break
    }
    default: {
      if (firstAssignee) {
        return assertNever('Unexpected assignee source', firstAssignee.source)
      }
    }
  }

  return (
    <RenderAssigneesWrapper maxWidth={maxWidth}>
      {firstAssignee && (
        <FirstAssignee
          crossOuted={isCrossOutedFirstAssigneeText?.(firstAssignee)}
          indented={!!firstAssigneeIcon}
        >
          {firstAssigneeIcon}
          <LongText partSize={20} withoutRightText>
            {firstAssignee.label}
          </LongText>
        </FirstAssignee>
      )}
      {extraAssignees.length !== 0 && (
        <AssigneeCounter
          assignees={extraAssignees}
          isCrossOutedTooltipText={isCrossOutedTooltipText}
        />
      )}
    </RenderAssigneesWrapper>
  )
}
