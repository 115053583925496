import { Skeleton } from '@mui/material'

type FilterItemStubProps = {
  count: number
}

export const FilterItemSkeleton = ({ count }: FilterItemStubProps) => (
  <>
    {Array.from(Array(count)).map((_item, idx) => {
      const width = 100 - Math.round(Math.random() * 70)
      // eslint-disable-next-line react/no-array-index-key
      return <Skeleton width={width} height={36} key={idx} />
    })}
  </>
)
