import { StatusContainer, StatusIndicator } from '@gmini/components'
import { useCallback, useMemo } from 'react'

import { v4 as uuid } from 'uuid'

import { FilterItem, FilterWrapper } from '../../atoms'

import { StatusItem } from './FilterStatus.types'

type DataTestProps = {
  filterItem: { name: string }
}

type FilterStatusProps<S> = {
  statusList: S[]
  allowedItems: string[]
  urlKey: string
  disabled?: boolean
  loading?: boolean
  countSkeletonItems?: number
  onChange: (value: Record<string, string>) => void
  value: string[]
  dataTestIdFor?: Partial<DataTestProps>
}

export const FilterStatus = <S extends StatusItem>({
  allowedItems,
  statusList,
  urlKey,
  disabled,
  loading,
  countSkeletonItems,
  onChange,
  value,
  dataTestIdFor,
}: FilterStatusProps<S>) => {
  const preparedStatusList = useMemo(
    () =>
      statusList.map(item => ({
        ...item,
        uniqId: uuid(),
        selected: value.some(status => status === item.status),
        disabled:
          disabled || !allowedItems?.some(status => status === item.status),
      })),
    [allowedItems, disabled, statusList, value],
  )

  const renderName = useCallback(
    (item: (typeof preparedStatusList)[0]) => (
      <StatusContainer>
        <StatusIndicator
          style={{
            background: item.color,
          }}
        />
        {item.name}
      </StatusContainer>
    ),
    [],
  )

  const filterName = dataTestIdFor?.filterItem?.name

  return (
    <FilterWrapper
      loading={!statusList.length && loading}
      countSkeletonItems={countSkeletonItems}
    >
      {preparedStatusList.map(item => (
        <FilterItem
          key={item.uniqId}
          item={item}
          onChange={el => onChange({ [urlKey]: el.status })}
          renderName={renderName}
          dataTestId={
            filterName && `filter${filterName}_${item.status.toLowerCase()}`
          }
        />
      ))}
    </FilterWrapper>
  )
}
