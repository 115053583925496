import { AppliedFilters } from '@gmini/components'
import {
  ASSIGNEES_TL,
  ATTRIBUTES_TL,
  CREATED_DATE_CODE_TL,
  IssueTemplateListFiltersType,
  IssueTemplateUrlKeyType,
  AUTHOR_IDS_TL,
  UPDATED_DATE_CODE_TL,
  appliedDateFilterItemsCodesMap,
  useAppliedFilterAssigneeData,
  useAppliedFilterAttributes,
  useAppliedFilterDateData,
  useAppliedFilterUserData,
} from '@gmini/helpers'
import { useStore } from 'effector-react'
import { useMemo } from 'react'

import { SHORT_DISPLAY_DATE_FORMAT } from '../../../constants'
import { attributesService } from '../../attribute.store'
import { filterService } from '../../issueTemplateFilter.store'
import { projectUserList$ } from '../../user.store'
import { seoEventManager } from '../../../config'

type IssueTemplateListAppliedFiltersProps = {
  onChange?: (filters: IssueTemplateListFiltersType) => void
}

export const IssueTemplateListAppliedFilters = ({
  onChange,
}: IssueTemplateListAppliedFiltersProps) => {
  const {
    appliedFilters: { useUpdateFilter, appliedFilters$ },
  } = filterService
  const { update } = useUpdateFilter()
  const userList = useStore(projectUserList$)
  const filters = useStore(appliedFilters$)
  const attributeValueById = useStore(attributesService.attributeValueById$)

  const preparedAuthors = useAppliedFilterUserData({
    name: 'Автор',
    urlKey: AUTHOR_IDS_TL,
    userList,
    value: filters.authorIds || [],
  })

  const preparedAssignee = useAppliedFilterAssigneeData({
    name: 'Назначено на',
    urlKey: ASSIGNEES_TL,
    value: filters.assignees,
  })

  const preparedAttributes = useAppliedFilterAttributes({
    urlKey: ATTRIBUTES_TL,
    value: filters.attributeValueIds.map(
      attributeId => attributeValueById[attributeId],
    ),
  })

  const preparedUpdatedDate = useAppliedFilterDateData({
    dateFormat: SHORT_DISPLAY_DATE_FORMAT,
    urlKeyCode: UPDATED_DATE_CODE_TL,
    name: 'Изменен',
    value: filters.updatedDateCode ? [filters.updatedDateCode] : [],
    valueName: filters.updatedDateCode
      ? appliedDateFilterItemsCodesMap[filters.updatedDateCode]
      : '',
  })

  const preparedCreatedDate = useAppliedFilterDateData({
    dateFormat: SHORT_DISPLAY_DATE_FORMAT,
    urlKeyCode: CREATED_DATE_CODE_TL,
    name: 'Создан',
    value: filters.createdDateCode ? [filters.createdDateCode] : [],
    valueName: filters.createdDateCode
      ? appliedDateFilterItemsCodesMap[filters.createdDateCode]
      : '',
  })

  const onChangeHandler = (filter: IssueTemplateUrlKeyType) => {
    const [[key]] = Object.entries(filter)
    update({ [key]: null })
    const filters = appliedFilters$.getState()
    onChange?.(filters)
  }

  const appliedFilters = useMemo(
    () =>
      [
        preparedUpdatedDate,
        preparedCreatedDate,
        preparedAuthors,
        preparedAssignee,
        ...preparedAttributes,
      ].filter(({ value }) => value.length),
    [
      preparedUpdatedDate,
      preparedCreatedDate,
      preparedAuthors,
      preparedAssignee,
      preparedAttributes,
    ],
  )

  return (
    <AppliedFilters
      onChange={onChangeHandler}
      items={appliedFilters}
      seoEventManager={seoEventManager}
    />
  )
}
