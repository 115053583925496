import qs, { ParseOptions, ParsedQuery } from 'query-string'

export function queryParser({
  key,
  options,
}: {
  key?: string
  options?: ParseOptions
}):
  | string
  | number
  | boolean
  | ParsedQuery<string | number | boolean>
  | undefined {
  const queryStringObject = qs.parse(window.location.search, options)
  if (queryStringObject === null) {
    return undefined
  }

  if (!key) {
    return queryStringObject
  }

  const value = queryStringObject[key]

  if (typeof value === 'string') {
    return value
  }

  if (typeof value === 'number') {
    return value
  }
  if (typeof value === 'boolean') {
    return value
  }

  if (
    Array.isArray(value) &&
    value.length > 0 &&
    typeof value[0] === 'string'
  ) {
    return value[0]
  }

  return undefined
}
