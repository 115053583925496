import styled from 'styled-components'

export const LinkedEntityTableItem = styled.div`
  display: flex;
  align-items: center;
`

export const LinkedEntityTableItemName = styled.div`
  white-space: nowrap;
`
