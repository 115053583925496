import { ApiCallService } from '@gmini/api-call-service'
import * as smApi from '@gmini/sm-api-sdk'
import { createStore } from 'effector'

export function createProjectListService({
  fetchProjectListApi,
}: {
  fetchProjectListApi: ApiCallService<
    undefined,
    {
      list: smApi.Project[]
      total: number
    }
  >
}) {
  const fetchProjectList = fetchProjectListApi.createContext()
  const fetchProjectListPending$ = fetchProjectList.pending$

  const projectList$ = createStore<smApi.Project[]>([]).on(
    fetchProjectList.doneData,
    (state, result) => result.list,
  )

  return {
    fetchProjectList,
    fetchProjectListPending$,
    projectList$,
  }
}
