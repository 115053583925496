import { ReactNode } from 'react'

import { Container, Popover, MenuItem } from './DropdownMenu.styled'

export type DropdownMenuProps = {
  anchorEl?: Element | null
  handleClose?: () => void
  items: MenuItem[]
}

export type MenuItem = {
  name: string
  onClick?: (name: string) => void
  disabled?: boolean
  icon?: ReactNode
}

export const DropdownMenu = ({
  anchorEl,
  handleClose,
  items,
}: DropdownMenuProps) => {
  const openPopover = Boolean(anchorEl)

  return (
    <Popover
      open={openPopover}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Container>
        {items.map(({ name, disabled, onClick, icon }, idx) => (
          <MenuItem
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            disabled={disabled}
            onClick={() => onClick?.(name)}
          >
            {icon}
            {name}
          </MenuItem>
        ))}
      </Container>
    </Popover>
  )
}
