import { AppliedFilters } from '@gmini/components'
import {
  ASSIGNEES_IL,
  ATTRIBUTES_IL,
  CREATED_DATE_CODE_IL,
  DEADLINE_CODE_IL,
  IssueListFiltersType,
  IssueUrlKeyType,
  AUTHOR_IDS_IL,
  STATUSES_IL,
  UPDATED_DATE_CODE_IL,
  appliedDateFilterItemsCodesMap,
  appliedDeadlineOptionCodeMap,
  useAppliedFilterAssigneeData,
  useAppliedFilterAttributes,
  useAppliedFilterDateData,
  useAppliedFilterStatusData,
  useAppliedFilterUserData,
} from '@gmini/helpers'
import { useStore } from 'effector-react'
import { useMemo } from 'react'

import { SHORT_DISPLAY_DATE_FORMAT } from '../../../constants'

import { issueStatusList$ } from '../../issueStatus.store'

import { projectUserList$ } from '../../user.store'

import { filterService } from '../../issueFilter.store'

import { attributesService } from '../../attribute.store'
import { seoEventManager } from '../../../config'

type IssueListAppliedFiltersProps = {
  onChange?: (filters: IssueListFiltersType) => void
}

export const IssueListAppliedFilters = ({
  onChange,
}: IssueListAppliedFiltersProps) => {
  const {
    appliedFilters: { useUpdateFilter, appliedFilters$ },
  } = filterService
  const issueStatusList = useStore(issueStatusList$)
  const userList = useStore(projectUserList$)
  const filters = useStore(appliedFilters$)
  const attributeValueById = useStore(attributesService.attributeValueById$)

  const { update } = useUpdateFilter()

  const preparedIssueStatuses = useAppliedFilterStatusData({
    name: 'Статус',
    urlKey: STATUSES_IL,
    statusList: issueStatusList,
    value: filters.statuses,
  })

  const preparedAuthors = useAppliedFilterUserData({
    name: 'Автор',
    urlKey: AUTHOR_IDS_IL,
    userList,
    value: filters.authorIds || [],
  })

  const preparedAssignee = useAppliedFilterAssigneeData({
    name: 'Назначено на',
    urlKey: ASSIGNEES_IL,
    value: filters.assignees,
  })

  const preparedAttributes = useAppliedFilterAttributes({
    urlKey: ATTRIBUTES_IL,
    value: filters.attributeValueIds
      ? filters.attributeValueIds.map(
          attributeId => attributeValueById[attributeId],
        )
      : [],
  })

  const preparedUpdatedDate = useAppliedFilterDateData({
    dateFormat: SHORT_DISPLAY_DATE_FORMAT,
    urlKeyCode: UPDATED_DATE_CODE_IL,
    name: 'Изменен',
    value: filters.updatedDateCode ? [filters.updatedDateCode] : [],
    valueName: filters.updatedDateCode
      ? appliedDateFilterItemsCodesMap[filters.updatedDateCode]
      : '',
  })

  const preparedCreatedDate = useAppliedFilterDateData({
    dateFormat: SHORT_DISPLAY_DATE_FORMAT,
    urlKeyCode: CREATED_DATE_CODE_IL,
    name: 'Создан',
    value: filters.createdDateCode ? [filters.createdDateCode] : [],
    valueName: filters.createdDateCode
      ? appliedDateFilterItemsCodesMap[filters.createdDateCode]
      : '',
  })

  const preparedDeadline = useAppliedFilterDateData({
    dateFormat: SHORT_DISPLAY_DATE_FORMAT,
    urlKeyCode: DEADLINE_CODE_IL,
    name: 'Срок',
    value: filters.deadlineCode ? [filters.deadlineCode] : [],
    valueName: filters.deadlineCode
      ? appliedDeadlineOptionCodeMap[filters.deadlineCode]
      : '',
  })

  const onChangeHandler = (filter: IssueUrlKeyType) => {
    const [[key]] = Object.entries(filter)
    update({ [key]: null })
    const filters = appliedFilters$.getState()
    onChange?.(filters)
  }

  const appliedFilters = useMemo(
    () =>
      [
        preparedIssueStatuses,
        preparedUpdatedDate,
        preparedCreatedDate,
        preparedAuthors,
        preparedAssignee,
        preparedDeadline,
        ...preparedAttributes,
      ].filter(({ value }) => value.length),
    [
      preparedIssueStatuses,
      preparedUpdatedDate,
      preparedCreatedDate,
      preparedAuthors,
      preparedAssignee,
      preparedDeadline,
      preparedAttributes,
    ],
  )
  return (
    <AppliedFilters
      onChange={onChangeHandler}
      items={appliedFilters}
      seoEventManager={seoEventManager}
    />
  )
}
