import { MouseEvent, useState } from 'react'

import { IconButton, List, Tooltip } from '@gmini/ui-kit'

import { SeoEventManager } from '@gmini/common'

import { EmptyList } from './EmptyList'
import {
  Body,
  Container,
  Header,
  Popup,
  Wrapper,
} from './SavedFilterList.styled'

type SavedFiltersListProps<T> = {
  existingFilters: T[]
  renderListItem: (filter: T) => JSX.Element
  seoEventManager?: SeoEventManager
}

export const SavedFiltersList = <T,>({
  existingFilters,
  renderListItem,
  seoEventManager,
}: SavedFiltersListProps<T>) => {
  const [openPopper, setOpenPopper] = useState<HTMLButtonElement | null>(null)
  return (
    <>
      <Tooltip
        placement='top'
        title='Список моих фильтров'
        styleContent={{ marginLeft: 'auto' }}
      >
        <IconButton
          onClick={(event: MouseEvent<HTMLButtonElement>) => {
            setOpenPopper(event.currentTarget)
            seoEventManager?.push({
              event: 'Gstation_Issues_SavedFilters_Click',
              payload: {},
            })
          }}
        >
          <List color='rgba(53, 59, 96, 0.5)' />
        </IconButton>
      </Tooltip>
      <Popup
        open={Boolean(openPopper)}
        anchorEl={openPopper}
        onClose={() => setOpenPopper(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Container>
          <Header>Мои фильтры:</Header>
          <Body>
            {existingFilters.length ? (
              <Wrapper>
                {existingFilters.map(filter => renderListItem(filter))}
              </Wrapper>
            ) : (
              <EmptyList />
            )}
          </Body>
        </Container>
      </Popup>
    </>
  )
}
