import styled from 'styled-components'

import {
  TextField as TextFieldInit,
  TextArea as TextAreaInit,
  Button,
} from '@gmini/ui-kit'

export const TextField = styled(TextFieldInit)`
  background: #f4f4f8;
`

export const TextArea = styled(TextAreaInit)`
  && {
    background: #f4f4f8;
    width: 100%;
  }
`

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  padding: 10px 24px 24px 24px;
`

export const MultipleFieldRow = styled.div`
  display: flex;
  gap: 12px;
`

export const FieldContainer = styled.div`
  width: 100%;
`

export const Separator = styled.div`
  width: 100%;
  background: #e5e7f1;
  height: 1px;
  min-height: 1px;
  margin-bottom: 13px;
`

export const UploadFilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow-y: auto;
  max-height: 200px;
  margin-right: -17px;
  padding-right: 17px;
`

export const FullSeparator = styled(Separator)`
  margin: 4px -24px 4px -24px;
  width: calc(100% + 48px);
`

export const EmptyContainer = styled.div`
  min-height: 40px;
`

export const HeaderTitle = styled.div`
  display: flex;
  gap: 0 12px;
  align-items: center;
  width: 100%;
`

export const ColorSelectWrapper = styled.div`
  margin-top: 4px;
`

export const AttributesWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 auto;
  width: 100%;
  padding: 18.5px 24px;
`

export const Title = styled.div`
  font-size: 20px;
  line-height: 21px;
  color: #000335;
  letter-spacing: 0.1px;
`

export const Content = styled.div`
  overflow-y: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  flex: 1 1 auto;
`
type FooterProps = {
  existScroll?: boolean
}

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 0 0 auto;
  gap: 12px;
  padding: 16px 24px;
  width: 100%;
  box-shadow: ${(props: FooterProps) =>
    props.existScroll ? '0px -10px 20px rgba(0, 0, 0, 0.1)' : 'none'};
`
type CreateButtonProps = {
  disabled?: boolean
}

export const CreateButton = styled(Button)`
  && {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #ebebef;
    font-family: Roboto, sans-serif;
    background: rgba(53, 59, 96, 1);
    transition: all 0.2s ease-out;
    ${(props: CreateButtonProps) =>
      props.disabled &&
      'background: rgba(53, 59, 96, 0.05); color: rgba(53, 59, 96, 0.3);'}
    height: 36px;
    margin: auto 0;

    &:hover {
      ${(props: CreateButtonProps) =>
        !props.disabled && 'background: rgba(0, 0, 61, 1);'};
    }

    &:active {
      ${(props: CreateButtonProps) =>
        !props.disabled && 'background: rgba(53, 59, 96, 1);'};
    }
  }
`
