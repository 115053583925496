import styled from 'styled-components'

export const BubbleStyled = styled.div`
  width: fit-content;
  padding: 4px 1px 4px 10px;
  background: #4c5ecf;
  display: flex;
  align-items: center;
  border-radius: 50px;
  height: 28px;
  gap: 0 1px;
`

export const BubbleTitle = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #fff;
  opacity: 0.7;
  margin-right: 3px;
  white-space: nowrap;
`

export const BubbleValue = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #ffffff;
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
`

export const DeleteButton = styled.div`
  padding: 6px;
  cursor: pointer;
  display: flex;

  &:hover {
    background: #2235aa;
    border-radius: 50%;

    & > svg path {
      fill: #fff;
    }
  }
`
