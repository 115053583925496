import styled, { css } from 'styled-components'

import smallPencil from './smallPencil.svg'

const Text = css`
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #00003d;
  letter-spacing: 0.1px;
`

type PreviewProps = {
  disabled?: boolean
  editIcon?: string
  isPlaceholder?: boolean
  showEditIcon?: boolean
}

export const Preview = styled.div`
  ${Text}
  cursor: pointer;
  width: fit-content;
  position: relative;
  word-break: break-word;
  white-space: pre-wrap;

  &:hover::after {
    position: absolute;
    ${(props: PreviewProps) =>
      props.showEditIcon &&
      css`
        content: url(${props.editIcon || smallPencil});
      `}

    opacity: 0.2;
    margin-left: 5px;
  }

  ${(props: PreviewProps) =>
    (props.disabled || !props.showEditIcon) &&
    css`
      pointer-events: none;
    `}

  ${(props: PreviewProps) =>
    props.isPlaceholder &&
    css`
      color: rgba(0, 0, 61, 0.6);
    `};
`

export const TextFieldWrapper = styled.div`
  position: relative;
  width: fit-content;
`

type ActionButtonProps = {
  disabled?: boolean
}

const ActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgb(0 0 0 / 20%) 0px 4px 7px;
  border-radius: 4px;
  cursor: pointer;
  width: 36px;
  height: 36px;
  transition: background-color 0.2s ease-out 0s;
  background: rgb(255, 255, 255);
  user-select: none;

  &:hover {
    & svg path {
      fill: #fff;
    }
  }

  ${(props: ActionButtonProps) =>
    props.disabled &&
    css`
      opacity: 0.2;
      pointer-events: none;
    `}
`

export const CancelButton = styled(ActionButton)`
  &:hover {
    background: rgb(217, 87, 94);
  }
`

export const DoneButton = styled(ActionButton)`
  &:hover {
    background: rgb(76, 94, 207);
  }
`

export const ActionPanel = styled.div`
  position: absolute;
  display: flex;
  gap: 4px;
  bottom: -40px;
  right: 0px;
  z-index: 1;
`
