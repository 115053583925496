import styled, { css } from 'styled-components'

export const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 179px;
  min-height: 0;
`

export const FiltersHeader = styled.div`
  margin-bottom: 16px;
`

export const FiltersTitle = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #00003d;
  letter-spacing: 0.15px;
`

export const FiltersContent = css`
  padding-right: 10px;
`

export const FilterTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.15px;
  color: #00003d;
  margin-bottom: 4px;
`

export const FilterItem = styled.div`
  margin-bottom: 20px;
`
