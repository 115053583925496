import * as chmApi from '@gmini/chm-api-sdk'
import * as ismApi from '@gmini/ism-api-sdk'
import * as smApi from '@gmini/sm-api-sdk'

import { Group } from '@gmini/ui-kit/lib/SelectByGroups/SelectByGroups.types'

import {
  AssigneeListItem,
  AttributesService,
  IssueListFilterDeadlineOptionCode,
  PreparedIssueStatus,
  deadlineFilterMap,
  issueDateFilterMap,
  issueStatusBgColorMap,
  issueStatusTextMap,
  relativeDateFilterMap,
  relativeDeadlineFilterMap,
  serverSideDateFilterMap,
  serverSideDeadlineFilterMap,
} from '@gmini/helpers'
import { SavedFilterType } from '@gmini/helpers/src/filterService/issue'

import { filtersTitle } from '../IssueListFilterPanel'

import { AppliedFilters } from './IssueList.types'

type AssigneeGroupList = Group<AssigneeListItem> & {
  source: ismApi.AssigneeSource
}
export const convertServerToClientFilters = ({
  filters,
  userList,
  assigneeGroupList,
}: {
  filters: smApi.Omni.Subscription[]
  userList: smApi.Auth.UserData[]
  assigneeGroupList: AssigneeGroupList[]
}) => {
  const subscriptions = filters.map(el => ({
    id: el.id,
    attributes: el.attributes,
  }))

  return subscriptions.reduce(
    (acc: Record<number, AppliedFilters>, subscription) => {
      const status = subscription.attributes.reduce(
        (
          acc: {
            name: string
            bgColor: string
          }[],
          el,
        ) => {
          if (el.attribute === 'issue.status') {
            acc.push({
              name: issueStatusTextMap[
                el.value as keyof typeof issueStatusTextMap
              ],
              bgColor:
                issueStatusBgColorMap[
                  el.value as keyof typeof issueStatusBgColorMap
                ],
            })
          }
          return acc
        },
        [],
      )

      const updatedAt = subscription.attributes.reduce((acc, el) => {
        if (el.attribute === 'issue.updatedAt') {
          return serverSideDateFilterMap[
            el.value as keyof typeof serverSideDateFilterMap
          ]
        }
        return acc
      }, '')

      const createdAt = subscription.attributes.reduce((acc, el) => {
        if (el.attribute === 'issue.createdAt') {
          return serverSideDateFilterMap[
            el.value as keyof typeof serverSideDateFilterMap
          ]
        }
        return acc
      }, '')

      const deadline = subscription.attributes.reduce((acc, el) => {
        if (el.attribute === 'issue.deadline') {
          return serverSideDeadlineFilterMap[
            el.value as keyof typeof serverSideDeadlineFilterMap
          ]
        }
        return acc
      }, '')

      const author = subscription.attributes.reduce((acc: string[], el) => {
        if (el.attribute === 'issue.authorId') {
          const filter = userList.find(user => user.id === el.value)
          if (filter) {
            acc.push(filter.name)
          }
        }
        return acc
      }, [])

      const assignees = subscription.attributes.reduce((acc: string[], el) => {
        if (el.attribute === 'issue.assignees') {
          switch (el.source) {
            case chmApi.AssigneeSource.USER: {
              assigneeGroupList.reduce((users: string[], assigneeItem) => {
                if (assigneeItem.source === chmApi.AssigneeSource.USER) {
                  const filter = assigneeItem.options.find(
                    user => user.assigneeId === el.value,
                  )
                  if (filter) {
                    users.push(filter.label)
                    acc.push(filter.label)
                  }
                }
                return users
              }, [])
              break
            }

            case chmApi.AssigneeSource.ROLE: {
              assigneeGroupList.reduce((roles: string[], assigneeItem) => {
                if (assigneeItem.source === chmApi.AssigneeSource.ROLE) {
                  const filter = assigneeItem.options.find(
                    role => role.assigneeId === el.value,
                  )
                  if (filter) {
                    roles.push(filter.label)
                    acc.push(filter.label)
                  }
                }
                return roles
              }, [])
              break
            }

            case chmApi.AssigneeSource.COMPANY: {
              assigneeGroupList.reduce((companies: string[], assigneeItem) => {
                if (assigneeItem.source === chmApi.AssigneeSource.COMPANY) {
                  const filter = assigneeItem.options.find(
                    company => company.assigneeId === el.value,
                  )
                  if (filter) {
                    companies.push(filter.label)
                    acc.push(filter.label)
                  }
                }
                return companies
              }, [])
              break
            }

            default:
              break // TODO: return assertNever('Unexpected el.source', el.source)
          }
        }
        return acc
      }, [])

      acc[subscription.id] = {
        status,
        updatedAt,
        createdAt,
        deadline,
        author,
        assignees,
      }
      return acc
    },
    {},
  )
}

export const convertClientToServerFilters = ({
  clientFilters,
  projectUrn,
  issueStatusList,
  userList,
  assignees,
  attributeValueById,
}: {
  clientFilters: SavedFilterType
  projectUrn: string
  issueStatusList: PreparedIssueStatus[]
  userList: smApi.Auth.UserData[]
  assignees: AssigneeListItem[]
  attributeValueById: AttributesService['attributeValueById$']['defaultState']
}) => {
  const status = clientFilters.statuses
    ? clientFilters.statuses.reduce(
        (acc: smApi.Omni.Attribute[], statusName) => {
          const filter = issueStatusList.find(
            item => item.status === statusName,
          )
          if (filter) {
            acc.push({
              attribute: 'issue.status',
              attributeLabel: filtersTitle.status,
              value: statusName,
              valueLabel: filter.name,
              operator: smApi.Omni.Operator.EQUAL,
              valueType: smApi.Omni.ValueType.STR,
            })
          }
          return acc
        },
        [],
      )
    : []

  const updateAt: smApi.Omni.Attribute[] = clientFilters.updatedDateCode
    ? [
        {
          attribute: 'issue.updatedAt',
          attributeLabel: filtersTitle.updatedAt,
          value: relativeDateFilterMap[clientFilters.updatedDateCode],
          valueLabel: issueDateFilterMap[clientFilters.updatedDateCode],
          operator: smApi.Omni.Operator.RANGE,
          valueType: smApi.Omni.ValueType.DATE,
        },
      ]
    : []

  const createAt: smApi.Omni.Attribute[] = clientFilters.createdDateCode
    ? [
        {
          attribute: 'issue.createdAt',
          attributeLabel: filtersTitle.createdAt,
          value: relativeDateFilterMap[clientFilters.createdDateCode],
          valueLabel: issueDateFilterMap[clientFilters.createdDateCode],
          operator: smApi.Omni.Operator.RANGE,
          valueType: smApi.Omni.ValueType.DATE,
        },
      ]
    : []

  const deadline: smApi.Omni.Attribute[] = clientFilters.deadlineCode
    ? [
        {
          attribute: 'issue.deadline',
          attributeLabel: filtersTitle.deadline,
          value: relativeDeadlineFilterMap[clientFilters.deadlineCode],
          valueLabel: deadlineFilterMap[clientFilters.deadlineCode],
          operator:
            clientFilters.deadlineCode ===
            IssueListFilterDeadlineOptionCode.ONLY_EXPIRED
              ? smApi.Omni.Operator.LESS_THAN
              : smApi.Omni.Operator.RANGE,
          valueType: smApi.Omni.ValueType.DATE,
        },
      ]
    : []

  const author = clientFilters.authorIds
    ? clientFilters.authorIds.reduce((acc: smApi.Omni.Attribute[], id) => {
        const filter = userList.find(item => item.id === id)
        if (filter) {
          acc.push({
            attribute: 'issue.author',
            attributeLabel: filtersTitle.author,
            value: id,
            valueLabel: filter.name,
            operator: smApi.Omni.Operator.EQUAL,
            valueType: smApi.Omni.ValueType.STR,
          })
        }
        return acc
      }, [])
    : []

  const assignee: smApi.Omni.Attribute[] = assignees
    ? assignees.map(el => ({
        source: el.source,
        attribute: 'approvers',
        attributeLabel: filtersTitle.assignee,
        value: el.assigneeId,
        valueLabel: el.label,
        operator: smApi.Omni.Operator.EQUAL,
        valueType: smApi.Omni.ValueType.STR,
      }))
    : []

  const attributes: smApi.Omni.Attribute[] = clientFilters.attributeValueIds
    ?.length
    ? clientFilters.attributeValueIds.map(el => ({
        attribute: 'issue.attributeValueIds',
        attributeLabel: attributeValueById[el]?.attribute?.name,
        valueLabel: attributeValueById[el]?.name,
        value: el,
        operator: smApi.Omni.Operator.CONTAINS,
        valueType: smApi.Omni.ValueType.INT,
      }))
    : []

  const serverFilters: smApi.Omni.CreateOmniSubscriptionParams = {
    moduleId: smApi.Omni.ModuleId.ISSUE_MANAGEMENT,
    resourceName: smApi.Omni.ResourceName.ISSUE,
    projectUrn,
    attributes: [
      ...status,
      ...updateAt,
      ...createAt,
      ...deadline,
      ...author,
      ...assignee,
      ...attributes,
    ],
  }

  return serverFilters
}

export const getCreateOmniSubscriptionParams = ({
  id,
  projectUrn,
}: {
  id: string
  projectUrn: string
}): smApi.Omni.CreateOmniSubscriptionParams => ({
  moduleId: smApi.Omni.ModuleId.ISSUE_MANAGEMENT,
  resourceName: smApi.Omni.ResourceName.ISSUE,
  projectUrn,
  attributes: [
    {
      attribute: 'issue.id',
      attributeLabel: filtersTitle.id,
      value: id,
      valueLabel: id,
      operator: smApi.Omni.Operator.EQUAL,
      valueType: smApi.Omni.ValueType.INT,
    },
  ],
})
