import { combine } from 'effector'

import {
  fetchIssueHistoryPending$,
  fetchListLinkedIssueToFilePending$,
} from '../../issue.store'
import {
  downloadFilePending$,
  fetchContentFilesPending$,
} from '../../file.store'

import {
  fetchIssueCommentListPending$,
  issueCommentsPending$,
} from '../Comments/model'

import { actionIssuePending$ } from './actionIssuePending'

export const editIssuePending$ = combine(
  [
    actionIssuePending$,
    fetchIssueHistoryPending$,
    fetchContentFilesPending$,
    downloadFilePending$,
    issueCommentsPending$,
    fetchIssueCommentListPending$,
    fetchListLinkedIssueToFilePending$,
  ],
  pendings => pendings.some(Boolean),
)
