import { createStore } from 'effector'

import { Attribute } from '@gmini/sm-api-sdk'

import { attributesService } from './attribute.store'

export const usedAttributes$ = createStore<Attribute.Data[]>([]).on(
  attributesService.fetchAttributeValues.doneData,
  (_, data) => {
    const attributeIds = data.attributeValues.map(
      ({ attribute }) => attribute.id,
    )
    const uniqueAttributeIds = Array.from(new Set(attributeIds))

    const attributeById = data.attributeValues.reduce(
      (acc: Record<number, Attribute.Data>, item) => {
        acc[item.attribute.id] = item.attribute

        return acc
      },
      {},
    )

    return uniqueAttributeIds.map(id => attributeById[id])
  },
)
