import { IconButton } from '@gmini/ui-kit'
import styled from 'styled-components'

export const IFrameContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 90vh;
`

export const RightSideHeader = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 20px 14px 30px 14px;
`

export const CloseButton = styled(IconButton)`
  && svg path {
    fill: #353b60;
  }
`
