import { useMemo } from 'react'

import { AppliedFilterAssignee } from './useAppliedFilterUserData.types'

type UseAppliedFilterUserDataProps<U> = {
  urlKey: string
  name: string
  userList: U[]
  value: string[]
}

export const useAppliedFilterUserData = <U extends AppliedFilterAssignee>({
  urlKey,
  name,
  userList,
  value,
}: UseAppliedFilterUserDataProps<U>) =>
  useMemo(
    () => ({
      name,
      value: userList
        .filter(item => value.some(id => id === item.id))
        .map(item => item.name),
      urlKey,
    }),
    [name, urlKey, userList, value],
  )
