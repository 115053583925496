import { fetchWorkdays } from '@gmini/helpers'
import dayjs from 'dayjs'

export const addDaysToDate = (days: number, date?: Date) => {
  const result = date ? new Date(date) : new Date()
  result.setDate(result.getDate() + days)
  return result
}

export const addWorkdaysToDate = async (days: number, date?: Date) => {
  const result = date ? new Date(date) : new Date()

  let workdays = await fetchWorkdays(
    `?date1=${dayjs(result).format('YYYYMMDD')}&date2=${dayjs(result)
      .add(1, 'year')
      .format('YYYYMMDD')}`,
  )

  if (workdays instanceof Error) {
    throw workdays
  }

  if (workdays.split('').filter(d => d === '0').length < days) {
    const workdays2 = await fetchWorkdays(
      `?date1=${dayjs(result)
        .add(1, 'year')
        .add(1, 'day')
        .format('YYYYMMDD')}&date2=${dayjs(result)
        .add(2, 'year')
        .add(1, 'day')
        .format('YYYYMMDD')}`,
    )

    if (workdays2 instanceof Error) {
      throw workdays2
    }
    workdays = workdays.concat(workdays2)
  }

  let workdaysCount = 0
  let targetDateIdx = 0

  while (workdaysCount < days) {
    if (workdays[targetDateIdx] === '0') {
      workdaysCount++
    }
    targetDateIdx++
  }

  while (workdays[targetDateIdx] === '1') {
    targetDateIdx++
  }

  result.setDate(result.getDate() + targetDateIdx)
  return result
}
