import styled from 'styled-components'

type TitleProps = {
  collapsed?: boolean
}

export const Title = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.15px;
  color: #00003d;
  white-space: nowrap;

  ${(props: TitleProps) =>
    props.collapsed &&
    `transform: rotate(90deg); color: #616184; position: absolute; top: 70px; left: 0; right: 0; font-size: 14px; font-weight: 500;`}
`
