import styled, { css } from 'styled-components'

type SimpleBubbleTitleProps = {
  isDeleted?: boolean
}

export const SimpleBubbleTitle = styled.div`
  display: flex;
  align-items: center;
  background: #ebebef;
  max-width: 150px;
  height: 19px;
  padding: 0 8px;
  border-radius: 4px;
  text-decoration: none;

  ${(props: SimpleBubbleTitleProps) =>
    props.isDeleted &&
    css`
      text-decoration: line-through;
    `}
`
