import { Group } from '@gmini/ui-kit/lib/SelectByGroups/SelectByGroups.types'
import { combine, createStore, Store } from 'effector'

import * as smApi from '@gmini/sm-api-sdk'
import * as api from '@gmini/chm-api-sdk'

import { Role } from '@gmini/helpers/src/rolesService/roleService.types'

import { AssigneeListItem } from '@gmini/helpers'

import { Company } from '../companiesService/companiesService.types'

type AssigneeGroupList = Group<AssigneeListItem> & {
  source: api.AssigneeSource
}

export function createAssigneeGroupListService({
  roleList$,
  userList$,
  companyList$,
}: {
  userList$: Store<smApi.Auth.UserData[]>
  roleList$: Store<Role[]>
  companyList$?: Store<Company[]>
}) {
  const mapUserToAssigneeOption = (
    user: smApi.Auth.UserData,
  ): AssigneeListItem => {
    const assigneeUser: AssigneeListItem = {
      assigneeId: user.id,
      label: user.name,
      source: api.AssigneeSource.USER,
    }

    return assigneeUser
  }

  const mapRoleToAssigneeOption = (role: Role): AssigneeListItem => {
    const assigneeRole: AssigneeListItem = {
      assigneeId: role.id,
      label: role.title,
      source: api.AssigneeSource.ROLE,
    }

    return assigneeRole
  }

  const mapCompanyToAssigneeOption = (company: Company): AssigneeListItem => {
    const assigneeCompany: AssigneeListItem = {
      assigneeId: company.id,
      label: company.label,
      source: api.AssigneeSource.COMPANY,
    }

    return assigneeCompany
  }

  const assigneeUserList$ = userList$.map(userList =>
    userList.map(mapUserToAssigneeOption),
  )
  const assigneeRoleList$ = roleList$.map(roleList =>
    roleList.map(mapRoleToAssigneeOption),
  )
  const assigneeCompanyList$ = companyList$
    ? companyList$.map(companyList =>
        companyList.map(mapCompanyToAssigneeOption),
      )
    : createStore<AssigneeListItem[]>([])

  const assigneeGroupList$ = combine(
    {
      userList: assigneeUserList$,
      roleList: assigneeRoleList$,
      companyList: assigneeCompanyList$,
    },
    ({ roleList, userList, companyList }) => {
      const userGroup: AssigneeGroupList = {
        label: 'Пользователи',
        source: api.AssigneeSource.USER,
        options: userList,
      }

      const roleGroup: AssigneeGroupList = {
        label: 'Роли',
        source: api.AssigneeSource.ROLE,
        options: roleList,
      }

      const companyGroup = {
        label: 'Компании',
        source: api.AssigneeSource.COMPANY,
        options: companyList,
      }

      return [userGroup, roleGroup, companyGroup]
    },
  )

  return {
    mapUserToAssigneeOption,
    mapRoleToAssigneeOption,
    mapCompanyToAssigneeOption,
    assigneeUserList$,
    assigneeRoleList$,
    assigneeCompanyList$,
    assigneeGroupList$,
  }
}
