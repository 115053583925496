import {
  createAttributesService,
  createAttributesFormService,
} from '@gmini/helpers'

export const attributesService = createAttributesService()

export const attributesFormService = createAttributesFormService({
  attributesService,
})
