import { Checkbox } from '@gmini/ui-kit'
import { useHover } from '@gmini/utils'

import { ReactNode } from 'react'

import { filterCheckboxColors } from '../FilterItem'

import { FilterUserItemStyled } from './FilterUserItem.styled'

type FilterUserItemProps<I> = {
  item: I
  onChange?: (item: I) => void
  label?: ReactNode
  checked: boolean
  disabled?: boolean
  getId: (item: I) => string
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const FilterUserItem = <I extends {}>({
  item,
  onChange,
  label,
  checked,
  disabled,
  getId,
}: FilterUserItemProps<I>) => {
  const { hover, onMouseLeave, onMouseOver } = useHover()

  const id = getId(item)

  return (
    <FilterUserItemStyled
      htmlFor={id}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
      disabled={disabled}
    >
      <Checkbox
        id={id}
        name={id}
        hover={hover}
        checked={checked}
        disabled={disabled}
        colors={filterCheckboxColors}
        onChange={() => onChange?.(item)}
      />
      {label}
    </FilterUserItemStyled>
  )
}
