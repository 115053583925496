import { issueFilterService, omniSubscriptionService } from '@gmini/helpers'
import { combine } from 'effector'

import { issueStatusList$ } from './issueStatus.store'
import { projectUserList$ } from './user.store'
import { attributesService } from './attribute.store'

const { savedFilters, appliedFilters } = issueFilterService
const filterActionsPending$ = combine(
  [
    omniSubscriptionService.fetchList.pending$,
    savedFilters.updateSavedFilter.pending$,
    savedFilters.deleteSavedFilter.pending$,
  ],
  pendings => pendings.some(Boolean),
)

export const filterService = {
  appliedFilters: appliedFilters({
    issueStatusList$,
    projectUserList$,
    attributeValueById$: attributesService.attributeValueById$,
  }),
  savedFilters,
  filterActionsPending$,
}
