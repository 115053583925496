export const getIssuesManagerLink = (
  envLinks: string,
  localDevUrl: string,
  isDev?: boolean,
) => {
  try {
    const issuesManagerLink: string = JSON.parse(envLinks).ism

    if (!issuesManagerLink) {
      throw new Error(
        'В переменных окружения не найдена ссылка на менеджер замечаний',
      )
    }

    if (isDev) {
      return localDevUrl
    }

    return `https://${issuesManagerLink}`
  } catch (error) {
    console.error(error)
  }
}
