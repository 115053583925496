import { defaultCheckboxColors } from '@gmini/ui-kit'

export const multiSelectCheckboxColors: Partial<typeof defaultCheckboxColors> =
  {
    checked: '#4C5ECF',
    unChecked: '#353b60',
    checkedDisabled: '#4C5ECF',
    unCheckedDisabled: '#353b60',
    hoverColor: '#4C5ECF',
  }
