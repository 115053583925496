import { Tooltip } from '@gmini/ui-kit'

import {
  SelectViewCompactIcon,
  SelectViewExpandedIcon,
  SelectViewItem,
  SelectViewWrapper,
} from './SelectView.styled'

type SelectViewProps = {
  isCompact: boolean
  onSelect: (isCompact: boolean) => void
}

export const SelectView = ({ isCompact, onSelect }: SelectViewProps) => (
  <SelectViewWrapper>
    <Tooltip
      title={'Компактный вид'}
      styleContent={{
        width: 'max-content',
      }}
      noMaxWidth
    >
      <SelectViewItem isSelected={isCompact}>
        <SelectViewCompactIcon
          isSelected={isCompact}
          onClick={() => onSelect(true)}
        />
      </SelectViewItem>
    </Tooltip>
    <Tooltip
      title={'Развернутый вид'}
      styleContent={{
        width: 'max-content',
      }}
      noMaxWidth
    >
      <SelectViewItem isLast isSelected={!isCompact}>
        <SelectViewExpandedIcon
          isSelected={!isCompact}
          onClick={() => onSelect(false)}
        />
      </SelectViewItem>
    </Tooltip>
  </SelectViewWrapper>
)
