import * as React from 'react'
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Chip,
  Popper,
  TextField,
  autocompleteClasses,
  paperClasses,
} from '@mui/material'

import { styled as mStyled } from '@mui/styles'

import {
  ArrowDown,
  Checkbox,
  CrossCircle,
  LongText,
  RoundedCross,
} from '@gmini/ui-kit'

import { multiSelectCheckboxColors } from './multiSelectCheckboxColors'
import {
  AutocompleteIconWrapper,
  CheckboxWrapper,
  DeleteButton,
} from './MultipleSelectAutocomplete.styled'

type MultipleSelectAutocompleteProps<Value> = {
  noOptionsText: string
  value: Value[]
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    value: Value[],
  ) => void
  options: Value[]
  loading: boolean
  disabled?: boolean
  getOptionLabel: (value: Value) => string
  filterOptions?: (options: Value[], searchValue: string) => Value[]
  groupBy: (value: Value) => string
  onOpen?: () => void
  onBlur?: (value: Value[]) => void
  isOptionEqualToValue?: (option: Value, value: Value) => boolean
  focusedInput?: boolean
  popupIconVisible?: boolean
  inputAutoFocus?: boolean
  dataTestIdPrefix?: string
}

export const MultipleSelectAutocomplete = React.memo(
  // eslint-disable-next-line @typescript-eslint/ban-types
  <Value extends {}>({
    noOptionsText,
    value,
    onChange,
    options,
    loading,
    disabled,
    onOpen,
    getOptionLabel,
    filterOptions,
    groupBy,
    onBlur,
    isOptionEqualToValue,
    focusedInput,
    popupIconVisible,
    inputAutoFocus,
    dataTestIdPrefix,
    ...otherProps
  }: MultipleSelectAutocompleteProps<Value>) => {
    const [focusedAutocomplete, setFocusedAutocomplete] = React.useState(
      focusedInput ?? false,
    )
    const [inputValue, setInputValue] = React.useState('')

    const autocompleteRef = React.useRef<HTMLInputElement>(null)
    const renderInput = React.useCallback(
      (params: AutocompleteRenderInputParams) => (
        <StyledAutocompleteTextField
          {...params}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (!loading) {
              setInputValue(event.target.value)
            }
          }}
          value={inputValue}
          placeholder={value.length > 0 ? '' : 'Не задано'}
          variant='outlined'
          inputRef={autocompleteRef}
          autoFocus={inputAutoFocus ?? false}
          focusedInput={focusedAutocomplete}
          existSelectedProperties={value.length > 0 || inputValue.length > 0}
        />
      ),
      [inputValue, value.length, inputAutoFocus, focusedAutocomplete, loading],
    )

    const renderOption = React.useCallback(
      (props, option, { selected }) => (
        <CheckboxWrapper
          data-test-id={dataTestIdPrefix ? `${dataTestIdPrefix}_ListItem` : ''}
          {...props}
        >
          <Checkbox
            name={option.name}
            checked={selected}
            colors={multiSelectCheckboxColors}
          />
          <LongText partSize={15}>{option.name}</LongText>
        </CheckboxWrapper>
      ),
      [dataTestIdPrefix],
    )

    const renderGroup = React.useCallback(
      params => (
        <li key={params.key}>
          {params.group ? <GroupHeader>{params.group}</GroupHeader> : null}
          <GroupItems>{params.children}</GroupItems>
        </li>
      ),
      [],
    )

    const onFocusAutocomplete = React.useCallback(() => {
      setFocusedAutocomplete(true)
    }, [])
    const onBlurAutocomplete = React.useCallback(() => {
      onBlur?.(value)
      setFocusedAutocomplete(false)
      setInputValue('')
    }, [value, onBlur])

    return (
      <StyledAutocomplete
        noOptionsText={noOptionsText}
        multiple
        defaultValue={value}
        options={options}
        groupBy={option => groupBy(option as Value)}
        getOptionLabel={option => getOptionLabel(option as Value)}
        inputValue={inputValue}
        onChange={(event, value) => onChange(event, value as Value[])}
        renderInput={renderInput}
        focusedInput={focusedAutocomplete}
        onFocus={onFocusAutocomplete}
        onBlur={onBlurAutocomplete}
        forcePopupIcon={popupIconVisible ?? true}
        disabled={disabled || loading}
        renderGroup={renderGroup}
        renderOption={renderOption}
        selectOnFocus
        filterOptions={
          filterOptions
            ? options => filterOptions(options as Value[], inputValue)
            : undefined
        }
        isOptionEqualToValue={
          isOptionEqualToValue
            ? (option, value) =>
                isOptionEqualToValue(option as Value, value as Value)
            : undefined
        }
        disableCloseOnSelect
        PopperComponent={props => <StyledAutocompletePopper {...props} />}
        clearIcon={
          <AutocompleteIconWrapper data-test-id='clearAttributeField'>
            <CrossCircle />
          </AutocompleteIconWrapper>
        }
        popupIcon={
          <AutocompleteIconWrapper data-test-id='openAttributeField'>
            <ArrowDown />
          </AutocompleteIconWrapper>
        }
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            // eslint-disable-next-line react/jsx-key
            <Chip
              label={getOptionLabel(option as Value)}
              {...getTagProps({ index })}
              deleteIcon={
                <DeleteButton>
                  <AutocompleteIconWrapper data-test-id='clearAttributeFieldValue'>
                    <RoundedCross />
                  </AutocompleteIconWrapper>
                </DeleteButton>
              }
            />
          ))
        }
        openOnFocus
        onOpen={onOpen}
        {...otherProps}
      />
    )
  },
)

MultipleSelectAutocomplete.displayName = 'MultipleSelectAutocomplete'

const GroupHeader = mStyled('div')({
  position: 'sticky',
  top: '-8px',
  padding: '3px 16px',
  background: '#f5f5f7',
  color: 'rgba(53, 59, 96, 0.7)',
})

const GroupItems = mStyled('ul')({
  padding: 0,
})

type StyledAutocompleteTextFieldProps = {
  focusedInput?: boolean
  existSelectedProperties?: boolean
}

type StyledAutocompleteProps = {
  focusedInput?: boolean
}

// TODO: Заменить на VirtualizedAutocomplete из @gmini/ui-kit, после чего вынести повторяющиеся стили с `SimpleConditionsAutocomplete` в переиспользуемый компонент
export const StyledAutocomplete = mStyled(Autocomplete)(
  ({ focusedInput }: StyledAutocompleteProps) => ({
    '& .MuiAutocomplete-listbox': { maxHeight: '70vh' },

    '& .MuiAutocomplete-root': {
      '& .MuiAutocomplete-input': {
        color: '#353B60',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
        borderRadius: '4px',
        boxShadow: focusedInput ? '0px 5px 10px rgba(53, 60, 96, 0.1)' : 'none',
        transition:
          'background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out',
      },
    },

    '& .MuiInputBase-root': { cursor: 'default' },
    '& .MuiAutocomplete-input': {
      color: '#353B60',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      borderRadius: '4px',

      boxShadow: focusedInput ? '0px 5px 10px rgba(53, 60, 96, 0.1)' : 'none',
      transition:
        'background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out',
    },

    '& .MuiAutocomplete-clearIndicator': {
      display: 'none',
    },

    '& .MuiAutocomplete-option': {
      padding: '8px 12px',
    },

    '& .MuiChip-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(53, 59, 96, 0.1)',
      height: '19px',
      padding: '0 8px',
      borderRadius: '4px',
      textDecoration: 'none',
    },

    '& .MuiChip-label': {
      padding: '0 8px',
    },

    '& .MuiChip-deleteIcon': {
      '& svg': {
        width: '12px',
        height: '12px',
      },

      '& :hover': {
        background: 'rgba(0, 0, 61, 0.1)',
      },

      '&:hover path': {
        stroke: 'rgba(0, 0, 61, 1)',
      },
    },
  }),
)

const StyledAutocompleteTextField = mStyled(TextField)(
  ({
    existSelectedProperties,
    focusedInput,
  }: StyledAutocompleteTextFieldProps) => ({
    '& .MuiOutlinedInput-root': {
      boxShadow: 'none',
      minHeight: '40px',
      gap: '4px ',
      padding: '5px 30px 5px 12px !important',
      background: focusedInput ? 'inherit' : '#F4F4F8',
      border: focusedInput ? '1px solid #4C5ECF' : '1px solid transparent',
      transition: 'border-color ease-out .2s',

      '& .MuiAutocomplete-tag': {
        padding: 0,
        margin: 0,

        color: 'rgba(0, 0, 61, 1)',
      },

      '&:hover': {
        border: focusedInput ? '1px solid #4C5ECF' : '1px solid #C5C6D1',
      },

      '& .MuiIconButton-label': {
        visibility: existSelectedProperties ? 'visible' : 'hidden',
      },

      '& .MuiAutocomplete-clearIndicator': {
        display: 'flex',
        alignItems: 'center',

        '& path': {
          opacity: 0.25,
        },

        '&:hover': {
          background: 'none',

          '& path:first-child': {
            opacity: 1,
            fill: '#353b60',
          },
        },

        '&:active, &:focus': {
          '& path:first-child': {
            opacity: 1,
            fill: '#00003d',
          },
        },
      },

      '& .MuiAutocomplete-endAdornment': {
        display: 'flex',
        flexFlow: 'row nowrap',
      },

      '& .MuiInputBase-input': {
        paddingTop: 0,
        paddingBottom: 0,
      },

      '& input': {
        fontSize: '14px',
        fontWeight: 500,
        color: 'rgba(0, 0, 0, 0.87)',
        paddingLeft: '2px !important',
      },

      '& input::placeholder': {
        fontWeight: 500,
        fontSize: '13px',
        color: 'rgba(0, 3, 53, 1)',
      },
    },
  }),
)

const StyledAutocompletePopper = mStyled(Popper)({
  padding: 0,

  [`& .${paperClasses.root}`]: {
    boxShadow: '0px 5px 10px rgb(53 60 96 / 15%) !important',
    border: '1px solid #EDEFF3 !important',
    borderRadius: '4px',
    margin: '4px -3px 0 -1px',
    fontSize: '14px',
    fontWeight: 500,
    color: '#353B60',
  },

  '& .MuiListSubheader-root': {
    backgroundColor: 'rgba(0, 5, 100, 0.04)',
  },

  [`& .${autocompleteClasses.noOptions}`]: {
    color: '#353B60',
    padding: '10px 8px 10px 12px',
    cursor: 'default',
    fontWeight: 500,
    fontSize: '14px',
  },

  [`& .${autocompleteClasses.option}`]: {
    '&[data-focus="true"]': {
      backgroundColor: 'transparent',
    },
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 5, 100, 0.04)',
    },
    '&[data-focus="true"]:hover': {
      backgroundColor: 'rgba(0, 5, 100, 0.04)',
    },
    '&[aria-selected="true"]:hover': {
      backgroundColor: 'rgba(0, 5, 100, 0.04)',
    },
  },
})
