import { createStore } from 'effector'

import * as smApi from '@gmini/sm-api-sdk'

import { useStore, useStoreMap } from 'effector-react'

import { Company } from './companiesService.types'

export function createCompaniesService() {
  const fetchCompanyList = smApi.HubManagement.Company.fetchList.createContext()
  const fetchCompanyListPending$ = fetchCompanyList.pending$

  const companyList$ = createStore<Company[]>([]).on(
    fetchCompanyList.doneData,
    (state, result) =>
      result.map(company => ({
        ...company,
        id: company.id.toString(),
        label: company.companyName,
      })),
  )

  function useCompanyList() {
    return useStore(companyList$)
  }

  function useFilteredCompanyList(filterTitleValue: string) {
    return useStoreMap({
      store: companyList$,
      keys: [filterTitleValue],
      fn: (companies, [filterTitle]) =>
        filterTitle
          ? companies.filter(company =>
              company.label.toLowerCase().includes(filterTitle.toLowerCase()),
            )
          : companies,
    })
  }

  return {
    fetchCompanyList,
    fetchCompanyListPending$,
    companyList$,
    useCompanyList,
    useFilteredRoleList: useFilteredCompanyList,
  }
}
