import * as ismApi from '@gmini/ism-api-sdk'
import { combine } from 'effector'

import { createIssueCommentService } from '../../createIssueCommentService'

export const {
  createIssueComment,
  createIssueCommentPending$,
  fetchIssueCommentList,
  fetchIssueCommentListPending$,
  issueCommentList$,
  removeIssueComment,
  removeIssueCommentPending$,
  reset,
} = createIssueCommentService({
  createIssueCommentApi: ismApi.IssueComment.create,
  fetchIssueCommentListApi: ismApi.IssueComment.fetchList,
  removeIssueCommentApi: ismApi.IssueComment.remove,
})

export const issueCommentsPending$ = combine(
  [
    createIssueCommentPending$,
    fetchIssueCommentListPending$,
    removeIssueCommentPending$,
  ],
  pendings => pendings.some(Boolean),
)
