import { createStore } from 'effector'

import * as smApi from '@gmini/sm-api-sdk'
import { useStore, useStoreMap } from 'effector-react'

export function createUsersService() {
  const fetchUserList = smApi.Auth.User.fetchList.createContext()
  const fetchUserListPending$ = fetchUserList.pending$

  const userList$ = createStore<smApi.Auth.UserData[]>([]).on(
    fetchUserList.doneData,
    (state, result) => result,
  )

  function useUserList() {
    return useStore(userList$)
  }

  function useFilteredUserList(filterNameValue: string) {
    return useStoreMap({
      store: userList$,
      keys: [filterNameValue],
      fn: (users, [filterName]) =>
        filterName
          ? users.filter(user =>
              user.name.toLowerCase().includes(filterName.toLowerCase()),
            )
          : users,
    })
  }

  return {
    fetchUserList,
    fetchUserListPending$,
    userList$,
    useUserList,
    useFilteredUserList,
  }
}
