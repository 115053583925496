export type FilterShowItem = {
  name: string
  type: FilterShowItemType
}

export enum FilterShowItemType {
  ALL = 'all',
  FOR_ME = 'forMe',
  SUBSCRIBED = 'subscribed',
}
