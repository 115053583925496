type User = {
  name: string
}

export const buildUserLabel = (user: User | null): string => {
  if (!user) {
    return 'не найден'
  }

  return user.name
}

export const buildShortUserLabel = (user: User | null): string => {
  if (!user) {
    return ''
  }

  return user.name[0]
}
