import styled, { css } from 'styled-components'

export const FilterCheckboxItemStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 0;
`

type FilterItemLabelProps = {
  disabled?: boolean
}

export const FilterItemLabel = styled.label`
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  padding-left: 8px;

  ${(props: FilterItemLabelProps) =>
    props.disabled &&
    css`
      opacity: 0.3;
      cursor: default;
    `}
`

export const RadioButtonWrapper = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
`
