import { SVGAttributes } from 'react'

export const BellFilledIcon = ({
  color = '#353B60',
  ...other
}: {
  color?: string
} & SVGAttributes<SVGSVGElement>) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...other}
  >
    <path
      d='M11.0001 22C12.1001 22 13.0001 21.1 13.0001 20H9.00005C9.00005 21.1 9.89005 22 11.0001 22ZM17.0001 16V11C17.0001 7.93 15.3601 5.36 12.5001 4.68V4C12.5001 3.17 11.8301 2.5 11.0001 2.5C10.1701 2.5 9.50005 3.17 9.50005 4V4.68C6.63005 5.36 5.00005 7.92 5.00005 11V16L3.71005 17.29C3.08005 17.92 3.52005 19 4.41005 19H17.5801C18.4701 19 18.9201 17.92 18.2901 17.29L17.0001 16Z'
      fill={color}
    />
    {/* <defs>
      <clipPath>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs> */}
  </svg>
)
