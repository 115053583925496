import styled from 'styled-components'

export const InputWrapper = styled.div`
  position: relative;
`

export const DisabledWrapper = styled.div`
  opacity: 0.7;
  width: 100%;
  height: 100%;
  position: absolute;
  background: #eee;
  border-radius: 4px;
  z-index: 9999;
`

export const DeleteButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  border-radius: 0 4px 4px 0;
  transition: background-color ease-out 0.2s;
  cursor: pointer;
`

export const AutocompleteIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;

  & path {
    transition: all ease-out 0.2s;
    fill: #353b60;
  }
`

export const AutocompletePopupIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & path {
    transition: all ease-out 0.2s;
    fill: #353b60;
  }
`

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  height: 40px;
  padding: 8px 12px;
`
