import { ReactNode } from 'react'
import * as smApi from '@gmini/sm-api-sdk'
import { userInfo$ } from '@gmini/common/lib/auth/auth'
import { Header, HeaderLogoWrap, Logo } from '@gmini/ui-kit'
import { useKeycloak } from '@react-keycloak/web'
import { useStore } from 'effector-react'
import { Notifications } from '@gmini/ui-common'
import { tokenData$ } from '@gmini/common/lib/keycloakAuth'

import {
  HeaderWrapper,
  TabsWrapper,
  Wrapper,
  BrandContainer,
  BrandTitle,
  PageContentContainer,
  LinearProgress,
  LoadingIndicatorWrap,
  PageContentWrapper,
  NotificationsWrapper,
} from './WithHeader.styled'

type WithHeaderProps = {
  loading?: boolean
  children: ReactNode | ReactNode[]
  NavbarDropDown: JSX.Element
  openedNavbarDropDown: boolean
  Tabs?: ReactNode
  brandSecondary?: ReactNode
  title: string
  notificationService?: smApi.OmniNotificationService
}

export const WithHeader = ({
  children,
  loading,
  NavbarDropDown,
  openedNavbarDropDown,
  Tabs,
  title,
  brandSecondary,
  notificationService,
}: WithHeaderProps) => {
  const { keycloak } = useKeycloak()

  const userInfo = useStore(userInfo$)
  const tokenData = useStore(tokenData$)

  if (!userInfo) {
    return null
  }

  return (
    <Wrapper>
      <HeaderWrapper>
        <Header
          onLogout={() => keycloak.logout()}
          brandSecondary={<>{brandSecondary}</>}
          brand={
            <BrandContainer active={openedNavbarDropDown}>
              <HeaderLogoWrap>
                <Logo />
              </HeaderLogoWrap>
              <BrandTitle>{title}</BrandTitle>
              {NavbarDropDown}
            </BrandContainer>
          }
          titleSecondary={
            <>
              {Tabs ? <TabsWrapper>{Tabs}</TabsWrapper> : null}
              {notificationService && tokenData?.accessToken && userInfo.id && (
                <NotificationsWrapper>
                  <Notifications
                    userToken={tokenData.accessToken}
                    // TODO: fix any, typescript give error in ci/cd
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    notificationService={notificationService as any}
                  />
                </NotificationsWrapper>
              )}
            </>
          }
          userInfo={userInfo}
        />
      </HeaderWrapper>
      <PageContentWrapper>
        <PageContentContainer>
          <LoadingIndicatorWrap>
            <LinearProgress show={loading} />
          </LoadingIndicatorWrap>
          {children}
        </PageContentContainer>
      </PageContentWrapper>
    </Wrapper>
  )
}
