import { ReactNode, useCallback, useEffect, useState } from 'react'

import { MultipleSelectAutocomplete } from '@gmini/components/src/atoms'

import { SelectPreview } from '../../atoms'
import { RenderEditModeProps } from '../../atoms/SelectPreview/SelectPreview.types'

type MultipleSelectAutocompleteWithPreviewProps<Value> = {
  initValue: Value[]
  options: Value[]
  getOptionLabel: (value: Value) => string
  loading: boolean
  optionsLoading: boolean
  groupBy: (value: Value) => string
  onOpen?: () => void
  placeholder: string
  onSubmit?: (value: Value[]) => void
  filterOptions?: (options: Value[]) => Value[]
  disabled?: boolean
  renderPreviewValue: (
    value: Value[],
  ) => string | ReactNode | ReactNode[] | undefined
  editable?: boolean
  isOptionEqualToValue?: (option: Value, value: Value) => boolean
  dataTestIdPrefix?: string
}

enum Mode {
  preview = 'preview',
  edit = 'edit',
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function MultipleSelectAutocompleteWithPreview<Value extends {}>({
  initValue,
  options,
  getOptionLabel,
  loading,
  optionsLoading,
  filterOptions,
  groupBy,
  onOpen,
  placeholder,
  onSubmit,
  disabled,
  renderPreviewValue,
  editable = true,
  isOptionEqualToValue,
  dataTestIdPrefix,
}: MultipleSelectAutocompleteWithPreviewProps<Value>) {
  const [value, setValue] = useState<Value[]>(initValue)

  useEffect(() => {
    setValue(initValue)
  }, [initValue])

  const renderEditMode = useCallback(
    ({ setMode, handlers }: RenderEditModeProps) => (
      <MultipleSelectAutocomplete
        onChange={(_event: React.SyntheticEvent<Element, Event>, value) =>
          setValue(value as Value[])
        }
        value={value}
        noOptionsText={loading ? 'Идёт загрузка' : 'Нет доступных свойств'}
        options={options}
        loading={optionsLoading}
        disabled={disabled}
        groupBy={option => groupBy(option as Value)}
        getOptionLabel={option => getOptionLabel(option as Value)}
        filterOptions={options =>
          filterOptions ? filterOptions(options as Value[]) : options
        }
        onOpen={onOpen}
        onBlur={value => {
          setMode(Mode.preview)
          onSubmit?.(value as Value[])
        }}
        isOptionEqualToValue={
          isOptionEqualToValue
            ? (option, value) =>
                isOptionEqualToValue(option as Value, value as Value)
            : undefined
        }
        popupIconVisible={false}
        focusedInput
        inputAutoFocus
        dataTestIdPrefix={dataTestIdPrefix}
      />
    ),
    [
      value,
      loading,
      options,
      optionsLoading,
      disabled,
      filterOptions,
      onOpen,
      isOptionEqualToValue,
      groupBy,
      getOptionLabel,
      onSubmit,
      dataTestIdPrefix,
    ],
  )

  return (
    <SelectPreview
      value={value}
      renderEditMode={renderEditMode}
      placeholder={placeholder}
      renderValue={renderPreviewValue}
      disabled={disabled}
      editable={editable}
    />
  )
}
