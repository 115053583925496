import { ReactNode } from 'react'

import { useHover } from '@gmini/utils'

import { Checkbox, RadioButton } from '@gmini/ui-kit'

import { filterCheckboxColors } from './filterCheckboxColors'
import { FilterItemType } from './FilterItem.types'
import {
  FilterCheckboxItemStyled,
  FilterItemLabel,
  RadioButtonWrapper,
} from './FilterItem.styled'

type FilterItemProps<T> = {
  onChange?: (item: T) => void
  item: T
  renderName?: (item: T) => ReactNode | ReactNode[]
  component?: 'checkbox' | 'radio'
  dataTestId?: string
}

export const FilterItem = <T extends FilterItemType>({
  onChange,
  renderName,
  item,
  component = 'checkbox',
  dataTestId,
}: FilterItemProps<T>) => {
  const { hover, onMouseLeave, onMouseOver } = useHover()

  return (
    <FilterCheckboxItemStyled>
      {component === 'radio' && (
        <RadioButtonWrapper>
          <RadioButton
            data-test-id={dataTestId}
            id={item.uniqId}
            name={item.uniqId}
            selected={item.selected}
            disabled={item.disabled}
            color='#4c5ecf'
            onChange={() => onChange?.(item)}
          />
        </RadioButtonWrapper>
      )}

      {component === 'checkbox' && (
        <Checkbox
          data-test-id={dataTestId}
          id={item.uniqId}
          name={item.uniqId}
          hover={hover}
          checked={item.selected}
          disabled={item.disabled}
          colors={filterCheckboxColors}
          onChange={() => onChange?.(item)}
        />
      )}

      <FilterItemLabel
        onMouseLeave={onMouseLeave}
        onMouseOver={onMouseOver}
        htmlFor={item.uniqId}
        disabled={item.disabled}
      >
        {renderName ? renderName(item) : item.name}
      </FilterItemLabel>
    </FilterCheckboxItemStyled>
  )
}
