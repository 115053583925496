import { AttributesService } from '@gmini/helpers'
import { isNotEmpty } from '@gmini/utils'
import { useCallback, useMemo } from 'react'

import { v4 as uuid } from 'uuid'

import { useStore } from 'effector-react'

import { LongText } from '@gmini/ui-kit'

import { FilterItem, FilterWrapper } from '../../atoms'

import { FilterNameWrapper } from './FilterAttribute.styled'

type DataTestProps = {
  filterItem: { name: string }
}

type FilterAttributeProps = {
  allowedItems: number[]
  attributeId: number
  urlKey: string
  disabled?: boolean
  attributeValueById$: AttributesService['attributeValueById$']
  loading?: boolean
  countSkeletonItems?: number
  onChange: (value: Record<string, number>) => void
  value: number[]
  dataTestIdFor?: Partial<DataTestProps>
  hideDeleted?: boolean
}

export const FilterAttribute = ({
  allowedItems,
  attributeId,
  urlKey,
  disabled,
  loading,
  attributeValueById$,
  countSkeletonItems,
  onChange,
  value,
  dataTestIdFor,
  hideDeleted,
}: FilterAttributeProps) => {
  const attributeValueById = useStore(attributeValueById$)

  const preparedAttributeValueList = useMemo(
    () =>
      allowedItems
        .map(id => {
          const item = attributeValueById[id]
          if (!item) {
            return null
          }

          return {
            ...item,
            uniqId: uuid(),
            selected: value.some(id => Number(id) === item.id),
            disabled,
          }
        })
        .filter(isNotEmpty)
        .filter(item => {
          if (hideDeleted) {
            return item?.attribute?.id === attributeId && !item.deleted
          }
          return item?.attribute?.id === attributeId
        }),
    [
      allowedItems,
      attributeValueById,
      value,
      disabled,
      hideDeleted,
      attributeId,
    ],
  )

  const renderName = useCallback(
    (item: (typeof preparedAttributeValueList)[0]) => (
      <FilterNameWrapper>
        <LongText partSize={20} withoutRightText>
          {item.name}
        </LongText>
      </FilterNameWrapper>
    ),
    [],
  )

  const filterName = dataTestIdFor?.filterItem?.name

  return (
    <FilterWrapper
      loading={!allowedItems.length && loading}
      countSkeletonItems={countSkeletonItems}
    >
      {preparedAttributeValueList.map(item => (
        <FilterItem
          key={item.uniqId}
          item={item}
          onChange={el => onChange({ [urlKey]: item.id })}
          renderName={renderName}
          dataTestId={
            filterName && `filter${filterName}_${item.name.toLowerCase()}`
          }
        />
      ))}
    </FilterWrapper>
  )
}
