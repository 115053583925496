import { useMemo } from 'react'

type UseAppliedFilterStatusData<S> = {
  urlKey: string
  statusList: S[]
  name: string
  value: string[]
}

export const useAppliedFilterStatusData = <
  S extends { status: string; name: string },
>({
  statusList,
  urlKey,
  name,
  value,
}: UseAppliedFilterStatusData<S>) =>
  useMemo(
    () => ({
      name,
      value: statusList
        .filter(item => value?.some(status => status === item.status))
        .map(item => item.name),
      urlKey,
    }),
    [name, statusList, urlKey, value],
  )
