export const fetchWorkdays = async (
  params: string,
): Promise<string | Error> => {
  const url = 'https://isdayoff.ru/api/getdata'
  const response = await fetch(`${url}${params}`)

  if (response.status === 200) {
    return response.text()
  }

  return new Error(`error while request ${url} - ${JSON.stringify(response)}`)
}
