import { Popover } from '@mui/material'
import React, { useRef, useState } from 'react'

import {
  Container,
  SelectedItem,
  StatusItem,
  StatusItemBadge,
  SelectArrow,
  StatusItemList,
} from './ColoredSelect.styled'
import { ColoredSelectItem } from './ColoredSelect.types'

type StatusSelectProps<T> = {
  selectedItem: T | undefined | null
  list: T[]
  onChange?: (item: T) => void
  disabled?: boolean
}

export const ColoredSelect = <T extends ColoredSelectItem>({
  list,
  selectedItem,
  onChange,
  disabled,
}: StatusSelectProps<T>) => {
  const [statusPopupOpen, setStatusPopupOpen] = useState(false)

  const anchorEl = useRef<HTMLDivElement>(null)

  const onClickSelectItem = (item: T) => {
    setStatusPopupOpen(false)
    onChange?.(item)
  }

  const canSelect = list.length > 0

  return (
    <Container>
      <SelectedItem
        data-test-id='changeIssueStatus'
        active={canSelect}
        disabled={disabled}
        bgColor={selectedItem?.bgColor || '#E5E7F1'}
        textColor={selectedItem?.textColor || '#353B60'}
        hoveredBgColor={selectedItem?.hoveredBgColor || '#CED1E3'}
        onClick={canSelect ? () => setStatusPopupOpen(true) : undefined}
        ref={anchorEl}
      >
        {selectedItem?.name || 'Не задан'}
        {canSelect ? <SelectArrow color='#fff' width='14px' /> : null}
      </SelectedItem>
      <Popover
        anchorEl={anchorEl.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={statusPopupOpen}
        onClose={() => setStatusPopupOpen(false)}
      >
        <StatusItemList>
          {list.map(item => (
            <StatusItem
              data-test-id={`changeIssueStatusListItem_${item.status}`}
              onClick={() => onClickSelectItem(item)}
              key={item.status}
            >
              <StatusItemBadge color={item.bgColor} />
              {item.name}
            </StatusItem>
          ))}
        </StatusItemList>
      </Popover>
    </Container>
  )
}
