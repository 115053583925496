import React, { SVGAttributes } from 'react'

export const AvatarIcon = ({
  color = '#353B60',
  ...otherProps
}: {
  color?: string
} & SVGAttributes<SVGSVGElement>) => (
  <svg
    width='10'
    height='12'
    viewBox='0 0 10 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...otherProps}
  >
    <path
      d='M5.0002 0.399902C6.3282 0.399902 7.4002 1.4719 7.4002 2.7999C7.4002 4.1279 6.3282 5.1999 5.0002 5.1999C3.6722 5.1999 2.6002 4.1279 2.6002 2.7999C2.6002 1.4719 3.6722 0.399902 5.0002 0.399902ZM5.0002 11.7599C3.0002 11.7599 1.2322 10.7359 0.200195 9.1839C0.224195 7.5919 3.4002 6.7199 5.0002 6.7199C6.5922 6.7199 9.7762 7.5919 9.8002 9.1839C8.7682 10.7359 7.0002 11.7599 5.0002 11.7599Z'
      fill={color}
    />
  </svg>
)
