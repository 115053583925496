export const passesFilterDate = (
  filterValue: (string | null)[] | null | undefined,
  value: string | null | undefined,
) => {
  if (!filterValue) {
    return true
  }

  const [startDate, endDate] = filterValue

  return Boolean(value! >= startDate! && value! <= endDate!) //TODO поставил тут !, потому что рассчитываю на приведение типов при сравнении
}
