import { LongText } from '@gmini/ui-kit'
import { ReactNode, useCallback, useEffect, useState } from 'react'

import { ItemPlaceholder } from '../../pages/EditIssuePage/EditIssue.styled'

import {
  SelectPreviewWrapper,
  SelectPreviewStyled,
} from './SelectPreview.styled'

import { Mode, RenderEditModeProps } from './SelectPreview.types'

type SelectPreviewProps<Value> = {
  renderEditMode?: (props: RenderEditModeProps) => ReactNode | ReactNode[]
  value: Value | null
  renderValue?: (value: Value) => string | ReactNode | ReactNode[] | undefined
  placeholder?: ReactNode | ReactNode[]
  disabled?: boolean
  editable?: boolean
  mode?: Mode
  onChangeMode?: (mode: Mode) => void
  previewStyle?: React.CSSProperties | null
  dataTestId?: string
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const SelectPreview = <Value extends {} | []>({
  renderEditMode,
  renderValue,
  value,
  placeholder,
  disabled,
  editable,
  mode,
  previewStyle,
  dataTestId,
  onChangeMode,
}: SelectPreviewProps<Value>) => {
  const [modeState, setMode] = useState<Mode>(mode || Mode.preview)
  const renderedValue = value ? renderValue?.(value) : ''
  const preparedValue =
    typeof renderedValue === 'string' ? (
      <LongText withoutRightText partSize={30}>
        {renderedValue}
      </LongText>
    ) : (
      renderedValue
    )

  useEffect(() => {
    if (mode) {
      setMode(mode)
    }
  }, [mode])

  const onKeyDown = useCallback(
    event => {
      const newValue = event.target.value
      if (modeState === Mode.edit && newValue !== value) {
        switch (event.key) {
          case 'Tab':
          case 'Enter':
            setMode(Mode.preview)
            onChangeMode?.(Mode.preview)
            break

          case 'Escape':
            setMode(Mode.preview)
            onChangeMode?.(Mode.preview)
            break
        }
      }
    },
    [modeState, onChangeMode, value],
  )

  switch (modeState) {
    case Mode.preview: {
      return (
        <SelectPreviewWrapper
          data-test-id={dataTestId}
          disabled={disabled}
          onClick={() => {
            if (editable) {
              setMode(Mode.edit)
              onChangeMode?.(Mode.edit)
            }
          }}
          editable={editable}
        >
          <SelectPreviewStyled styles={previewStyle}>
            {renderedValue ? (
              preparedValue
            ) : (
              <ItemPlaceholder>{placeholder}</ItemPlaceholder>
            )}
          </SelectPreviewStyled>
        </SelectPreviewWrapper>
      )
    }
    case Mode.edit: {
      return (
        <>
          {renderEditMode?.({
            mode: Mode.edit,
            setMode,
            handlers: { onKeyDown },
          })}
        </>
      )
    }
    default: {
      return null
    }
  }
}
