import styled from 'styled-components'

import { HighlightOff } from '@gmini/ui-kit'

export const HighlightOffIcon = styled(HighlightOff).attrs({
  color: 'rgba(53, 59, 96, 0.2)',
})`
  position: absolute;
  right: 7.5px;
  top: 0;
  bottom: 0;
  margin: auto 0;

  &:hover {
    cursor: pointer;
    & :first-child {
      fill: #353b60;
    }
  }
`
