import { CapitalizedTextWrapper, LongText } from '@gmini/ui-kit'

import { SimpleBubbleTitle } from './SimpleBubble.styled'

type SimpleBubbleProps = {
  title: string
  deleted?: boolean
  withoutTooltip?: boolean
}

export const SimpleBubble = ({
  title,
  deleted,
  withoutTooltip,
}: SimpleBubbleProps) => (
  <CapitalizedTextWrapper>
    <SimpleBubbleTitle isDeleted={deleted}>
      <LongText
        partSize={30}
        withoutRightText
        withoutTooltip={withoutTooltip ?? false}
      >
        {title}
      </LongText>
    </SimpleBubbleTitle>
  </CapitalizedTextWrapper>
)
