export const createDateRangeByMonths = (
  currentDate: Date,
  days: number,
  sign?: 'plus' | 'minus',
): [string, string] => {
  const date = new Date(currentDate)
  let resultDate = null

  if (sign === 'plus') {
    resultDate = date.getMonth() + days
  } else {
    resultDate = date.getMonth() - days
  }

  date.setMonth(resultDate)

  return [date.toISOString(), currentDate.toISOString()]
}
