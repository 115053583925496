import styled from 'styled-components'
import { RoundedCross as RoundedCrossInit } from '@gmini/ui-kit'

export const Wrapper = styled.div`
  padding: 16px 24px;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const Title = styled.div`
  font-size: 18px;
  line-height: 21px;
  color: #000335;
  letter-spacing: 0.1px;
`

export const RoundedCross = styled(RoundedCrossInit)`
  cursor: pointer;

  path {
    fill: #353b60;
  }
`

export const ErrorsWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 500px;
  overflow-y: auto;
`

export const ErrorMessage = styled.div`
  color: #000335;
`
