import styled, { css } from 'styled-components'

export const Bubble = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  padding: 0 5px;
  border-radius: 4px;
  background: rgba(76, 94, 207, 0.15);
  color: rgba(76, 94, 207, 1);
`

export const Count = styled.div`
  font-weight: 700;
  font-size: 11px;
  line-height: 17px;
`

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3px 0;
`

type TooltipItemProps = { crossOuted?: boolean }

export const TooltipItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0 5px;

  ${(props: TooltipItemProps) =>
    props.crossOuted &&
    css`
      && span {
        text-decoration: line-through;
      }
    `}
`

export const TooltipIconSubstrate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  background: #646464;
`

export const TooltipIconSubstrateCircle = styled(TooltipIconSubstrate)`
  border-radius: 50%;
`

export const TooltipIconSubstrateSquare = styled(TooltipIconSubstrate)`
  border-radius: 4px;
`
