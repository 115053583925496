import styled, { css } from 'styled-components'

import pencil from './pencil.svg'

const Text = css`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #00003d;
  letter-spacing: 0.15px;
`

export const SelectPreviewStyled = styled.div<{
  styles?: React.CSSProperties | null
}>`
  ${Text}
  ${({ styles }) => ({ ...(styles ? styles : {}) })};
  width: max-content;
  position: relative;
`

type SelectPreviewProps = {
  disabled?: boolean
  editable?: boolean
}

export const SelectPreviewWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;

  ${(props: SelectPreviewProps) =>
    (props.disabled || !props.editable) &&
    css`
      cursor: default;
    `}

  &:hover {
    ${SelectPreviewStyled} {
      &::after {
        position: absolute;
        top: -2px;
        bottom: 0;
        right: -28px;
        padding-left: 5px;
        opacity: 0.2;
        transform: scale(0.9);

        ${props =>
          !(props as SelectPreviewProps).disabled &&
          (props as SelectPreviewProps).editable &&
          css`
            content: url(${pencil});
          `}
      }
    }
  }
`
