import * as api from '@gmini/ism-api-sdk'

import { createDateRangeByDays } from '../../createDateRangeByDays'
import { createDateRangeByMonths } from '../../createDateRangeByMonths'
import {
  DateFilterItemsCode,
  IssueDateFilter,
  IssueDeadlineFilter,
  IssueListFilterDeadlineOptionCode,
} from '../enum'
import { FilterDateRangeByCode } from '../types/types'

export const issueAvailableFilters = [
  'show',
  'filter',
  'attributeValueIds',
  'statuses',
  'authorIds',
  'assignees',
  'createdDateRange',
  'updatedDateRange',
  'createdDateCode',
  'updatedDateCode',
  'deadlineRange',
  'deadlineCode',
  'sortByOperator',
  'sortByFieldName',
] as const

export const issueDateFilterMap = {
  [DateFilterItemsCode.ALL]: IssueDateFilter.ALL,
  [DateFilterItemsCode.LAST_WEEK]: IssueDateFilter.ONE_WEEK_BEFORE,
  [DateFilterItemsCode.LAST_2_WEEK]: IssueDateFilter.TWO_WEEKS_BEFORE,
  [DateFilterItemsCode.LAST_MONTH]: IssueDateFilter.ONE_MONTH_BEFORE,
  [DateFilterItemsCode.CUSTOM]: IssueDateFilter.CUSTOM,
}

export const deadlineFilterMap = {
  [IssueListFilterDeadlineOptionCode.ALL]: IssueDeadlineFilter.ALL,
  [IssueListFilterDeadlineOptionCode.ALMOST_EXPIRED]:
    IssueDeadlineFilter.ONE_WEEK_BEFORE,
  [IssueListFilterDeadlineOptionCode.ONLY_EXPIRED]: IssueDeadlineFilter.TODAY,
}

export const filterDateRangeByCode: FilterDateRangeByCode = {
  [DateFilterItemsCode.ALL]: null,
  [DateFilterItemsCode.CUSTOM]: null,
  [DateFilterItemsCode.LAST_WEEK]: currentDate =>
    createDateRangeByDays(currentDate, 7),
  [DateFilterItemsCode.LAST_2_WEEK]: currentDate =>
    createDateRangeByDays(currentDate, 14),
  [DateFilterItemsCode.LAST_MONTH]: currentDate =>
    createDateRangeByMonths(currentDate, 1),
}

export const replyStatusColorMap: Record<api.IssueAnswer.StatusEnum, string> = {
  DECLINED: '#ec868b',
  ACCEPTED: '#0bbb7b',
  PENDING: '#4c5ecf',
  DELETED: '#a2a3b7',
}
