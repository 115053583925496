import { Checkbox, Tooltip } from '@gmini/ui-kit'

import moment from 'moment'

import { DEFAULT_DISPLAY_DATE_FORMAT } from './constants'

import {
  Container,
  Image,
  File,
  FileWrapper,
  Download,
  Eye,
  Trash,
  ActionPanel,
  ActionButton,
  AttachmentInfo,
  AttachmentInfoTitle,
  AttachmentInfoUploadAt,
  AttachmentInfoSelect,
  AttachmentInfoWrapper,
  AttachmentInfoTextCutInvisible,
  AttachmentInfoTextCut,
} from './GalleryItem.styled'
import { GalleryItemFile } from './GalleryItem.types'
import { attachmentCheckboxColors } from './attachmentCheckboxColors'

type GalleryItemProps<F> = {
  file: F
  checked: boolean
  onChecked?: (file: F) => void
  onDelete?: (file: F) => void
  onClickView?: (file: F) => void
  onDownload?: (file: F) => void
  getImgSrc?: (file: F) => void
  disabledDelete?: boolean
  isDeletable?: (file: F) => boolean
}

export const GalleryItem = <F extends GalleryItemFile>({
  file,
  checked,
  onChecked,
  onDelete,
  onClickView,
  onDownload,
  getImgSrc,
  disabledDelete,
  isDeletable,
}: GalleryItemProps<F>) => {
  const imgSrc = getImgSrc?.(file)

  return (
    <Container>
      {imgSrc ? (
        <>
          <Image src={imgSrc} />
          <Eye onClick={() => onClickView?.(file)} />
        </>
      ) : (
        <FileWrapper>
          <File />
          {file.name.split('.').pop()}
        </FileWrapper>
      )}
      <ActionPanel>
        {onDownload && (
          <ActionButton onClick={() => onDownload(file)}>
            <Download />
          </ActionButton>
        )}
        {onDelete && isDeletable?.(file) && (
          <ActionButton
            disabled={disabledDelete}
            onClick={() => onDelete(file)}
          >
            <Trash />
          </ActionButton>
        )}
      </ActionPanel>
      <AttachmentInfoWrapper>
        <AttachmentInfo>
          <AttachmentInfoTitle>
            <Tooltip title={file.name}>
              <AttachmentInfoTextCut>
                <AttachmentInfoTextCutInvisible>
                  {file.name}
                </AttachmentInfoTextCutInvisible>
              </AttachmentInfoTextCut>
            </Tooltip>
          </AttachmentInfoTitle>
          <AttachmentInfoUploadAt>
            {moment(file.uploadAt).format(DEFAULT_DISPLAY_DATE_FORMAT)}
          </AttachmentInfoUploadAt>
        </AttachmentInfo>
        <AttachmentInfoSelect>
          <Checkbox
            id={file.id.toString()}
            name={file.name}
            checked={checked}
            colors={attachmentCheckboxColors}
            onChange={() => {
              onChecked?.(file)
            }}
          />
        </AttachmentInfoSelect>
      </AttachmentInfoWrapper>
    </Container>
  )
}
