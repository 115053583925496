import styled from 'styled-components'

export const AnchorLinkStyled = styled.span`
  color: #4c5ecf;
  letter-spacing: 0.15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  &:hover {
    text-decoration: underline;
  }
`
