import { createUsersService } from '@gmini/helpers'

export const {
  fetchUserList: fetchProjectUserList,
  fetchUserListPending$: fetchProjectUserListPending$,
  useFilteredUserList: useFilteredProjectUserList,
  useUserList: useProjectUserList,
  userList$: projectUserList$,
} = createUsersService()

export const {
  fetchUserList: fetchAllUserList,
  fetchUserListPending$: fetchAllUserListPending$,
  useFilteredUserList: useFilteredAllUserList,
  useUserList: useAllUserList,
  userList$: allUserList$,
} = createUsersService()
