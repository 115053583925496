import { LongText, SelectByGroups } from '@gmini/ui-kit'
import {
  Group,
  RenderOptionParams,
} from '@gmini/ui-kit/lib/SelectByGroups/SelectByGroups.types'

import * as ismApi from '@gmini/ism-api-sdk'

import { ReactNode, useCallback, useMemo } from 'react'

import { AssigneeListItem } from '@gmini/helpers'

import { FilterUserItem } from '../../atoms'

import {
  FilterUserWrapper,
  FirstElWrapper,
  GroupTitleStyled,
  Separator,
} from './FilterAssigneeMultiple.styled'

type FilterUserInfoType = {
  id: string
}

export type FilterAssigneeMultipleGroup = Group<AssigneeListItem> & {
  source: ismApi.AssigneeSource
}

type FilterAssigneeMultipleMultiple<UI> = {
  assigneeGroupList: FilterAssigneeMultipleGroup[]
  userInfo: UI | null
  allowedItems: ismApi.Assignee[]
  urlKey: string
  labelFirstEl: string
  loading?: boolean
  disabled?: boolean
  countSkeletonItems?: number
  placeholder?: string
  onChange: (value: Record<string, AssigneeListItem>) => void
  value: AssigneeListItem[]
}

const currentUserId = 'currentUserId'

export const FilterAssigneeMultiple = <UI extends FilterUserInfoType>({
  allowedItems,
  assigneeGroupList,
  userInfo,
  urlKey,
  disabled,
  loading,
  countSkeletonItems,
  placeholder,
  labelFirstEl,
  onChange,
  value,
}: FilterAssigneeMultipleMultiple<UI>) => {
  const firstEl = useMemo(
    () =>
      ({
        assigneeId: currentUserId,
        source: ismApi.AssigneeSource.USER,
        label: labelFirstEl,
      } as AssigneeListItem),
    [labelFirstEl],
  )

  const allowedAssigneeGroupList: FilterAssigneeMultipleGroup[] =
    useMemo(() => {
      let result = assigneeGroupList.map(group => ({
        ...group,
        options: group.options.filter(
          option =>
            allowedItems.some(
              ({ assigneeId, source }) =>
                option.source === source && assigneeId === option.assigneeId,
            ) && option.assigneeId !== userInfo?.id,
        ),
      }))

      if (
        userInfo &&
        allowedItems.some(({ assigneeId }) => assigneeId === userInfo.id)
      ) {
        result = result.map(group => {
          if (group.source === ismApi.AssigneeSource.USER) {
            return { ...group, options: [firstEl, ...group.options] }
          }

          return group
        })
      }

      return result
    }, [allowedItems, assigneeGroupList, firstEl, userInfo])

  const renderOption = useCallback(
    (params: RenderOptionParams<AssigneeListItem>): ReactNode => {
      if (userInfo && params.option.assigneeId === currentUserId) {
        return (
          <FirstElWrapper>
            <FilterUserItem
              item={userInfo}
              label={
                <LongText partSize={20} withoutRightText>
                  {labelFirstEl}
                </LongText>
              }
              onChange={() =>
                onChange?.({
                  [urlKey]: {
                    assigneeId: userInfo.id,
                    label: labelFirstEl,
                    source: ismApi.AssigneeSource.USER,
                  },
                })
              }
              checked={value.some(
                ({ assigneeId, source }) =>
                  source === ismApi.AssigneeSource.USER &&
                  assigneeId === userInfo.id,
              )}
              getId={item => item.id}
            />
            <Separator />
          </FirstElWrapper>
        )
      }

      return (
        <FilterUserItem
          item={params.option}
          label={
            <LongText partSize={20} withoutRightText>
              {params.option.label}
            </LongText>
          }
          onChange={() => onChange({ [urlKey]: params.option })}
          checked={value.some(
            ({ assigneeId, source }) =>
              source === params.option.source &&
              assigneeId === params.option.assigneeId,
          )}
          getId={item => `${item.source}__${item.assigneeId}`}
        />
      )
    },
    [labelFirstEl, onChange, urlKey, userInfo, value],
  )

  return (
    <FilterUserWrapper>
      <SelectByGroups
        data-test-id='filterByAssignee'
        getOptionLabel={value => value.label}
        groups={allowedAssigneeGroupList}
        emptyOptionListMessage='Нет доступных совпадений'
        loading={loading}
        placeholder={placeholder}
        disabled={
          disabled ||
          !allowedAssigneeGroupList.some(({ options }) => options.length)
        }
        value={null}
        hideInputIcon
        allowDelete={false}
        optionListStyles={{
          width: '364px',
          paddingBottom: '10px',
        }}
        renderOption={renderOption}
        popoverBoxShadow='0px 4px 10px rgba(0, 0, 0, 0.25)'
        // eslint-disable-next-line no-empty-function
        onChange={() => {}}
        style={{ paddingRight: '9.5px' }}
        groupTitleStyled={GroupTitleStyled}
      />
    </FilterUserWrapper>
  )
}
