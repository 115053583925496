import { ReactNode, useCallback, useEffect, useState } from 'react'

import { SelectByGroupsMultiple } from '@gmini/ui-kit'
import { Group } from '@gmini/ui-kit/lib/SelectByGroups/SelectByGroups.types'

import { SelectPreview } from '../../atoms'
import { RenderEditModeProps } from '../../atoms/SelectPreview/SelectPreview.types'

type SelectByGroupsMultipleWithPreviewProps<Value> = {
  groups: Group<Value>[]
  getOptionLabel: (value: Value) => string
  placeholder: string
  onSubmit?: (value: Value[]) => void
  disabled?: boolean
  renderPreviewValue: (
    value: Value[],
  ) => string | ReactNode | ReactNode[] | undefined
  allowDelete?: boolean
  autofocus?: boolean
  editable?: boolean
  initValue: Value[]
  dataTestIdPrefix?: string
}

enum Mode {
  preview = 'preview',
  edit = 'edit',
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function SelectByGroupsMultipleWithPreview<Value extends {}>({
  groups,
  getOptionLabel,
  placeholder,
  onSubmit,
  disabled,
  renderPreviewValue,
  allowDelete = true,
  autofocus = true,
  editable = true,
  initValue,
  dataTestIdPrefix,
}: SelectByGroupsMultipleWithPreviewProps<Value>) {
  const [value, setValue] = useState<Value[]>(initValue)

  useEffect(() => {
    setValue(initValue)
  }, [initValue])

  const renderEditMode = useCallback(
    ({ setMode, handlers }: RenderEditModeProps) => (
      <SelectByGroupsMultiple
        value={value}
        groups={groups}
        onChange={setValue}
        getOptionLabel={getOptionLabel}
        placeholder={placeholder}
        onKeyDown={handlers.onKeyDown}
        allowDelete={allowDelete}
        colorScheme='white'
        autofocus={autofocus}
        onBlur={value => {
          setMode(Mode.preview)
          onSubmit?.(value)
        }}
        optionDataTestIdPrefix={`${dataTestIdPrefix}Option`}
      />
    ),
    [
      allowDelete,
      autofocus,
      getOptionLabel,
      groups,
      onSubmit,
      placeholder,
      value,
      dataTestIdPrefix,
    ],
  )

  return (
    <SelectPreview
      value={value}
      renderEditMode={renderEditMode}
      placeholder={placeholder}
      renderValue={renderPreviewValue}
      disabled={disabled}
      editable={editable}
      dataTestId='editAssignee'
    />
  )
}
