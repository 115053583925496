import * as R from 'ramda'

type AsyncFunction<T, U> = (args: T) => Promise<U>

type ResultMapping<T, U> = {
  [K in keyof Partial<T>]: keyof U
}

export function runSequentially<T extends object, U>(
  asyncFunctions: AsyncFunction<T, U>[],
  initialArgs: T,
  resultMapping: ResultMapping<T, U> = {} as ResultMapping<T, U>,
): Promise<U[]> {
  return R.reduce<
    AsyncFunction<T, U>,
    Promise<{ results: U[]; prevResult: U | T }>
  >(
    (acc, fn) =>
      acc.then(({ results, prevResult }) => {
        const args = R.mergeRight(
          initialArgs,
          R.mapObjIndexed(
            (path, key) => R.path([path as string], prevResult),
            resultMapping,
          ) as Partial<T>,
        ) as T
        return fn(args).then(result => ({
          results: [...results, result],
          prevResult: result,
        }))
      }),
    Promise.resolve({ results: [] as U[], prevResult: initialArgs }),
    asyncFunctions,
  ).then(({ results }) => results)
}
