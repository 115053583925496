import { createStore } from 'effector'

import { ApiCallService } from '@gmini/api-call-service'

export const allowedFiltersService = <P, R>({
  fetchAllowedFiltersApi,
}: {
  fetchAllowedFiltersApi: ApiCallService<P, R>
}) => {
  const fetchAllowedFilters = fetchAllowedFiltersApi.createContext()
  const fetchAllowedFiltersPending$ = fetchAllowedFilters.pending$

  const allowedFilters$ = createStore<R | null>(null).on(
    fetchAllowedFilters.doneData,
    (_, data) => data,
  )

  return {
    allowedFilters$,
    fetchAllowedFilters,
    fetchAllowedFiltersPending$,
  }
}
