import { useMemo } from 'react'

import { v4 as uuid } from 'uuid'

import { FilterItem, FilterWrapper } from '../../atoms'

import { FilterShowItem, FilterShowItemType } from './FilterShow.types'

type FilterShow = {
  filterItem: { name: string }
}

type FilterShowProps<T> = {
  typeList: T[]
  urlKey: string
  loading?: boolean
  disabled?: boolean
  countSkeletonItems?: number
  onChange: (value: Record<string, string>) => void
  dataTestIdFor?: Partial<FilterShow>
  value: FilterShowItemType
}

export const FilterShow = <T extends FilterShowItem>({
  typeList,
  urlKey,
  disabled,
  loading,
  countSkeletonItems,
  onChange,
  dataTestIdFor,
  value,
}: FilterShowProps<T>) => {
  const preparedTypeList = useMemo(
    () =>
      typeList.map(item => ({
        ...item,
        uniqId: uuid(),
        selected: value === item.type,
        disabled,
      })),
    [typeList, disabled, value],
  )

  const filterName = dataTestIdFor?.filterItem?.name

  return (
    <FilterWrapper
      loading={!typeList.length && loading}
      countSkeletonItems={countSkeletonItems}
    >
      {preparedTypeList.map(item => (
        <FilterItem
          key={item.uniqId}
          item={item}
          component='radio'
          onChange={el => onChange({ [urlKey]: el.type })}
          dataTestId={
            filterName && `filter${filterName}By_${item.type.toLowerCase()}`
          }
        />
      ))}
    </FilterWrapper>
  )
}
