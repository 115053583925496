import styled, { css } from 'styled-components'

export const IssueOfficialReplyWrapper = styled.div``

type OfficialReplyProps = {
  border: string
}

export const OfficialReply = styled.div`
  padding: 12px 14px;
  @media (max-width: 920px) {
    padding: 40px 12px 14px;
    transition: all 0.2s ease-in-out;
  }
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  ${(props: OfficialReplyProps) =>
    props.border === '#4c5ecf'
      ? css`
          border: 1px solid rgba(76, 94, 207, 0.3);
          background: #f1f3ff;
        `
      : css`
          border: 1px solid ${props.border};
          background: #f4f4f8;
        `};
  position: relative;
`
type StatusWrapperProps = {
  background: string
}

export const StatusWrapper = styled.div`
  position: absolute;
  top: -1px;
  right: -1px;
  display: flex;
  align-items: center;
  * {
    background: ${(props: StatusWrapperProps) => props.background};
  }
`

export const ReplyStatus = styled.div`
  padding: 11px 12px 9px 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: #ffffff;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
`
export const Corner = styled.div`
  position: absolute;
  left: -19px;
  height: 34px;
  width: 20px;
  clip-path: polygon(0 0, 100% 0, 100% 96%);
`
export const ReplyAuthorAndDate = styled.div`
  display: flex;
  align-items: center;
`
export const UserBadge = styled.div`
  width: 32px;
  min-width: 32px;
  height: 32px;
  background: #a2a3b7;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: #ffffff;
  border-radius: 50%;
  text-transform: uppercase;
`
export const DateInfo = styled.div`
  margin-left: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 61, 0.6);
`
export const UserLabel = styled.div`
  margin-left: 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 61, 0.7);
`
export const ReplyText = styled.div`
  margin-left: 42px;
  margin-top: 3px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #00003d;
`
export const ButtonContainer = styled.div`
  & button {
    font-size: 14px;
    padding: 8px;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 12px;
  padding-top: 12px;
  padding-right: 14px;
`
