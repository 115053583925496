import styled, { css } from 'styled-components'

import { AttachFileSize, AttachFileVariant } from './AttachFile.types'

export const Wrapper = styled.div`
  width: max-content;
  height: max-content;
`

type LabelProps = {
  disabled?: boolean
  variant: AttachFileVariant
  size: AttachFileSize
}

export const Label = styled.label`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 3px;
  font-weight: 500;
  border-radius: 4px;
  transition: all 0.2s ease-out;
  padding: 4px;
  cursor: pointer;
  user-select: none;
  font-size: 12px;

  & svg {
    ${(props: LabelProps) => {
      switch (props.size) {
        case 'medium': {
          return css`
            max-width: 24px;
            max-height: 24px;
          `
        }
        case 'small': {
          return css`
            max-width: 20px;
            max-height: 20px;
          `
        }
      }
    }}
  }

  ${(props: LabelProps) =>
    props.disabled &&
    css`
      && {
        background: rgba(53, 59, 96, 0.05);
        pointer-events: none;
        color: rgba(53, 59, 96, 0.2);

        path {
          fill: rgba(53, 59, 96, 0.2);
        }
      }
    `}

  ${(props: LabelProps) => {
    switch (props.variant) {
      case 'blue': {
        return css`
          color: #4c5ecf;

          & path {
            fill: #4c5ecf;
            transition: background-color 0.2s ease-out, color 0.2s ease-out;
          }

          &:hover {
            background: rgba(53, 59, 96, 0.1);
          }

          &:active {
            background: rgba(53, 59, 96, 0.2);
          }
        `
      }
      case 'default': {
        return css`
          color: rgba(53, 59, 96, 0.5);

          & path {
            fill: rgba(53, 59, 96, 0.5);
            transition: background-color 0.2s ease-out, color 0.2s ease-out;
          }

          &:hover {
            background: rgba(53, 59, 96, 0.1);
          }

          &:active {
            background: rgba(53, 59, 96, 0.2);
          }

          ${(props: LabelProps) =>
            props.disabled &&
            css`
              && {
                background: inherit;
              }
            `}
        `
      }
    }
  }}
`

export const Input = styled.input`
  display: none;
`
