import styled from 'styled-components'

type FieldErrorProps = {
  hidden: boolean
}

export const FieldError = styled.div`
  display: ${(props: FieldErrorProps) => (props.hidden ? 'none' : 'flex')};
  flex-direction: column;
  gap: 3px;
  color: #d9575e;
  font-size: 12px;
  font-weight: 500;
  margin-left: 6px;
  margin-top: 4px;
  min-height: 21px;
  max-height: 140px;
  overflow-y: auto;
`
