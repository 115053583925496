import { useMemo } from 'react'

import { AssigneeListItem } from '../createAssigneeGroupListService'

type UseAppliedFilterAssigneeDataProps = {
  urlKey: string
  name: string
  value: AssigneeListItem[]
}

export const useAppliedFilterAssigneeData = ({
  urlKey,
  name,
  value,
}: UseAppliedFilterAssigneeDataProps) =>
  useMemo(
    () => ({
      name,
      value: value.map(({ label }) => label),
      urlKey,
    }),
    [name, urlKey, value],
  )
