import { ReactNode, useCallback, useState } from 'react'

import { ValidationRules } from './useFilesValidation.types'

type UseFileValidationProps = {
  rules: ValidationRules
  onError?: (errorMessage: ErrorMessage[]) => void
}

export type ErrorMessage = string | ReactNode

//TODO Разбить функцию validateFiles так, чтобы облегчить добавление нового правила валидации
export const useFilesValidation = ({
  rules,
  onError,
}: UseFileValidationProps): {
  validateFiles: (files: File[], attachedFileCount?: number) => File[]
  fileErrorMessages: ErrorMessage[] | null
  clearFileErrors: () => void
} => {
  const [fileErrorMessages, setFileErrorMessages] = useState<
    (string | ReactNode)[] | null
  >(null)

  const clearFileErrors = () => {
    setFileErrorMessages(null)
  }

  const validateFiles = useCallback(
    (files: File[], attachedFileCount?: number) => {
      const sizeErrorFiles: File[] = []
      const extensionErrorFiles: File[] = []
      const maxCountErrorsFiles: File[] = []

      const allowFiles = files.filter(file => {
        let isValid = true

        // Валидация на размер файла
        if (rules.checkSize) {
          const tooLargeFile = file.size > rules.checkSize.maxSize

          if (tooLargeFile) {
            sizeErrorFiles.push(file)
            isValid = false
          }
        }

        // Валидация на расширение файла
        if (rules.checkExtension) {
          const allowExtension = rules.checkExtension.whiteListExtension.some(
            extension =>
              extension.toLocaleLowerCase() ===
              file.name.split('.').pop()?.toLocaleLowerCase(),
          )

          if (!allowExtension) {
            extensionErrorFiles.push(file)
            isValid = false
          }
        }

        return isValid
      })

      let resultFiles = allowFiles
      // Валидация на количество файлов
      const maxCountFiles = rules.checkCount?.maxCount
      if (maxCountFiles) {
        // Вычисляем, сколько файлов можно загрузить до максимального значения
        const freeSpaceFiles = maxCountFiles - (attachedFileCount || 0)

        // Выпиливаем файлы, которые не поместились
        resultFiles = allowFiles.slice(0, freeSpaceFiles)
        if (freeSpaceFiles < allowFiles.length) {
          // Пушим выпиленные файлов в массив с ошибками
          allowFiles.slice(freeSpaceFiles - allowFiles.length).forEach(file => {
            maxCountErrorsFiles.push(file)
          })
        }
      }

      const checkSizeMessage = rules.checkSize?.message
      const checkExtensionMessage = rules.checkExtension?.message
      const checkCountMessage = rules.checkCount?.message

      const errorMessagesArray = []

      if (sizeErrorFiles.length && checkSizeMessage) {
        errorMessagesArray.push(
          typeof checkSizeMessage === 'string'
            ? checkSizeMessage
            : checkSizeMessage(sizeErrorFiles),
        )
      }

      if (extensionErrorFiles.length && checkExtensionMessage) {
        errorMessagesArray.push(
          typeof checkExtensionMessage === 'string'
            ? checkExtensionMessage
            : checkExtensionMessage(extensionErrorFiles),
        )
      }

      if (maxCountErrorsFiles.length && checkCountMessage) {
        errorMessagesArray.push(
          typeof checkCountMessage === 'string'
            ? checkCountMessage
            : checkCountMessage(maxCountErrorsFiles),
        )
      }

      if (onError && errorMessagesArray.length) {
        onError(errorMessagesArray)
      }

      setFileErrorMessages(
        errorMessagesArray.length ? errorMessagesArray : null,
      )

      return resultFiles
    },
    [
      onError,
      rules.checkCount?.maxCount,
      rules.checkCount?.message,
      rules.checkExtension,
      rules.checkSize,
    ],
  )

  return {
    validateFiles,
    fileErrorMessages,
    clearFileErrors,
  }
}
