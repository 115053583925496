import * as smApi from '@gmini/sm-api-sdk'
import * as api from '@gmini/ism-api-sdk'
import { FormAttribute, FormAttributeValue } from '@gmini/components'

import { Store, createStore } from 'effector'

import { clone } from 'ramda'

import { isNotEmpty } from '@gmini/utils'

import { useStoreMap } from 'effector-react'

import { AttributesService } from '../attributesService'

export type AttributesFormService = {
  optionsByAttributeId$: Store<OptionsByAttributeId>
  getFormAttributes: (
    fullAttributeList: smApi.Attribute.Data[],
    attributes: api.Attribute[],
    attributeValues: smApi.Attribute.FetchAttributeValuesRes['attributeValues'],
  ) => FormAttribute[]
  getFormAttributesValues: (
    attributes: api.Attribute[],
    attributeId: number,
    attributeValues: smApi.Attribute.FetchAttributeValuesRes['attributeValues'],
  ) => FormAttributeValue[]
  attributeValues$: Store<
    smApi.Attribute.FetchAttributeValuesRes['attributeValues']
  >
  useAttributesForEditing: (
    usedAttributes: api.Attribute[],
    projectUrn: string | null,
  ) => smApi.Attribute.Data[]
}

export type CreateAttributesFormService = {
  attributesService: AttributesService
}
type OptionsByAttributeId = Record<number, FormAttributeValue[]>

export function createAttributesFormService({
  attributesService,
}: CreateAttributesFormService): AttributesFormService {
  const optionsByAttributeId$: AttributesFormService['optionsByAttributeId$'] =
    createStore<OptionsByAttributeId>({}).on(
      attributesService.attributeValuesWithGroupsByAttributeId$.updates,
      (state, result) => {
        const next = clone(state)

        for (const attributeId in result) {
          if (result[attributeId]) {
            next[attributeId] = result[attributeId]
              .map(({ id, groupLabel, name, deleted }) => ({
                id,
                groupLabel,
                name,
                hidden: deleted,
              }))
              .sort((a, b) => {
                if (a.groupLabel === b.groupLabel) {
                  return a.name.localeCompare(b.name)
                }

                return a.groupLabel.localeCompare(b.groupLabel)
              })
          }
        }

        return next
      },
    )

  const attributeValues$ = createStore<
    smApi.Attribute.FetchAttributeValuesRes['attributeValues']
  >([]).on(
    attributesService.fetchAttributeValues.doneData,
    (_, result) => result.attributeValues,
  )

  function getFormAttributes(
    fullAttributeList: smApi.Attribute.Data[],
    attributes: api.Attribute[],
    attributeValues: smApi.Attribute.FetchAttributeValuesRes['attributeValues'],
  ): FormAttribute[] {
    return fullAttributeList.length
      ? fullAttributeList.map(attribute => {
          const filledAttribute = attributes.find(
            ({ id }) => id === attribute.id,
          )

          return {
            attributeId: attribute.id,
            name: attribute.name,
            deleted: attribute.deleted,
            values: filledAttribute
              ? filledAttribute.valueIds
                  .map(id => {
                    const currentValue = attributeValues.find(
                      value => value.id === id,
                    )

                    if (currentValue && !currentValue.deleted) {
                      return {
                        groupLabel: currentValue.group?.name || '',
                        id: currentValue.id,
                        name: currentValue.name,
                      }
                    }
                  })
                  .filter(isNotEmpty)
              : [],
          }
        })
      : []
  }

  function getFormAttributesValues(
    attributes: api.Attribute[],
    attributeId: number,
    attributeValues: smApi.Attribute.FetchAttributeValuesRes['attributeValues'],
  ): FormAttributeValue[] {
    const currentAttribute = attributes.find(({ id }) => id === attributeId)
    if (!attributes.length || !currentAttribute) {
      return []
    }

    return currentAttribute.valueIds
      .map(id => {
        const currentValue = attributeValues.find(value => value.id === id)

        if (currentValue) {
          return {
            groupLabel: currentValue.group?.name || '',
            id: currentValue.id,
            name: currentValue.name,
          }
        }
      })
      .filter(isNotEmpty)
  }

  function useAttributesForEditing(
    usedAttributes: api.Attribute[],
    projectUrn: string | null,
  ) {
    return useStoreMap({
      store: attributesService.attributeListByProjectUrn$,
      keys: [projectUrn, usedAttributes],
      fn: (attributesByProjectUrn, [projectUrn, usedAttributes]) => {
        const usedAttributeIdsMap = usedAttributes?.reduce(
          (acc: Record<number, boolean>, item) => {
            acc[item.id] = true
            return acc
          },
          {},
        )
        if (!projectUrn) {
          return []
        }

        return (
          attributesByProjectUrn[projectUrn]?.filter(
            attribute =>
              (attribute.deleted && usedAttributeIdsMap[attribute.id]) ||
              !attribute.deleted,
          ) || []
        )
      },
    })
  }

  return {
    optionsByAttributeId$,
    getFormAttributes,
    getFormAttributesValues,
    attributeValues$,
    useAttributesForEditing,
  }
}
