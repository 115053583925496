import { ApiCallService } from '@gmini/api-call-service'
import { combine, createEvent, createStore } from 'effector'
import { clone } from 'ramda'

import * as api from '@gmini/ism-api-sdk'

export function createIssueCommentService({
  fetchIssueCommentListApi,
  removeIssueCommentApi,
  createIssueCommentApi,
}: {
  fetchIssueCommentListApi: ApiCallService<
    api.IssueComment.FetchListParams,
    api.IssueComment.FetchListData
  >
  removeIssueCommentApi: ApiCallService<
    api.IssueComment.RemoveParams,
    api.IssueComment
  >
  createIssueCommentApi: ApiCallService<
    api.IssueComment.CreateParams,
    api.IssueComment
  >
}) {
  const reset = createEvent<{ issueId: number }>()

  const fetchIssueCommentList = fetchIssueCommentListApi.createContext()
  const fetchIssueCommentListPending$ = fetchIssueCommentList.pending$

  const removeIssueComment = removeIssueCommentApi.createContext()
  const removeIssueCommentPending$ = removeIssueComment.pending$

  const createIssueComment = createIssueCommentApi.createContext()
  const createIssueCommentPending$ = createIssueComment.pending$

  const buildCommentKey = ({
    id,
    status,
    type,
  }: {
    id: number
    type: api.IssueCommentType
    status?: string | null
  }) => `${type}__${id}__${status ? status : ''}`

  type ById = {
    [id: string]: api.IssueComment.Item & { key: string }
  }

  type IdsByIssueId = Record<number, string[]>

  const byId$ = createStore<ById>({})
    .on(fetchIssueCommentListApi.doneData, (state, result) => {
      const next = clone(state)

      result.list.forEach(comment => {
        const key = buildCommentKey(comment)
        return (next[key] = { ...comment, key })
      })

      return next
    })
    .on(removeIssueComment.doneData, (state, nextComment) => {
      const next = clone(state)

      if (
        Object.prototype.hasOwnProperty.call(next, buildCommentKey(nextComment))
      ) {
        const key = buildCommentKey(nextComment)
        next[key] = { ...nextComment, key }
      }

      return next
    })
    .on(createIssueComment.doneData, (state, comment) => {
      const next = clone(state)

      const key = buildCommentKey(comment)

      next[key] = { ...comment, key }

      return next
    })

  const idsByIssueId$ = createStore<IdsByIssueId>({})
    .on(fetchIssueCommentList.done, (state, { params, result }) => {
      const next = clone(state)
      const { issueId, offset = 0 } = params

      next[issueId] = [
        ...new Set([
          ...(next[issueId] || []).slice(0, offset),
          ...result.list.map(comment => buildCommentKey(comment)),
          ...(next[issueId] || []).slice(offset),
        ]),
      ]

      return next
    })
    .on(createIssueComment.doneData, (state, comment) => {
      const next = clone(state)

      next[comment.issueId] = [
        ...new Set([
          buildCommentKey(comment),
          ...(next[comment.issueId] || []),
        ]),
      ]

      return next
    })
    .on(reset, (state, { issueId }) => {
      const next = clone(state)

      if (Object.prototype.hasOwnProperty.call(next, issueId)) {
        delete next[issueId]
      }

      return next
    })

  const totalByIssueId$ = createStore<Record<number, number>>({})
    .on(fetchIssueCommentList.done, (state, { params, result }) => {
      const next = clone(state)

      next[params.issueId] = result.total

      return next
    })
    .on(createIssueComment.done, (state, { params }) => {
      const next = clone(state)

      next[params.issueId] = next[params.issueId] + 1

      return next
    })

  return {
    issueCommentList$: combine({ byId$, idsByIssueId$, totalByIssueId$ }),
    removeIssueComment,
    removeIssueCommentPending$,
    createIssueComment,
    createIssueCommentPending$,
    fetchIssueCommentList,
    fetchIssueCommentListPending$,
    reset,
  }
}
