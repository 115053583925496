import * as smApi from '@gmini/sm-api-sdk'
import { combine, createEvent, createStore } from 'effector'
import { clone } from 'ramda'

import {
  relativeDateFilterMap,
  relativeDeadlineFilterMap,
  issueFilterService,
} from '@gmini/helpers'

import { OmniSubscriptionService } from './createOmniSubscriptionService.types'

export const createOmniSubscriptionService =
  (): OmniSubscriptionService.Service => {
    const createSubscription = smApi.Omni.createOmniSubscription.createContext()
    const removeSubscription = smApi.Omni.removeOmniSubscription.createContext()
    const fetchList = smApi.Omni.fetchOmniSubscriptions.createContext()

    const subscriptionsPending$ = combine(
      [createSubscription.pending$, removeSubscription.pending$],
      pendings => pendings.some(Boolean),
    )

    const updateSubscriptionsMap = createEvent<{ [x: string]: string }>()

    const createKeyIssueSubscription = ({
      issueId,
      projectUrn,
    }: {
      issueId: string
      projectUrn: string
    }) =>
      createKeySubscription({
        attributes: ['issue.id'],
        values: [issueId],
        moduleId: smApi.Omni.ModuleId.ISSUE_MANAGEMENT,
        resourceName: smApi.Omni.ResourceName.ISSUE,
        projectUrn,
      })

    const createKeySubscription = ({
      attributes,
      moduleId,
      projectUrn,
      resourceName,
      values,
    }: Pick<
      smApi.Omni.CreateOmniSubscriptionParams,
      'moduleId' | 'projectUrn' | 'resourceName'
    > & {
      attributes: string[]
      values: (string | number)[]
    }): string =>
      [moduleId, projectUrn, resourceName, ...attributes, ...values].join('_')

    type AttributeKeyValue = Pick<smApi.Omni.Attribute, 'attribute' | 'value'>

    const createKeyFromClientFilters = ({
      clientFilters,
      projectUrn,
    }: {
      clientFilters: issueFilterService.SavedFilterType
      projectUrn: string
    }) => {
      const status = clientFilters.statuses
        ? clientFilters.statuses.reduce(
            (acc: AttributeKeyValue[], statusName) => {
              acc.push({
                attribute: 'issue.status',
                value: statusName,
              })

              return acc
            },
            [],
          )
        : []

      const updateAt: AttributeKeyValue[] = clientFilters.updatedDateCode
        ? [
            {
              attribute: 'issue.updatedAt',
              value: relativeDateFilterMap[clientFilters.updatedDateCode],
            },
          ]
        : []

      const createAt: AttributeKeyValue[] = clientFilters.createdDateCode
        ? [
            {
              attribute: 'issue.createdAt',
              value: relativeDateFilterMap[clientFilters.createdDateCode],
            },
          ]
        : []

      const deadline: AttributeKeyValue[] = clientFilters.deadlineCode
        ? [
            {
              attribute: 'issue.deadline',
              value: relativeDeadlineFilterMap[clientFilters.deadlineCode],
            },
          ]
        : []

      const author = clientFilters.authorIds
        ? clientFilters.authorIds.reduce((acc: AttributeKeyValue[], id) => {
            acc.push({
              attribute: 'issue.author',
              value: id,
            })
            return acc
          }, [])
        : []

      const assignee: AttributeKeyValue[] = clientFilters.assignees
        ? clientFilters.assignees.map(el => ({
            attribute: 'approvers',
            value: el.assigneeId,
          }))
        : []

      const attributes: AttributeKeyValue[] = clientFilters.attributeValueIds
        ?.length
        ? clientFilters.attributeValueIds.map(el => ({
            attribute: 'issue.attributeValueIds',
            value: el,
          }))
        : []
      const attrs = [
        ...status,
        ...updateAt,
        ...createAt,
        ...deadline,
        ...author,
        ...assignee,
        ...attributes,
      ]
      return createKeySubscription({
        projectUrn,
        moduleId: smApi.Omni.ModuleId.ISSUE_MANAGEMENT,
        resourceName: smApi.Omni.ResourceName.ISSUE,
        attributes: attrs.map(({ attribute }) => attribute),
        values: attrs.map(({ value }) => value),
      })
    }

    const subscriptionById$ = createStore<
      Record<string, smApi.Omni.Subscription>
    >({})
      .on(fetchList.doneData, (state, list) => {
        const nextState = clone(state)

        list.forEach(subscription => {
          const key = createKeySubscription({
            attributes: subscription.attributes.map(
              ({ attribute }) => attribute,
            ),
            values: subscription.attributes.map(({ value }) => value),
            moduleId: subscription.moduleId,
            projectUrn: subscription.projectUrn,
            resourceName: subscription.resourceName,
          })

          nextState[key] = subscription
        })

        return nextState
      })
      .on(createSubscription.doneData, (state, subscription) => {
        const nextState = clone(state)

        const key = createKeySubscription({
          attributes: subscription.attributes.map(({ attribute }) => attribute),
          values: subscription.attributes.map(({ value }) => value),
          moduleId: subscription.moduleId,
          projectUrn: subscription.projectUrn,
          resourceName: subscription.resourceName,
        })

        nextState[key] = subscription

        return nextState
      })
      .on(removeSubscription.done, (state, { params: { id }, result }) => {
        const ids = Object.keys(state)
        const key = ids.find(key => state[key].publicId === id)

        if (!key) {
          return
        }

        const nextState = ids.reduce(
          (acc, id) => (key === id ? acc : { ...acc, [id]: state[id] }),
          {},
        )

        return nextState
      })

    return {
      createSubscription,
      removeSubscription,
      fetchList,
      updateSubscriptionsMap,
      subscriptionById$,
      subscriptionsPending$,
      createKeySubscription,
      createKeyIssueSubscription,
      createKeyFromClientFilters,
    }
  }
