export const buildUrlWithParams = (
  path: string,
  params: {
    [x: string]: string | number | null | Array<string | number | Date>
  },
  urlSearchParams?: string,
) =>
  `${path}${
    params
      ? `?${urlSearchParams ? `${urlSearchParams}&` : ''}${Object.keys(params)
          .filter(key => params[key])
          .map(key => {
            const value = params[key]

            return `${key}=${Array.isArray(value) ? value.join(',') : value}`
          })
          .join('&')}`
      : ''
  }`
