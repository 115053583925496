import { Button } from '@gmini/ui-kit'
import { useStore } from 'effector-react'

import {
  buildShortUserLabel,
  buildUserLabel,
  issueAnswerStatusDescMap,
  replyStatusColorMap,
} from '@gmini/helpers'

import * as api from '@gmini/ism-api-sdk'
import moment from 'moment'

import { projectUserList$ } from '../../user.store'
import { FULL_DISPLAY_DATE_FORMAT } from '../../../constants'

import {
  Corner,
  DateInfo,
  IssueOfficialReplyWrapper,
  OfficialReply,
  ReplyAuthorAndDate,
  ReplyStatus,
  ReplyText,
  StatusWrapper,
  UserBadge,
  UserLabel,
  ButtonContainer,
} from './IssueOfficialReply.styled'

type IssueOfficialReplyProps<T> = {
  officialReply: T
  onReject?: ((officialReply: T) => void) | null
  onAccept?: ((officialReply: T) => void) | null
  onDelete?: ((officialReply: T) => void) | null
  disabled?: boolean
}

export const IssueOfficialReply = <
  T extends {
    status: api.IssueAnswer.StatusEnum
    createdAt: string
    authorId: string
    description: string
  },
>({
  officialReply,
  onDelete,
  onAccept,
  onReject,
  disabled,
}: IssueOfficialReplyProps<T>) => {
  const users = useStore(projectUserList$)

  const replyAuthor =
    users.find(user => user.id === officialReply.authorId) || null

  return (
    <IssueOfficialReplyWrapper>
      <OfficialReply border={replyStatusColorMap[officialReply.status]}>
        <StatusWrapper background={replyStatusColorMap[officialReply.status]}>
          <Corner />
          <ReplyStatus>
            ОФИЦИАЛЬНЫЙ ОТВЕТ: {issueAnswerStatusDescMap[officialReply.status]}
          </ReplyStatus>
        </StatusWrapper>
        <ReplyAuthorAndDate>
          <UserBadge>{buildShortUserLabel(replyAuthor)}</UserBadge>
          <UserLabel>
            {replyAuthor?.email || buildUserLabel(replyAuthor)}
          </UserLabel>
          <DateInfo>
            {moment(officialReply.createdAt).format(FULL_DISPLAY_DATE_FORMAT)}
          </DateInfo>
        </ReplyAuthorAndDate>
        <ReplyText>{officialReply.description}</ReplyText>
      </OfficialReply>

      <ButtonContainer>
        {onDelete && (
          <Button
            color='secondary'
            onClick={() => onDelete(officialReply)}
            disabled={disabled}
          >
            Удалить официальный ответ
          </Button>
        )}
        {onReject && (
          <Button
            color='secondary'
            onClick={() => onReject(officialReply)}
            disabled={disabled}
          >
            Отклонить
          </Button>
        )}
        {onAccept && (
          <Button onClick={() => onAccept(officialReply)} disabled={disabled}>
            Принять и Завершить замечание
          </Button>
        )}
      </ButtonContainer>
    </IssueOfficialReplyWrapper>
  )
}
