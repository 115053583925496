import styled from 'styled-components'
import { Sections, Grid } from '@gmini/ui-kit'

type SelectViewIconProps = {
  isSelected: boolean
}

export const SelectViewCompactIcon = styled(Sections)<SelectViewIconProps>`
  g path {
    fill: ${({ isSelected }) => (isSelected ? '#80819A' : '#CCCDDE')};
  }
`

export const SelectViewExpandedIcon = styled(Grid)<SelectViewIconProps>`
  path {
    fill: ${({ isSelected }) => (isSelected ? '#80819A' : '#CCCDDE')};
  }
`

export const SelectViewWrapper = styled.div`
  display: flex;
`

type SelectViewItemProps = {
  isLast?: boolean
  isSelected?: boolean
}

export const SelectViewItem = styled.div<SelectViewItemProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ isSelected }) => (isSelected ? 'default' : 'pointer')};
  width: 36px;
  height: 36px;
  border: 1px solid #e5e7f1;
  border-radius: 4px;
  border-right: ${({ isLast }) => (isLast ? '1px solid #e5e7f1' : 'none')};
`
