import * as chmApi from '@gmini/chm-api-sdk'

type Item = {
  source: chmApi.AssigneeSource
  assigneeId: string
}

export const passesFilterAssigneeMultiple = (
  filterValue: Item[],
  value: Item[],
) =>
  Boolean(
    !filterValue.length ||
      (value.length &&
        filterValue.some(filter =>
          value.some(
            ({ source, assigneeId }) =>
              filter.source === source && filter.assigneeId === assigneeId,
          ),
        )),
  )
