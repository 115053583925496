import * as chmApi from '@gmini/chm-api-sdk'

export const RESET_ALL = 'resetAll'

export const AUTHOR_IDS_IL = 'authorIdsIL'
export const OWNER_IDS_IL = 'ownerIdsIL'
export const ATTRIBUTES_IL = 'attributeValueIdsIL'
export const ASSIGNEES_IL = 'assigneesIL'
export const STATUSES_IL = 'statusesIL'
export const UPDATED_DATE_RANGE_IL = 'updatedDateRangeIL'
export const UPDATED_DATE_CODE_IL = 'updatedDateCodeIL'
export const CREATED_DATE_RANGE_IL = 'createdDateRangeIL'
export const CREATED_DATE_CODE_IL = 'createdDateCodeIL'
export const DEADLINE_RANGE_IL = 'deadlineRangeIL'
export const DEADLINE_CODE_IL = 'deadlineCodeIL'
export const SEARCH_IL = 'searchIL'
export const SHOW_IL = 'showIL'
export const ISSUE_ID = 'issueId'
export const SORT_BY_OPERATOR_IL = 'sortByOperatorIL'
export const SORT_BY_FIELD_NAME_IL = 'sortByFieldNameIL'
export const ISSUE_FILTER_ID = 'issueFilterId'

export const SEARCH_TL = 'searchTL'
export const ATTRIBUTES_TL = 'attributeValueIdsTL'
export const AUTHOR_IDS_TL = 'authorIdsTL'
export const OWNER_IDS_TL = 'ownerIdsTL'
export const ASSIGNEES_TL = 'assigneesTL'
export const CREATED_DATE_RANGE_TL = 'createdDateRangeTL'
export const UPDATED_DATE_RANGE_TL = 'updatedDateRangeTL'
export const UPDATED_DATE_CODE_TL = 'updatedDateCodeTL'
export const CREATED_DATE_CODE_TL = 'createdDateCodeTL'
export const TEMPLATE_ID = 'templateId'
export const SORT_BY_OPERATOR_TL = 'sortByOperatorTL'
export const SORT_BY_FIELD_NAME_TL = 'sortByFieldNameTL'

export const instanceStatusColorMap: Record<
  chmApi.InstanceStatus.Enum,
  string
> = {
  IN_PROCESS: '#F5A623',
  FINISHED: '#0D9966',
  DRAFT: '#A7A9AC',
}

export const defaultInstanceStatusColor = '#A7A9AC'

//shared keys query params
export const SELECTED_TAB = 'selectedTab'
export const PROJECT_URN = 'projectUrn'

// instance list keys query params
export const TEMPLATE_TYPE_IDS_IL = 'templateTypeIdsIL'
export const INSTANCE_ID = 'instanceId'

// template list keys query params
export const TEMPLATE_TYPE_IDS_TL = 'templateTypeIdsTL'
export const STATUSES_TL = 'statusesTL'
export const INITIATORS_TL = 'initiatorsTL'

export const filtersTitle = {
  show: 'Показывать',
  discipline: 'Дисциплины',
  status: 'Статус',
  updatedAt: 'Дата изменения',
  createAt: 'Дата создания',
  deadline: 'Срок',
  author: 'Автор',
  assignee: 'Назначено на',
}
