import React, { SVGAttributes } from 'react'

export const UnsubscribeIcon = ({
  color = '#353B60',
  ...otherProps
}: {
  color?: string
} & SVGAttributes<SVGSVGElement>) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...otherProps}
  >
    <path
      d='M20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H14C14.5523 20 15 19.5523 15 19C15 18.4477 14.5523 18 14 18H4.5C4.22386 18 4 17.7761 4 17.5V8L11.735 12.8344C11.8971 12.9357 12.1029 12.9357 12.265 12.8344L20 8V12.5C20 13.0523 20.4477 13.5 21 13.5C21.5523 13.5 22 13.0523 22 12.5V6C22 4.9 21.1 4 20 4ZM12.265 10.8344C12.1029 10.9357 11.8971 10.9357 11.735 10.8344L4 6H20L12.265 10.8344Z'
      fill={color}
    />
    <rect
      x='17'
      y='14'
      width='10'
      height='2'
      rx='1'
      transform='rotate(45 17 14)'
      fill={color}
    />
    <rect
      width='10'
      height='2'
      rx='1'
      transform='matrix(-0.707107 0.707107 0.707107 0.707107 22.6572 14)'
      fill={color}
    />
  </svg>
)
