import { GalleryItem } from '../GalleryItem'

import { GalleryItemFile } from '../../molecules/GalleryItem/GalleryItem.types'

import { Container } from './Gallery.styled'

type GalleryProps<F> = {
  fileList: F[]
  fileListChecked: Record<number, boolean>
  onCheckedFile?: (file: F) => void
  onDeleteFile?: (file: F) => void
  disabled?: boolean
  onDownloadFile?: (file: F) => void
  onOpenViewer?: (file: F) => void
  getImgSrc?: (file: F) => string | null | undefined
  isDeletable?: (file: F) => boolean
}

export const Gallery = <F extends GalleryItemFile>({
  fileList,
  fileListChecked,
  onCheckedFile,
  onDeleteFile,
  disabled,
  onDownloadFile,
  onOpenViewer,
  getImgSrc,
  isDeletable,
}: GalleryProps<F>) => (
  <Container>
    {fileList
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(file => (
        <GalleryItem
          key={file.id}
          file={file}
          checked={fileListChecked[file.id]}
          onChecked={onCheckedFile}
          onDelete={onDeleteFile}
          onClickView={onOpenViewer}
          getImgSrc={getImgSrc}
          disabledDelete={disabled}
          onDownload={onDownloadFile}
          isDeletable={isDeletable}
        />
      ))}
  </Container>
)
