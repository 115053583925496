import { buildUserLabel } from '@gmini/helpers'
import { LongText, Select } from '@gmini/ui-kit'
import { RenderOptionParams } from '@gmini/ui-kit/lib/Select/Select.types'

import { useCallback, useMemo } from 'react'

import { FilterUserItem } from '../../atoms'

import { FilterUserWrapper, Separator } from './FilterUser.styled'

import { FilterUserInfoType, FilterUserItemType } from './FilterUser.types'

type FilterUserProps<UL, UI> = {
  userList: UL[]
  userInfo: UI | null
  allowedItems: string[]
  urlKey: string
  labelFirstEl: string
  loading?: boolean
  disabled?: boolean
  countSkeletonItems?: number
  placeholder?: string
  onChange: (value: Record<string, string>) => void
  value: string[]
}

const currentUserId = 'currentUserId'

export const FilterUser = <
  UL extends FilterUserItemType,
  UI extends FilterUserInfoType,
>({
  allowedItems,
  userList,
  userInfo,
  urlKey,
  disabled,
  loading,
  countSkeletonItems,
  placeholder,
  labelFirstEl,
  value,
  onChange,
}: FilterUserProps<UL, UI>) => {
  const onChangeHandler = useCallback(
    (item: UL | UI | null) => {
      if (!item) {
        return
      }

      onChange?.({ [urlKey]: item.id })
    },
    [onChange, urlKey],
  )

  const firstEl = useMemo(
    () =>
      ({
        id: currentUserId,
        name: labelFirstEl,
      } as UL),
    [labelFirstEl],
  )

  const allowedUserList: UL[] = useMemo(() => {
    const result = userList.filter(
      item => allowedItems.includes(item.id) && item.id !== userInfo?.id,
    )

    if (userInfo && allowedItems.some(id => id === userInfo.id)) {
      return [firstEl, ...result]
    }

    return result
  }, [allowedItems, firstEl, userInfo, userList])

  const renderOption = useCallback(
    (params: RenderOptionParams<UL>) => {
      if (userInfo && params.option.id === currentUserId) {
        return (
          <>
            <FilterUserItem
              item={userInfo}
              label={
                <LongText partSize={20} withoutRightText>
                  {labelFirstEl}
                </LongText>
              }
              onChange={() => onChangeHandler(userInfo)}
              checked={value.some(id => id === userInfo.id)}
              getId={item => item.id}
            />
            {allowedUserList.length > 1 && <Separator />}
          </>
        )
      }

      return (
        <FilterUserItem
          item={params.option}
          label={
            <LongText partSize={20} withoutRightText>
              {buildUserLabel(params.option)}
            </LongText>
          }
          onChange={() => onChangeHandler(params.option)}
          checked={value.some(id => id === params.option.id)}
          getId={item => item.id}
        />
      )
    },
    [allowedUserList.length, labelFirstEl, onChangeHandler, userInfo, value],
  )

  return (
    <FilterUserWrapper>
      <Select
        data-test-id='filterByAuthor'
        getOptionLabel={buildUserLabel}
        options={allowedUserList}
        loading={loading}
        placeholder={placeholder}
        disabled={disabled || allowedUserList.length === 0}
        value={null}
        hideInputIcon
        allowDelete={false}
        optionListStyles={{
          width: '364px',
          paddingTop: '10px',
          paddingBottom: '10px',
        }}
        renderOption={renderOption}
        popoverBoxShadow='0px 4px 10px rgba(0, 0, 0, 0.25)'
        // eslint-disable-next-line no-empty-function
        onChange={() => {}}
        style={{ paddingRight: '9.5px' }}
      />
    </FilterUserWrapper>
  )
}
