import styled, { css } from 'styled-components'

import { IconButton } from '@gmini/ui-kit'

type EditButtonProps = {
  visible?: boolean
}

export const EditButton = styled(IconButton)`
  ${(props: EditButtonProps) => props.visible && 'opacity: 1 !important;'}
`

const defaultStyles = css`
  font-size: 20px;
  line-height: 30px;
`

const editIssueTitleStyles = css`
  font-size: 18px;
  line-height: 21.09px;
`

const editIssueSmallStyles = css`
  font-size: 14px;
  line-height: 16.41px;
`

type NameProps = {
  variant: 'default' | 'editIssueTitle' | 'editIssueSmall'
}

export const Name = styled.div`
  display: flex;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  ${(props: NameProps) => {
    switch (props.variant) {
      case 'default':
        return defaultStyles
      case 'editIssueTitle':
        return editIssueTitleStyles
      case 'editIssueSmall':
        return editIssueSmallStyles
    }
  }};
  ${(props: NameProps) =>
    props.variant !== 'default'
      ? css`
          margin: 7px 8px 12px 0;
        `
      : ''}

  ${EditButton} {
    opacity: 0;
  }

  &:hover {
    ${EditButton} {
      opacity: 1;
    }
  }
`

export const NameText = styled.div`
  max-width: 340px;
  overflow: hidden;
  white-space: nowrap;
`

type ButtonsWrapperProps = {
  variant: 'default' | 'editIssueTitle' | 'editIssueSmall'
}

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${(props: ButtonsWrapperProps) =>
    props.variant === 'default' ? '4px' : '8px'};
  gap: 3px;
`

type InputProps = {
  variant: 'default' | 'editIssueTitle' | 'editIssueSmall'
  minWidth: string
}

export const Input = styled.input`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  ${(props: InputProps) => {
    switch (props.variant) {
      case 'default':
        return defaultStyles
      case 'editIssueTitle':
        return editIssueTitleStyles
      case 'editIssueSmall':
        return editIssueSmallStyles
    }
  }};
  letter-spacing: 0.15px;
  background: rgba(92, 130, 229, 0.1);
  border: 0;
  outline: none;
  border-bottom: 2px solid #4c5ecf;
  min-width: ${(props: InputProps) => props.minWidth};
  height: ${(props: InputProps) =>
    props.variant === 'default'
      ? '32px'
      : props.variant === 'editIssueTitle'
      ? '21.09px'
      : '16.41px'};
`

export const CancelButton = styled(IconButton)`
  & path {
    fill: #d9575e !important;
  }
`

export const DoneButton = styled(IconButton)`
  & path {
    fill: #4c5ecf !important;
  }

  &:disabled {
    path {
      fill: rgba(76, 94, 207, 0.2) !important;
    }
  }
`
