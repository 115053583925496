import {
  useEffect,
  useState,
  useRef,
  CSSProperties,
  useCallback,
  ChangeEvent,
} from 'react'

import { Cancel, Done, Tooltip, TextArea } from '@gmini/ui-kit'

import { ClickAwayListener } from '@mui/material'

import { FieldError } from '../../atoms'

import {
  Preview,
  TextFieldWrapper,
  ActionPanel,
  DoneButton,
  CancelButton,
} from './TextFieldMultilineWithPreview.styled'

type TextFieldMultilineWithPreviewProps = {
  value: string
  onSubmit: (value: string) => void
  viewMaxLength?: number
  styleText?: CSSProperties
  disabled?: boolean
  maxLength?: number
  emptyMessage?: string
  allowSubmitEmpty?: boolean
  styleTextArea?: CSSProperties
  editIcon?: string
  editable?: boolean
}

enum Mode {
  preview = 'preview',
  edit = 'edit',
}

export const TextFieldMultilineWithPreview = ({
  value,
  onSubmit,
  viewMaxLength,
  styleText,
  disabled,
  maxLength,
  emptyMessage,
  allowSubmitEmpty,
  styleTextArea,
  editIcon,
  editable = true,
}: TextFieldMultilineWithPreviewProps) => {
  const [mode, setMode] = useState<Mode>(Mode.preview)
  const [editorValue, setEditorValue] = useState(value)

  const textAreaRef = useRef<HTMLTextAreaElement | null>(null)
  const trimmedValue = editorValue.trim()

  const maxLengthExceeded = !!maxLength && trimmedValue.length > maxLength

  useEffect(() => {
    setEditorValue(value)
  }, [value, mode])

  useEffect(() => {
    if (textAreaRef.current && mode === Mode.edit) {
      textAreaRef.current.focus()
    }
  }, [editorValue, mode])

  const onChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setEditorValue(event.target.value)
  }, [])

  const onKeyDown = useCallback(
    event => {
      if (maxLengthExceeded || (!editorValue && !allowSubmitEmpty)) {
        return
      }

      if (event.key === 'Escape') {
        event.preventDefault()
        setMode(Mode.preview)
        return
      }

      if ((event.key === 'Enter' && !event.shiftKey) || event.key === 'Tab') {
        event.preventDefault()
        onSubmit(event.target.value)
        setMode(Mode.preview)
      }
    },
    [allowSubmitEmpty, editorValue, maxLengthExceeded, onSubmit],
  )
  const previewMessage =
    viewMaxLength && viewMaxLength < value.length
      ? `${value.slice(0, viewMaxLength)} ...`
      : value

  switch (mode) {
    case Mode.preview: {
      return (
        <Preview
          disabled={disabled}
          style={styleText}
          onClick={() => {
            if (editable) {
              setMode(Mode.edit)
            }
          }}
          editIcon={editIcon}
          showEditIcon={editable}
          isPlaceholder={previewMessage.length === 0}
        >
          {previewMessage.length !== 0 ? previewMessage : emptyMessage}
        </Preview>
      )
    }
    case Mode.edit: {
      let doneButtonTooltipValue = ''

      if (trimmedValue.length === 0 && !allowSubmitEmpty) {
        doneButtonTooltipValue = 'Введите значение'
      }
      if (maxLengthExceeded) {
        doneButtonTooltipValue = `Превышена максимально допустимая длина в ${maxLength}`
      }

      return (
        <ClickAwayListener onClickAway={() => setMode(Mode.preview)}>
          <div>
            <TextFieldWrapper>
              <TextArea
                textAreaRef={textAreaRef}
                onKeyDown={onKeyDown}
                value={editorValue}
                onChange={onChange}
                error={maxLengthExceeded}
                style={styleTextArea}
              />
              <ActionPanel>
                <CancelButton onClick={() => setMode(Mode.preview)}>
                  <Cancel width='24px' height='24px' color='#D9575E' />
                </CancelButton>
                <Tooltip
                  title={doneButtonTooltipValue}
                  noMaxWidth
                  styleContent={{ background: '#fff' }}
                >
                  <DoneButton
                    onClick={() => {
                      onSubmit(editorValue)
                      setMode(Mode.preview)
                    }}
                    disabled={
                      maxLengthExceeded ||
                      (trimmedValue.length === 0 && !allowSubmitEmpty)
                    }
                  >
                    <Done width='20px' height='20px' color='#4C5ECF' />
                  </DoneButton>
                </Tooltip>
              </ActionPanel>
            </TextFieldWrapper>

            <FieldError hidden={!maxLengthExceeded}>
              {maxLengthExceeded &&
                `Превышена максимально допустимая длина в ${maxLength} символов`}
            </FieldError>
          </div>
        </ClickAwayListener>
      )
    }
  }
}
