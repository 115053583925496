import { createStore } from 'effector'

import * as smApi from '@gmini/sm-api-sdk'
import { useStore, useStoreMap } from 'effector-react'

import { Role } from './roleService.types'

export function createRolesService() {
  const fetchRoleList = smApi.Auth.Role.fetchList.createContext()
  const fetchRoleListPending$ = fetchRoleList.pending$

  const roleList$ = createStore<Role[]>([]).on(
    fetchRoleList.doneData,
    (state, result) =>
      result.map(role => ({ ...role, id: role.id.toString() })),
  )

  function useRoleList() {
    return useStore(roleList$)
  }

  function useFilteredRoleList(filterTitleValue: string) {
    return useStoreMap({
      store: roleList$,
      keys: [filterTitleValue],
      fn: (roles, [filterTitle]) =>
        filterTitle
          ? roles.filter(role =>
              role.title.toLowerCase().includes(filterTitle.toLowerCase()),
            )
          : roles,
    })
  }

  return {
    fetchRoleList,
    fetchRoleListPending$,
    roleList$,
    useRoleList,
    useFilteredRoleList,
  }
}
