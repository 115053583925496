import { createProjectListService } from '@gmini/helpers'

import * as smApi from '@gmini/sm-api-sdk'

import { filterService as issueFilterService } from '../../issueFilter.store'
import { filterService as templateFilterService } from '../../issueTemplateFilter.store'

export const { fetchProjectList, fetchProjectListPending$, projectList$ } =
  createProjectListService({
    fetchProjectListApi: smApi.Project.fetchList,
  })

export const filterService = [issueFilterService, templateFilterService]
