import styled from 'styled-components'

type UserBadgeProps = {
  bg?: string
}

export const UserBadge = styled.div`
  width: 32px;
  min-width: 32px;
  height: 32px;
  background: ${(props: UserBadgeProps) => (props.bg ? props.bg : '#a2a3b7')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: #ffffff;
  border-radius: 50%;
  text-transform: uppercase;
`
