import styled from 'styled-components'

import { SuccessSave } from '@gmini/ui-kit'

export const Label = styled.label``

export const InputWrapper = styled.div`
  margin-bottom: 20px;
`
export const LabelText = styled.div`
  margin-bottom: 6px;
  &::after {
    content: ' *';
    color: red;
  }
`
export const Success = styled(SuccessSave)`
  margin-top: 3px;
  & path:nth-child(2) {
    fill: #0bbb7b;
    opacity: 0.5;
  }
`
