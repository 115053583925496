import { DependencyList, RefObject, useEffect, useRef, useState } from 'react'

type UseOnScreen = {
  ref: RefObject<HTMLElement>
  triggers?: DependencyList
  observerOptions?: IntersectionObserverInit
}

export const useOnScreen = ({
  ref,
  triggers = [],
  observerOptions,
}: UseOnScreen) => {
  const [isOnScreen, setIsOnScreen] = useState(false)
  const observerRef = useRef<IntersectionObserver | null>(null)

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      ([entry]) => setIsOnScreen(entry.isIntersecting),
      observerOptions,
    )
  }, [observerOptions])

  useEffect(() => {
    if (observerRef?.current && ref?.current) {
      observerRef.current.observe(ref.current)

      return () => {
        observerRef?.current?.disconnect()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, ...triggers])

  return isOnScreen
}
