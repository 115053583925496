import styled from 'styled-components'

import { Magnifier as MagnifierInit } from '@gmini/ui-kit'

export const Magnifier = styled(MagnifierInit)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 12px;
  margin: auto 0;
`

type SearchInputProps = {
  width?: string
}

export const SearchInput = styled.input`
  background: #f4f4f8;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  height: 40px;
  color: #353b60;
  padding: 9.5px 40px;
  outline: none;
  width: ${(props: SearchInputProps) => (props.width ? props.width : '300px')};
  border: 1px solid transparent;
  border-radius: 4px;
  transition: ease-out 0.2s;

  &:hover {
    border: 1px solid #c5c6d1;
  }

  &:focus {
    border: 1px solid #4c5ecf;
  }

  &:disabled {
    border: 1px solid transparent;
    color: rgba(53, 59, 96, 0.2);

    &::placeholder {
      color: rgba(53, 59, 96, 0.2);
    }

    ${Magnifier} {
      path {
        fill: rgba(53, 59, 96, 0.2);
      }
    }
  }

  &::placeholder {
    color: rgba(53, 59, 96, 0.4);
  }
`

export const SearchContainer = styled.div`
  position: relative;
`
