import styled, { css } from 'styled-components'

import { IconButton } from '@gmini/ui-kit'

export const Container = styled.div`
  display: flex;
  gap: 0 8px;
  width: 100%;
`

export const AuthorLabel = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 61, 0.7);
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 0 8px;
`

export const Body = styled.div`
  display: flex;
`

export const CreatedAt = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 61, 0.6);
`

type OpenCtxMenuButtonProps = {
  hidden?: boolean
}

export const OpenCtxMenuButton = styled(IconButton)`
  margin-left: auto;

  ${(props: OpenCtxMenuButtonProps) =>
    props.hidden &&
    css`
      && {
        visibility: hidden;
      }
    `}
`

export const Text = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.15px;
  color: #00003d;
  word-break: break-word;

  & blockquote {
    margin: 0 12px;
    border-left: 4px solid #ccc;
    padding-left: 8px !important;
  }

  & p {
    margin: 0;
  }

  & ul {
    margin: 6px 24px 12px 24px;
  }

  & ol {
    margin: 6px 24px 12px 24px;
    padding: 0;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0 12px;
  flex: 1;
`
