import styled from 'styled-components'

import { IconButton } from '@gmini/ui-kit'

type OpenCtxButtonProps = {
  ml?: string
  mr?: string
}

export const OpenCtxButton = styled(IconButton)`
  padding: 0;
  margin-left: ${(props: OpenCtxButtonProps) => (props.ml ? props.ml : 'auto')};
  margin-right: ${(props: OpenCtxButtonProps) => (props.mr ? props.mr : '6px')};

  & svg {
    width: 20px;
    height: 20px;
  }
`
