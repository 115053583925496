import { createStore, merge } from 'effector'

import { clone } from 'ramda'

import { omniSubscriptionService } from '@gmini/helpers'

import { createTableService } from '@gmini/ui-kit'

import {
  changeStatus,
  fetchListLinkedIssueToFile,
  fetchMostRecentIssue,
  updateIssue,
} from '../../issue.store'

import { startUploadFile } from '../../file.store'

import { CurrentIssue } from './types'

export const attachmentTableService = createTableService()

export const currentIssue$ = createStore<CurrentIssue | null>(null)
  .on(
    merge([
      updateIssue.doneData,
      changeStatus.doneData,
      startUploadFile.doneData,
      fetchMostRecentIssue.doneData,
    ]),
    (state, issue) => ({ ...state, ...issue }),
  )
  .on(fetchListLinkedIssueToFile.done, (state, { params, result }) => {
    const next = clone(state)

    params.issues.forEach(issueId => {
      const linkedEntity = result.issues.find(
        ({ externalId }) => externalId === issueId,
      )

      if (linkedEntity && issueId === state?.id && next) {
        next.linkedEntity = {
          moduleName: 'G-station',
          entityName: linkedEntity.file.name,
          link: linkedEntity.file.webview,
        }
      }
    })
    return next
  })
  .on(omniSubscriptionService.subscriptionById$.updates, (state, result) => {
    if (!state) {
      return
    }
    const key = omniSubscriptionService.createKeyIssueSubscription({
      projectUrn: state.projectUrn,
      issueId: state.id.toString(),
    })
    const subscriptionPublicId = result[key]?.publicId
    return subscriptionPublicId
      ? { ...state, subscriptionPublicId }
      : { ...state, subscriptionPublicId: '' }
  })
