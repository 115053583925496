import { ScrollBar, Accordion } from '@gmini/ui-kit'

import {
  FiltersContent,
  FiltersHeader,
  FiltersTitle,
  FiltersWrapper,
} from './FilterPanel.styled'

import { FilterPanelItem } from './FilterPanel.types'

type FilterPanelProps = {
  items: FilterPanelItem[]
}

export const FilterPanel = ({ items }: FilterPanelProps) => (
  <FiltersWrapper>
    <FiltersHeader>
      <FiltersTitle>Фильтры</FiltersTitle>
    </FiltersHeader>
    <ScrollBar contentStyled={FiltersContent}>
      {items.map((item, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <Accordion key={idx} title={item.title}>
          {item.component}
        </Accordion>
      ))}
    </ScrollBar>
  </FiltersWrapper>
)
