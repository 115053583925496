import { Popover } from '@mui/material'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 617px;
  width: 360px;
`
export const Popup = styled(Popover)``

export const Header = styled.div`
  height: 67px;
  padding: 24px;
  border-bottom: 1px solid #e5e7f1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 18px;
  line-height: 21px;
`

export const Body = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
