import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
`

export const PrimaryText = styled.p`
  font-size: 14px;
  line-height: 16px;
  margin: 16px 0 6px;
`

export const SecondaryText = styled.p`
  font-size: 13px;
  line-height: 15px;
  color: rgba(0, 0, 61, 0.5);
  text-align: center;
  margin: 0;
`
