import styled, { css } from 'styled-components'

export const FilterUserWrapper = styled.div`
  padding-right: 10px;
`

export const OptionListStyled = css`
  && {
    width: 364px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`

export const FirstElWrapper = styled.div`
  margin-top: 10px;
`

export const GroupTitleStyled = css`
  padding-left: 23px;
`

export const Separator = styled.div`
  background: #e5e7f1;
  height: 1px;
  width: calc(100% - 48px);
  margin: 10px auto;
`
