import { useMemo } from 'react'

type UseAppliedFilterAttributesProps<T> = {
  urlKey: string
  value: T[]
}

export const useAppliedFilterAttributes = <
  T extends { attribute: { name: string; id: number }; name: string },
>({
  urlKey,
  value,
}: UseAppliedFilterAttributesProps<T>) =>
  useMemo(
    () =>
      Object.values(
        value.reduce(
          (
            acc: Record<
              number,
              { name: string; value: string[]; urlKey: string }
            >,
            item,
          ) => {
            if (!item) {
              return acc
            }

            const attributeId = item.attribute.id
            if (!acc[attributeId]) {
              acc[attributeId] = {
                name: item.attribute.name,
                value: [],
                urlKey,
              }
            }

            acc[attributeId].value.push(item.name)

            return acc
          },
          {},
        ),
      ),
    [urlKey, value],
  )
