import { createEffect, createStore, Effect, Store } from 'effector'

import * as smApi from '@gmini/sm-api-sdk'

import { useStoreMap } from 'effector-react'
import { ApiCallServiceContext, PendingMap } from '@gmini/api-call-service'
import { clone } from 'ramda'

export type AttributesService = {
  fetchAttributeValues: Effect<
    smApi.Attribute.FetchAttributeValuesParams,
    smApi.Attribute.FetchAttributeValuesRes
  >
  fetchAttributeValuesPending$: Store<boolean>
  fetchAttributePopulated: ApiCallServiceContext<
    smApi.Attribute.FetchAttributePopulatedParams,
    smApi.Attribute.FetchPopulatedRes
  >
  fetchAttributePopulatedWithArchived: Effect<
    Omit<smApi.Attribute.FetchAttributePopulatedParams, 'archived'>,
    smApi.Attribute.FetchPopulatedRes
  >
  fetchAttributePendingMap$: Store<PendingMap>
  attributeById$: Store<Record<number, smApi.Attribute.Data>>
  useAttributeDataById: (attrId: number) => smApi.Attribute.Data
  useAttributeValuesWithGroups: (attrId: number) => AttributeValueWithGroups[]
  fetchAttributeList: Effect<
    smApi.Attribute.FetchAttributeListParams,
    smApi.Attribute.Data[],
    Error
  >
  fetchAttributeListPending$: Store<boolean>
  attributeListByProjectUrn$: Store<Record<string, smApi.Attribute.Data[]>>
  useAttributeList: (params: {
    projectUrn?: string | null
    archived?: boolean
    excludeIds?: number[]
  }) => smApi.Attribute.Data[]
  attributeValueById$: Store<
    Record<
      number,
      smApi.Attribute.FetchAttributeValuesRes['attributeValues'][number]
    >
  >
  attributeValuesWithGroupsByAttributeId$: Store<AttributeValuesWithGroupsByAttributeId>
}

export type AttributeValueWithGroups = { groupLabel: string } & (
  | smApi.Attribute.Value
  | smApi.Attribute.Group
)

export type AttributeValuesWithGroupsByAttributeId = Record<
  number,
  AttributeValueWithGroups[]
>

export function createAttributesService(): AttributesService {
  const fetchAttributeValues =
    smApi.Attribute.fetchAttributeValues.createContext()
  const fetchAttributeValuesSkipEmptyValueIds = createEffect<
    smApi.Attribute.FetchAttributeValuesParams,
    smApi.Attribute.FetchAttributeValuesRes
  >().use(({ valueIds }) => {
    if (!valueIds.length) {
      return Promise.resolve({ attributeValues: [] })
    }
    return fetchAttributeValues({ valueIds })
  })
  const fetchAttributeValuesPending$ = fetchAttributeValues.pending$

  const fetchAttributePopulated =
    smApi.Attribute.fetchAttributePopulated.createContext()
  const fetchAttributePendingMap$ = fetchAttributePopulated.pendingMap$
  const fetchAttributePopulatedWithArchived = createEffect<
    Omit<smApi.Attribute.FetchAttributePopulatedParams, 'archived'>,
    smApi.Attribute.FetchPopulatedRes
  >().use(({ attrId, projectUrn }) =>
    fetchAttributePopulated({ attrId, projectUrn, archived: true }),
  )

  const attributeById$ = createStore<Record<number, smApi.Attribute>>({})
    .on(fetchAttributePopulated.doneData, (state, data) => ({
      ...state,
      [data.id]: data,
    }))
    .on(fetchAttributeValues.doneData, (state, data) => {
      const next = clone(state)

      data.attributeValues.forEach(item => {
        next[item.attribute.id] = item.attribute
      })

      return next
    })

  const attributeValueById$ = createStore<
    Record<
      number,
      smApi.Attribute.FetchAttributeValuesRes['attributeValues'][number]
    >
  >({})
    .on(fetchAttributePopulated.doneData, (state, result) => {
      const next = clone(state)

      const attribute = {
        id: result.id,
        name: result.name,
        description: result.description,
        deleted: result.deleted,
      }

      result.children?.forEach(item => {
        if (item.type === 'AttributeValue') {
          next[item.id] = {
            ...item,
            attribute,
            group: null,
          }
          return
        }

        if (item.type === 'AttributeGroup') {
          item.children?.forEach(child => {
            next[child.id] = { ...child, attribute, group: { ...item } }
          })
        }
      })

      return next
    })
    .on(fetchAttributeValues.doneData, (state, result) => {
      const next = clone(state)

      result.attributeValues.forEach(item => {
        next[item.id] = item
      })

      return next
    })

  const attributeValuesWithGroupsByAttributeId$: Store<AttributeValuesWithGroupsByAttributeId> =
    createStore<AttributeValuesWithGroupsByAttributeId>({}).on(
      fetchAttributePopulated.doneData,
      (state, result) => {
        const next = clone(state)

        next[result.id] =
          result.children?.reduce((acc: AttributeValueWithGroups[], item) => {
            if (item.type === 'AttributeGroup') {
              item.children?.forEach(child =>
                acc.push({ ...child, groupLabel: item.name }),
              )
            } else if (item.type === 'AttributeValue') {
              acc.push({ ...item, groupLabel: '' })
            }

            return acc
          }, []) || []

        return next
      },
    )

  function useAttributeDataById(attributeId: number) {
    return useStoreMap({
      store: attributeById$,
      keys: [attributeId],
      fn: (attributes, [attributeId]) => attributes[attributeId] || null,
    })
  }

  function useAttributeValuesWithGroups(attributeId: number) {
    return useStoreMap({
      store: attributeValuesWithGroupsByAttributeId$,
      keys: [attributeId],
      fn: (attributes, [attributeId]) => attributes[attributeId] || [],
    })
  }

  const fetchAttributeList = smApi.Attribute.fetchAttributeList.createContext()
  const fetchAttributeListPending$ = fetchAttributeList.pending$

  const fetchAttributeListWithArchived = createEffect<
    smApi.Attribute.FetchAttributeListParams,
    smApi.Attribute.Data[]
  >().use(params => fetchAttributeList({ archived: true, ...params }))

  const attributeListByProjectUrn$ = createStore<
    Record<string, smApi.Attribute.Data[]>
  >({}).on(fetchAttributeListWithArchived.done, (state, { params, result }) => {
    const next = clone(state)

    next[params.projectUrn] = result

    return next
  })

  function useAttributeList(params: {
    projectUrn?: string | null
    archived?: boolean
  }) {
    const { projectUrn, archived } = params || {}

    return useStoreMap({
      store: attributeListByProjectUrn$,
      keys: [projectUrn, archived],
      fn: (attributesByProjectUrn, [projectUrn, archived]) => {
        if (!projectUrn || !attributesByProjectUrn[projectUrn]) {
          return []
        }

        return !archived
          ? attributesByProjectUrn[projectUrn].filter(
              attribute => !attribute.deleted,
            )
          : attributesByProjectUrn[projectUrn]
      },
    })
  }

  return {
    fetchAttributePopulated,
    fetchAttributePendingMap$,
    attributeById$,
    useAttributeDataById,
    useAttributeValuesWithGroups,
    fetchAttributePopulatedWithArchived,
    fetchAttributeList: fetchAttributeListWithArchived,
    fetchAttributeListPending$,
    attributeListByProjectUrn$,
    useAttributeList,
    attributeValueById$,
    fetchAttributeValues: fetchAttributeValuesSkipEmptyValueIds,
    fetchAttributeValuesPending$,
    attributeValuesWithGroupsByAttributeId$,
  }
}
