import { MouseEvent, memo } from 'react'

import * as smApi from '@gmini/sm-api-sdk'

import { useHover } from '@gmini/utils'

import { Bell, Tooltip, Trash } from '@gmini/ui-kit'
import { AttributesService } from '@gmini/helpers'

import { SeoEventManager } from '@gmini/common'

import { RenderAttributes, BellFilledIcon } from '../../../atoms'

import {
  FilterActions,
  Header,
  Root,
  Status,
  StatusColor,
  StyledIconButton,
  Text,
  Title,
  Wrapper,
} from './SavedFilter.styled'

type SubscriptionProps = {
  id?: number
  name?: string
  statuses: { name: string; bgColor: string }[]
  updatedDateCode: string
  createdDateCode: string
  deadlineCode: string
  authorIds: string[]
  assignees: string[]
  attributeValues: smApi.Attribute.FetchAttributeValuesRes['attributeValues']
  onRemove?: ({
    id,
    publicId,
    name,
  }: {
    id?: number
    publicId?: string | null
    name: string | undefined
  }) => void
  onSubscribe?: ({
    id,
    publicId,
  }: {
    id?: number
    publicId?: string | null
  }) => void
  disabledActions?: boolean
  publicId?: string | null
  applyFilter?: (id?: number) => void
  attributeValueById$: AttributesService['attributeValueById$']
  seoEventManager?: SeoEventManager
}

export const SavedFilter = memo(
  ({
    id,
    name,
    statuses,
    updatedDateCode,
    createdDateCode,
    deadlineCode,
    authorIds,
    assignees,
    onRemove,
    onSubscribe,
    disabledActions,
    publicId,
    applyFilter,
    attributeValues,
    attributeValueById$,
    seoEventManager,
  }: SubscriptionProps) => {
    const { hover, onMouseLeave, onMouseOver } = useHover()

    const onSubscribeHandler = (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      onSubscribe?.({ id, publicId })
    }

    const onRemoveHandler = (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      onRemove?.({ id, publicId, name })

      seoEventManager?.push({
        event: 'Gstation_Issues_SavedFilters_Delete',
        payload: {
          filterId: id,
        },
      })
    }

    return (
      <Root
        onMouseLeave={onMouseLeave}
        onMouseOver={onMouseOver}
        onClick={() => {
          applyFilter?.(id)

          seoEventManager?.push({
            event: 'Gstation_Issues_SavedFilters_Activate',
            payload: {
              filterId: id,
            },
          })
        }}
      >
        {name && (
          <Header>
            <Title>{name}</Title>

            <FilterActions>
              {hover && (
                <Tooltip
                  placement='top'
                  title='Удалить'
                  styleContent={{ marginLeft: 'auto' }}
                >
                  <StyledIconButton
                    onClick={onRemoveHandler}
                    disabled={disabledActions}
                  >
                    <Trash />
                  </StyledIconButton>
                </Tooltip>
              )}

              {(hover || publicId) && (
                <Tooltip
                  placement='top'
                  title={publicId ? 'Уже отслеживаете' : 'Отслеживать'}
                  styleContent={{ marginLeft: 'auto' }}
                >
                  <StyledIconButton
                    onClick={onSubscribeHandler}
                    disabled={disabledActions}
                  >
                    {publicId ? <BellFilledIcon /> : <Bell />}
                  </StyledIconButton>
                </Tooltip>
              )}
            </FilterActions>
          </Header>
        )}
        <Wrapper>
          {statuses.map(el => (
            <Status key={el.name}>
              <StatusColor color={el.bgColor} />
              <Text>{el.name}</Text>
            </Status>
          ))}
        </Wrapper>

        {updatedDateCode && <Text>{`Дата изменения: ${updatedDateCode}`}</Text>}
        {createdDateCode && <Text>{`Дата создания: ${createdDateCode}`}</Text>}
        {deadlineCode && <Text>{`Срок: ${deadlineCode}`}</Text>}
        {!!authorIds.length && (
          <Text>{`Автор: ${authorIds.map(el => el).join(', ')}`}</Text>
        )}
        {!!assignees.length && (
          <Text>{`Назначено на: ${assignees
            .map(name => name)
            .join(', ')}`}</Text>
        )}
        {attributeValues.length > 0 && (
          <RenderAttributes
            attributeValueById$={attributeValueById$}
            attributeValueIds={attributeValues.map(({ id }) => id)}
          />
        )}
      </Root>
    )
  },
)

SavedFilter.displayName = 'Subscription'
