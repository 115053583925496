import styled, { css } from 'styled-components'

import {
  ArrowRight as ArrowRightInit,
  File as FileInit,
  FileWithCross as FileWithCrossInit,
} from '@gmini/ui-kit'

export const Wrapper = styled.div`
  width: 100%;
`

export const HistoryItem = styled.div`
  display: flex;
  gap: 0 14px;
  padding: 24px 0;
`

export const Separator = styled.div`
  background: #e5e7f1;
  height: 1px;
`

export const ChangeBody = styled.div`
  flex: 1;
  padding-top: 8px;
  overflow: hidden;
`

export const ChangeInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const UserLabel = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.15px;
  color: #00003d;
`

export const Date = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 61, 0.6);
`

export const VerticalLine = styled.div`
  position: absolute;
  top: -5px;
  left: 3px;
  width: 1px;
  height: 10px;
  background: #a2a3b7;
`

export const Operation = styled.div`
  position: relative;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: #00003d;
  padding-top: 8px;
  display: flex;
  align-items: center;
  gap: 8px;

  ${VerticalLine} {
    top: -1px;
  }
`

export const Item = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0 8px;
  padding: 4.5px 0;
  font-family: Roboto, sans-serif;
`

export const ItemAttribute = styled(Item)`
  align-items: start;
`

type ItemLabelProps = {
  color?: string
}

export const ItemLabel = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 61, 0.6);
  white-space: nowrap;

  ${(props: ItemLabelProps) =>
    props.color &&
    css`
      color: ${props.color};
    `}
`

type ItemValueProps = {
  size?: 'small' | 'medium'
  m?: string
}

export const ItemValue = styled.div`
  display: flex;
  align-items: center;
  gap: 0 2px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: #00003d;
  overflow: hidden;

  ${(props: ItemValueProps) =>
    props.size === 'medium' &&
    css`
      font-size: 14px;
    `}

  ${(props: ItemValueProps) =>
    props.m &&
    css`
      margin: ${props.m};
    `}
`

export const ItemValueInner = styled.div`
  overflow: hidden;
`

export const ItemValueThrough = styled(ItemValueInner)`
  text-decoration: line-through;

  & span {
    text-decoration: line-through;
  }
`

export const ItemAttributeValue = styled(ItemValue)`
  padding-top: 2px;
  flex-wrap: wrap;
  gap: 4px;
`

export const FileWrapper = styled.div`
  margin-top: 2.5px;
  margin-left: 14px;
  display: flex;
  flex-direction: column;
  gap: 9px 0;
`

export const FileItemLabel = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 61, 0.6);
  letter-spacing: 0.15px;
`

export const FileItemValue = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: #00003d;
  display: flex;
  gap: 12px;
`

export const NoHistoryMessage = styled.div`
  padding: 24px;
  font-weight: 500;
`

type Circle = {
  color?: string
}

export const Circle = styled.div`
  width: 7px;
  min-width: 7px;
  height: 7px;
  background: #a2a3b7;
  border-radius: 50%;

  ${(props: Circle) =>
    props.color &&
    css`
      background: ${props.color};
    `}
`

type FileItemProps = {
  gap: string
}

export const FileItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props: FileItemProps) => props.gap};
  color: #00003d;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
`

export const ItemsWrapper = styled.div`
  padding: 5px 0 0 15px;
`

type StatusProps = {
  background: string
  color: string
}

export const Status = styled.div`
  padding: 1px 8px;
  font-weight: 700;
  font-size: 9px;
  line-height: 16px;
  color: ${(props: StatusProps) => props.color};
  background: ${(props: StatusProps) => props.background};
  border-radius: 20px;
  text-transform: uppercase;
`

export const ArrowRight = styled(ArrowRightInit)`
  min-width: 12px;
`

type FileProps = {
  size: 'small' | 'medium'
}

export const File = styled(FileInit)`
  ${(props: FileProps) => {
    switch (props.size) {
      case 'medium': {
        return css`
          min-width: 24px;
          width: 24px;
          height: 24px;
        `
      }
      case 'small': {
        return css`
          min-width: 20px;
          width: 20px;
          height: 20px;
        `
      }
    }
  }}
`

export const FileWithCross = styled(FileWithCrossInit)`
  ${(props: FileProps) => {
    switch (props.size) {
      case 'medium': {
        return css`
          min-width: 24px;
          width: 24px;
          height: 24px;
        `
      }
      case 'small': {
        return css`
          min-width: 20px;
          width: 20px;
          height: 20px;
        `
      }
    }
  }}
`
