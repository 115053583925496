import { IssueXlsxFields } from './IssueList.types'

export const defaultOffset = 0
export const limit = 20

export const orderedFieldsXlsx: IssueXlsxFields[] = [
  'id',
  'status',
  'name',
  'description',
  'linkedFileName',
  'linkedFileVersion',
  'answer',
  'createdAt',
  'updatedAt',
  'deadline',
  'assignees',
  'assigneesCompany',
  'owner',
  'ownerCompany',
  'author',
  'authorCompany',
  'lastUpdateAuthor',
  'lastUpdateCompany',
  'attributes',
]
