export const createDateRangeByDays = (
  currentDate: Date,
  days: number,
  sign?: 'plus' | 'minus',
): [Min: string, Max: string] => {
  const date = new Date(currentDate)
  let resultDate = null

  if (sign === 'plus') {
    resultDate = date.getDate() + days
  } else {
    resultDate = date.getDate() - days
  }

  date.setDate(resultDate)

  if (sign === 'plus') {
    return [currentDate.toISOString(), date.toISOString()]
  }

  return [date.toISOString(), currentDate.toISOString()]
}
