export const filtersTitle = {
  id: 'ID',
  discipline: 'Дисциплины',
  status: 'Статус',
  updatedAt: 'Дата изменения',
  createdAt: 'Дата создания',
  deadline: 'Срок',
  author: 'Автор',
  assignee: 'Назначено на',
}
