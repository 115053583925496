import { Table, TableColumn, Tooltip } from '@gmini/ui-kit'
import moment from 'moment'

import * as api from '@gmini/ism-api-sdk'

import { useSortTable } from '@gmini/common'

import { useStore } from 'effector-react'

import { useMemo } from 'react'

import sortBy from 'lodash/sortBy'

import { isImage } from '@gmini/helpers'

import { DEFAULT_DISPLAY_DATE_FORMAT } from '../../../constants'

import { allUserList$ } from '../../user.store'
import { companyList$ } from '../../company.store'

import { formatFileSize } from '../../../helpers/formatFileSize'

import {
  AttachmentTableProps,
  AttachmentTableRow,
} from './AttachmentTable.types'
import { ClickableImageName } from './AttachmentTable.styled'
import {
  ATTACHMENT_TABLE_COMPACT_BODY_HEIGHT,
  COLUMN_CHAR_WIDTH,
  COLUMN_HORIZONTAL_PADDING,
} from './constants'

const getColumnTextLength = (width: number) =>
  (width - COLUMN_HORIZONTAL_PADDING) / COLUMN_CHAR_WIDTH

const columns: TableColumn<AttachmentTableRow>[] = [
  {
    field: 'name',
    name: 'Название файла',
    visible: true,
    cellStyle: { width: '230px', overflow: 'hidden' },
    style: {
      width: '240px',
    },
    // eslint-disable-next-line react/display-name
    renderCell: ({ row }) => {
      const textLength = getColumnTextLength(230)

      if (!isImage(row)) {
        return row.name.length > textLength ? (
          <Tooltip placement='bottom' title={row.name}>
            <>{row.name.substring(0, textLength)}...</>
          </Tooltip>
        ) : (
          row.name
        )
      }

      return (
        <ClickableImageName onClick={() => row.openViewer()}>
          {row.name.length > textLength ? (
            <Tooltip placement='bottom' title={row.name}>
              <>{row.name.substring(0, textLength)}...</>
            </Tooltip>
          ) : (
            row.name
          )}
        </ClickableImageName>
      )
    },
  },
  {
    field: 'uploadAt',
    name: 'Дата добавления',
    visible: true,
    cellStyle: { width: '114px', overflow: 'hidden' },
    style: {
      width: '124px',
    },
    // eslint-disable-next-line react/display-name
    renderCell: ({ row }) => (
      <div style={{ width: '124px', maxWidth: '114px' }}>
        {moment(row.uploadAt).format(DEFAULT_DISPLAY_DATE_FORMAT)}
      </div>
    ),
  },
  {
    field: 'author',
    name: 'Автор',
    visible: true,
    cellStyle: { width: '154px', overflow: 'hidden' },
    style: {
      width: '164px',
    },
    // eslint-disable-next-line react/display-name
    renderCell: ({ row }) => {
      const textLength = getColumnTextLength(164)
      return (
        <div style={{ width: '164px', maxWidth: '154px' }}>
          {row.author?.name?.length && row.author?.name?.length > textLength ? (
            <Tooltip placement='bottom' title={row.author?.name}>
              <>{row.author?.name?.substring(0, textLength)}...</>
            </Tooltip>
          ) : (
            row.author?.name
          )}
        </div>
      )
    },
  },
  {
    field: 'company',
    name: 'Компания',
    visible: true,
    cellStyle: { width: '154px', overflow: 'hidden' },
    style: {
      width: '164px',
    },
    // eslint-disable-next-line react/display-name
    renderCell: ({ row }) => {
      const textLength = getColumnTextLength(164)
      return (
        <div style={{ width: '164px', maxWidth: '154px' }}>
          {row.company?.companyName?.length &&
          row.company?.companyName?.length > textLength ? (
            <Tooltip placement='bottom' title={row.company?.companyName}>
              <>{row.company?.companyName?.substring(0, textLength)}...</>
            </Tooltip>
          ) : (
            row.company?.companyName || ''
          )}
        </div>
      )
    },
  },
  {
    field: 'email',
    name: 'Email',
    visible: true,
    cellStyle: { width: '154px', overflow: 'hidden' },
    style: {
      width: '164px',
    },
    // eslint-disable-next-line react/display-name
    renderCell: ({ row }) => {
      const textLength = getColumnTextLength(164)
      return (
        <div style={{ width: '164px', maxWidth: '154px' }}>
          {row.email?.length && row.email?.length > textLength ? (
            <Tooltip placement='bottom' title={row.email}>
              <>{row.email?.substring(0, textLength)}...</>
            </Tooltip>
          ) : (
            row.email
          )}
        </div>
      )
    },
  },
  {
    field: 'size',
    name: 'Размер',
    visible: true,
    cellStyle: { width: '114px', overflow: 'hidden' },
    style: {
      width: '124px',
    },
    // eslint-disable-next-line react/display-name
    renderCell: ({ row }) => {
      const textLength = getColumnTextLength(124)
      const sizeString = row.size ? formatFileSize(row.size) : ''

      return (
        <div style={{ width: '124px', maxWidth: '114px' }}>
          {sizeString?.length && sizeString?.length > textLength ? (
            <Tooltip placement='bottom' title={sizeString}>
              <>{sizeString?.substring(0, textLength)}...</>
            </Tooltip>
          ) : (
            sizeString
          )}
        </div>
      )
    },
  },
]

type ColumnSettings = {
  field: string
  visible: boolean
}

// TODO: Write helper for get column order from storage
export const getAttachmentListColumnOrderFromStorage =
  (): TableColumn<AttachmentTableRow>[] => {
    const data = localStorage.getItem('attachmentListColumnOrder')
    if (typeof data === 'string') {
      try {
        const parsedData = JSON.parse(data) as ColumnSettings[]

        return columns
          .slice()
          .sort((a, b) => {
            const aIdx = parsedData.findIndex(s => s.field === a.field)
            const bIdx = parsedData.findIndex(s => s.field === b.field)

            // В случае если в localStorage не было настройки колонки (например: в коде добавили новую)
            if (aIdx < 0 || bIdx < 0) {
              return 0
            }

            return aIdx - bIdx
          })
          .map(col => {
            const colFromLocalStorage = parsedData.find(
              ({ field }) => field === col.field,
            )

            return {
              ...col,
              visible:
                colFromLocalStorage === undefined
                  ? true
                  : colFromLocalStorage.visible,
            }
          })
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err)
      }
    }
    return columns
  }

// TODO: Write helper for set column order to storage
export const setAttachmenListColumnsToStorage = (
  next: TableColumn<AttachmentTableRow>[],
) => {
  localStorage.setItem(
    'attachmentListColumnOrder',
    JSON.stringify(next.map(({ field, visible }) => ({ field, visible }))),
  )
}

export const setAttachmentListSortToStorage = (next: {
  issueId: number
  sortByFieldName: string
  sortByOperator: string
}) => {
  localStorage.setItem('attachmentListSort', JSON.stringify(next))
}

export const getAttachmentListSortFromStorage = ():
  | {
      issueId: number
      sortByFieldName: string
      sortByOperator: string
    }
  | undefined => {
  const data = localStorage.getItem('attachmentListSort')
  if (typeof data === 'string') {
    return JSON.parse(data)
  }
}

// TODO: Refactor Table props, props styles
export const AttachmentTable = ({
  issueId,
  columns,
  setColumns,
  fileList,
  tableService,
  sort,
  setSort,
  onOpenViewer,
  isCompact,
}: AttachmentTableProps) => {
  const companyList = useStore(companyList$)
  const allUserList = useStore(allUserList$)

  const { renderTh, isSorted, SortContextMenu } = useSortTable({
    selectedSortField: sort?.sortByFieldName || '',
    selectedSortOperator: sort?.sortByOperator || '',
    getFieldKey: (col: TableColumn<AttachmentTableRow>) => col.field as string,
    onSort: params => {
      setAttachmentListSortToStorage({
        issueId,
        sortByFieldName: params.sortByFieldName || '',
        sortByOperator: params.sortByOperator || '',
      })

      setSort({
        issueId,
        sortByFieldName: params.sortByFieldName || '',
        sortByOperator: params.sortByOperator || '',
      })
    },
  })

  const attachmentList = useMemo(
    (): AttachmentTableRow[] =>
      (fileList || []).map(file => {
        const author = allUserList.find(user => user.id === file.createdBy)

        const company = companyList.find(
          company => Number(company.id) === author?.companyId,
        )

        return {
          id: file.id,
          name: file.name,
          uploadAt: file.uploadAt,
          size: file.size,
          author,
          company,
          email: author?.email,
          openViewer: () => onOpenViewer(file),
        }
      }),
    [allUserList, companyList, fileList, onOpenViewer],
  )

  const sortedAttachmentList = useMemo(() => {
    if (!attachmentList || !sort?.sortByFieldName || !sort?.sortByOperator) {
      return attachmentList
    }

    const field = sort.sortByFieldName
    const sortedList = sortBy(attachmentList, item => {
      if (field === 'author') {
        return item.author?.lastName || ''
      }
      if (field === 'company') {
        return item.company?.companyName || ''
      }
      return item[field] === null ? '' : item[field]
    })

    return sort.sortByOperator === api.SortByOperator.ASC
      ? sortedList
      : sortedList.reverse()
  }, [attachmentList, sort])

  return (
    <>
      <Table
        columns={columns}
        rows={sortedAttachmentList}
        tableService={tableService}
        onChangeColumns={setColumns}
        getRowKey={(row: AttachmentTableRow) => row.id}
        renderTh={renderTh}
        isSelectedCol={isSorted}
        pending={false}
        activeRowKey={undefined}
        compactBodyHeight={
          isCompact ? ATTACHMENT_TABLE_COMPACT_BODY_HEIGHT : undefined
        }
        hideOpenCtxButton
        onRowCtxMenu={function (
          event: React.MouseEvent<HTMLElement, MouseEvent>,
          item: AttachmentTableRow,
          // eslint-disable-next-line no-empty-function
        ): void {}}
        onClick={function (
          event: React.MouseEvent<HTMLElement, MouseEvent>,
          item: AttachmentTableRow,
          // eslint-disable-next-line no-empty-function
        ): void {}}
      />
      <SortContextMenu />
    </>
  )
}
