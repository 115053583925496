import { createEffect, createStore } from 'effector'

import * as ismApi from '@gmini/ism-api-sdk'

import { clone } from 'ramda'

import { omniSubscriptionService } from '../../omniSubscriptionService/model'
import { DateFilterItemsCode, IssueListFilterDeadlineOptionCode } from '../enum'

const saveFilterApi = ismApi.Filters.saveFilter.createContext()
const saveFilter = createEffect<
  Omit<SavedFilterType, 'id'>,
  ismApi.Filters.IssueFilterType
>().use(filter =>
  saveFilterApi({
    ...filter,
    createdDateCode: filter.createdDateCode
      ? ismApi.Filters.IssueDateCodeEnum[filter.createdDateCode]
      : null,
    updatedDateCode: filter.updatedDateCode
      ? ismApi.Filters.IssueDateCodeEnum[filter.updatedDateCode]
      : null,
    deadlineCode: filter.deadlineCode
      ? ismApi.Filters.IssueLDeadlineCodeEnum[filter.deadlineCode]
      : null,
    attributeValueIds: filter.attributeValueIds.map(String),
  }),
)

const deleteSavedFilter = ismApi.Filters.deleteFilter.createContext()

const updateSavedFilter = ismApi.Filters.updateFilter.createContext()

const fetchSavedFilters = ismApi.Filters.fetchSavedFilters.createContext()

const fetchSavedFilter = ismApi.Filters.fetchSavedFilter.createContext()

export const parseIssueFilter = (
  filter: ismApi.Filters.IssueFilterType,
): SavedFilterType => ({
  ...filter,
  updatedDateCode: filter.updatedDateCode
    ? DateFilterItemsCode[filter.updatedDateCode]
    : null,
  createdDateCode: filter.createdDateCode
    ? DateFilterItemsCode[filter.createdDateCode]
    : null,
  deadlineCode: filter.deadlineCode
    ? IssueListFilterDeadlineOptionCode[filter.deadlineCode]
    : null,
  attributeValueIds: filter.attributeValueIds?.length
    ? filter.attributeValueIds.map(Number)
    : [],
})

export type SavedFilterType = Omit<
  ismApi.Filters.IssueFilterType,
  'updatedDateCode' | 'createdDateCode' | 'deadlineCode' | 'attributeValueIds'
> & {
  createdDateCode?: DateFilterItemsCode | null
  updatedDateCode?: DateFilterItemsCode | null
  deadlineCode?: IssueListFilterDeadlineOptionCode | null
  attributeValueIds: number[]
}

const savedFilters$ = createStore<SavedFilterType[]>([])
  .on(saveFilter.doneData, (state, filter) => [
    parseIssueFilter(filter),
    ...state,
  ])
  .on(fetchSavedFilters.doneData, (_, filters) => filters.map(parseIssueFilter))
  .on(deleteSavedFilter.doneData, (state, { id }) =>
    state.filter(filter => filter.id !== id),
  )
  .on(updateSavedFilter.doneData, (state, filter) =>
    state.map(currentFilter =>
      currentFilter.id === filter.id
        ? { ...currentFilter, ...parseIssueFilter(filter) }
        : currentFilter,
    ),
  )
  .on(fetchSavedFilter.doneData, (state, filter) => {
    const nextState = [...state]

    if (nextState.some(({ id }) => id === filter.id)) {
      return nextState.map(currentFilter =>
        currentFilter.id === filter.id
          ? { ...currentFilter, ...parseIssueFilter(filter) }
          : currentFilter,
      )
    }

    nextState.push(parseIssueFilter(filter))

    return nextState
  })
  .on(omniSubscriptionService.fetchList.doneData, (state, subscriptions) => {
    if (!subscriptions.length) {
      return
    }
    const next = clone(state)
    const { projectUrn } = subscriptions[0]
    const subscriptionsMapByKey = subscriptions.reduce(
      (
        acc: Record<string, string>,
        { moduleId, projectUrn, resourceName, attributes, publicId },
      ) => {
        const key = omniSubscriptionService.createKeySubscription({
          moduleId,
          projectUrn,
          resourceName,
          attributes: attributes.map(({ attribute }) => attribute),
          values: attributes.map(({ value }) => value),
        })
        return { ...acc, [key]: publicId }
      },
      {},
    )
    return next.map(filter => ({
      ...filter,
      publicId:
        subscriptionsMapByKey[
          omniSubscriptionService.createKeyFromClientFilters({
            clientFilters: filter,
            projectUrn,
          })
        ] || null,
    }))
  })

export const savedFilters = {
  saveFilter,
  deleteSavedFilter,
  updateSavedFilter,
  fetchSavedFilters,
  fetchSavedFilter,
  savedFilters$,
}
