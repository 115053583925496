import { Arrow } from '@gmini/ui-kit'
import styled, { css } from 'styled-components'

export const Container = styled.div``

type SelectedItemProps = {
  bgColor: string
  textColor: string
  hoveredBgColor: string
  disabled?: boolean
  active: boolean
}

export const SelectedItem = styled.div`
  background: ${(props: SelectedItemProps) => props.bgColor};
  border-radius: 20px;
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 0 4px;
  color: ${(props: SelectedItemProps) => props.textColor};
  padding: 4px 8.5px;
  text-transform: uppercase;
  cursor: ${(props: SelectedItemProps) =>
    props.active ? 'pointer' : 'default'};
  transition: 0.2s ease-out background-color;

  &:hover {
    background: ${(props: SelectedItemProps) => props.hoveredBgColor};
  }

  && svg path {
    fill: ${(props: SelectedItemProps) => props.textColor};
  }

  ${(props: SelectedItemProps) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.2;
    `}
`

export const SelectArrow = styled(Arrow)`
  transform: rotate(90deg);
`

export const StatusItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0 12px;
  padding: 6.5px 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #000335;
  cursor: pointer;

  &:hover {
    background: #f4f4f8;
  }
`

type StatusItemBadgeProps = {
  color: string
}

export const StatusItemBadge = styled.div`
  height: 18px;
  width: 4px;
  border-radius: 4px;
  background: ${(props: StatusItemBadgeProps) => props.color};
`

export const StatusItemList = styled.div`
  width: fit-content;
  padding: 7px 0;
`
