import styled, { css } from 'styled-components'

export const Container = styled.div`
  width: 100%;
  max-height: 300px;
`

export const CommentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px 0;
  margin-top: 20px;
`

export const EditorWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0 10px;
  margin-top: 20px;
`

type MoreButtonProps = {
  disabled?: boolean
}

export const MoreButton = styled.div`
  width: 100%;
  background: #f5f5f7;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #353b60;
  padding: 6px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 20px;
  transition: all 0.2s ease-out;

  &:hover {
    background: rgba(53, 59, 96, 0.1);
  }

  &:active {
    background: rgba(53, 59, 96, 0.2);
  }

  ${(props: MoreButtonProps) =>
    props.disabled &&
    css`
      opacity: 0.2;
      pointer-events: none;
    `}
`
