import React, { HTMLAttributes, ReactNode } from 'react'

import { AnchorLinkStyled } from './AnchorLink.styled'

type AnchorLinkProps = HTMLAttributes<HTMLSpanElement> & {
  children: ReactNode | ReactNode[]
}

export const AnchorLink = ({ children, ...other }: AnchorLinkProps) => (
  <AnchorLinkStyled {...other}>{children}</AnchorLinkStyled>
)
