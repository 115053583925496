import { useCallback } from 'react'

import { Select } from '@gmini/ui-kit'

import { SelectPreview } from '../../atoms'
import { RenderEditModeProps } from '../../atoms/SelectPreview/SelectPreview.types'

type SelectWithPreviewProps<Value> = {
  options: Value[]
  value: Value | null
  getOptionLabel: (value: Value) => string
  placeholder: string
  onChange?: (value: Value | null) => void
  disabled?: boolean
  renderValue?: (value: Value) => string | undefined
  allowDelete?: boolean
  autofocus?: boolean
  editable?: boolean
}

enum Mode {
  preview = 'preview',
  edit = 'edit',
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function SelectWithPreview<Value extends {}>({
  options,
  value,
  getOptionLabel,
  placeholder,
  onChange,
  disabled,
  renderValue,
  allowDelete = true,
  autofocus = true,
  editable = true,
}: SelectWithPreviewProps<Value>) {
  const renderEditMode = useCallback(
    ({ setMode, handlers }: RenderEditModeProps) => (
      <Select
        value={value}
        options={options}
        onChange={newValue => {
          setMode(Mode.preview)
          onChange?.(newValue)
        }}
        getOptionLabel={getOptionLabel}
        placeholder={placeholder}
        onKeyDown={handlers.onKeyDown}
        allowDelete={allowDelete}
        onClick={() => setMode(Mode.preview)}
        colorScheme='white'
        autofocus={autofocus}
        onBlur={() => setMode(Mode.preview)}
      />
    ),
    [
      allowDelete,
      autofocus,
      getOptionLabel,
      onChange,
      options,
      placeholder,
      value,
    ],
  )

  return (
    <SelectPreview
      value={value}
      renderEditMode={renderEditMode}
      placeholder={placeholder}
      renderValue={renderValue}
      disabled={disabled}
      editable={editable}
      dataTestId='editAuthor'
    />
  )
}
