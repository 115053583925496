import { List } from '@gmini/ui-kit'

import { Container, PrimaryText, SecondaryText } from './EmptyList.styled'

export const EmptyList = () => (
  <Container>
    <List
      width='36px'
      height='36px'
      color='rgba(53, 59, 96, 0.2)
'
    />
    <PrimaryText>Список пуст</PrimaryText>
    <SecondaryText>
      Здесь будут отображаться сохраненные <br /> вами фильтры
    </SecondaryText>
  </Container>
)
