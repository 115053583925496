import styled from 'styled-components'

import {
  TextField as TextFieldInit,
  TextArea as TextAreaInit,
} from '@gmini/ui-kit'

export const EmptyContainer = styled.div`
  height: 60px;
`

export const Separator = styled.div`
  width: 100%;
  background: #e5e7f1;
  height: 1px;
  min-height: 1px;
  margin-bottom: 13px;
`

export const FullSeparator = styled(Separator)`
  margin: 4px -24px 4px -24px;
  width: calc(100% + 48px);
`

export const AttributesWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
`

export const FieldContainerLine = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
`

export const FieldContainer = styled.div`
  width: 100%;
`
export const MultipleFieldRow = styled.div`
  display: flex;
  gap: 12px;
`
export const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  padding: 10px 24px 24px 24px;
`
export const TextArea = styled(TextAreaInit)`
  && {
    background: #f4f4f8;
    width: 100%;
  }
`
export const TextField = styled(TextFieldInit)`
  background: #f4f4f8;
`
