import { DateFilterItemsCode, filterDateRangeByCode } from '@gmini/helpers'

export const filterDateItems: (currentDate: Date) => Array<{
  name: string
  code: DateFilterItemsCode
  dateRange: [string, string] | null
}> = (currentDate: Date) => [
  { name: 'За весь период', code: DateFilterItemsCode.ALL, dateRange: null },
  {
    name: 'За последнюю неделю',
    code: DateFilterItemsCode.LAST_WEEK,
    dateRange:
      filterDateRangeByCode[DateFilterItemsCode.LAST_WEEK]?.(currentDate) ||
      null,
  },
  {
    name: 'За последние 2 недели',
    code: DateFilterItemsCode.LAST_2_WEEK,
    dateRange:
      filterDateRangeByCode[DateFilterItemsCode.LAST_2_WEEK]?.(currentDate) ||
      null,
  },
  {
    name: 'За последний месяц',
    code: DateFilterItemsCode.LAST_MONTH,
    dateRange:
      filterDateRangeByCode[DateFilterItemsCode.LAST_MONTH]?.(currentDate) ||
      null,
  },
  // {
  //   name: 'Указать вручную',
  //   code: DateFilterItemsCode.CUSTOM,
  //   dateRange: null,
  // }, // TODO добавить обратно, когда разберусь с DatePicker
]
