import { ReactNode } from 'react'

import { FilterItemSkeleton } from '../FilterItemSkeleton'

import { FilterWrapperStyled } from './FilterWrapper.styled'

type FilterWrapperProps = {
  loading?: boolean
  countSkeletonItems?: number
  children: JSX.Element | ReactNode | ReactNode[]
}

export const FilterWrapper = ({
  countSkeletonItems,
  loading,
  children,
}: FilterWrapperProps) => {
  const showSkeleton = loading && countSkeletonItems
  return (
    <FilterWrapperStyled>
      {showSkeleton ? (
        <FilterItemSkeleton count={countSkeletonItems || 10} />
      ) : (
        children
      )}
    </FilterWrapperStyled>
  )
}
