/* eslint-disable react/display-name */
import {
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import {
  Button,
  Cross,
  Download,
  IconButton,
  PlusCircle,
  Snackbar,
  Table,
  TableColumn,
  TableContentContainer,
  SelectedRowsChip,
  Tag,
  Tooltip,
  Update,
} from '@gmini/ui-kit'
import { CircularProgress } from '@gmini/ui-kit/lib/CircularProgress'
import {
  countNonMatchingItems,
  declensionsOfNum,
  isNotEmpty,
  useDebounce,
} from '@gmini/utils'
import qs from 'query-string'

import { Icon } from '@gmini/common/lib/classifier-editor/ContextMenuItem'
import { useContextMenu } from '@gmini/common/lib/components/VersionSwitch/ContextMenu'

import { useStore, useStoreMap } from 'effector-react'

import {
  getDaysNumberBeforeOrAfterDeadline,
  getDeadlineNameDay,
  getDeadlineStatus,
  getDeadlineStyle,
  interfaceName,
  userInfo$,
  columnAction,
  useSortTable,
} from '@gmini/common'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'

import {
  ActionPanel,
  AnchorLink,
  EntityCreatedSnackbar,
  HighlightOffIcon,
  InfiniteScroll,
  LinkedEntityTableItem,
  LinkedEntityTableItemName,
  Magnifier,
  RenderAssignees,
  RenderAttributes,
  SavedFilter,
  SavedFilterWrapper,
  SavedFiltersList,
  SearchContainer,
  SearchInput,
  StatusContainer,
  StatusIndicator,
  StatusWrapper,
  StyledEye,
  SubscribeIcon,
  TabContainer,
  TableContent,
  UnsubscribeIcon,
  snackbarDataTestId,
} from '@gmini/components'
import {
  AssigneeListItem,
  DateFilterItemsCode,
  ISSUE_ID,
  IssueListFilterDeadlineOptionCode,
  IssueListFiltersType,
  IssueUrlKeyType,
  PreparedIssueStatus,
  SEARCH_IL,
  SORT_BY_FIELD_NAME_IL,
  SORT_BY_OPERATOR_IL,
  getListColumnOrderFromStorage,
  omniSubscriptionService,
  queryParser,
  useAssignees,
} from '@gmini/helpers'

import * as smApi from '@gmini/sm-api-sdk'

import * as api from '@gmini/ism-api-sdk'

import { equals, isEmpty, isNil } from 'ramda'

import { FilterShowItemType } from '@gmini/components/src/molecules/FilterShow/FilterShow.types'

import { CreateIssuePopup } from '../CreateIssuePopup'

import {
  FetchIssueListWithExtraDataParams,
  FetchIssueListXLSXWithExtraDataParams,
  PreparedIssue,
  addIssueToIds,
  fetchIssueListWithExtraData,
  fetchIssueXlsxWithExtraData,
  fetchIssuesWithSubscription,
  fetchXlsxIssuePending$,
  issuePending$,
  issuesList$,
  resetIssueList,
} from '../../issue.store'

import { gStationDocumentManagementUrl, seoEventManager } from '../../../config'
import {
  DEFAULT_DISPLAY_DATE_FORMAT,
  PROJECT_IS_NOT_SELECTED_ERROR,
  PROJECT_URN,
  ZERO_SEARCH,
} from '../../../constants'
import {
  assigneeAllUserList$,
  assigneeCompanyList$,
  assigneeRoleList$,
} from '../../assigneeGroupList'
import { filterService } from '../../issueFilter.store'
import { issueStatusList$ } from '../../issueStatus.store'
import {
  allUserList$,
  fetchAllUserListPending$,
  projectUserList$,
} from '../../user.store'
import { EditIssuePopup } from '../EditIssuePopup'
import { IssueListAppliedFilters } from '../IssueListAppliedFilters'
import { IssueListFilterPanel } from '../IssueListFilterPanel'

import { isFilterExist$, parsedFilters } from '../../../helpers'

import { attributesService } from '../../attribute.store'

import { SaveFilter } from '../../molecules'

import { fetchAllowedFilters } from '../IssueListFilterPanel/model'

import { StyledIconButton } from './IssueList.styled'
import { defaultOffset, limit } from './constants'
import {
  convertClientToServerFilters,
  getCreateOmniSubscriptionParams,
} from './converter'
import { matchIssueToFilters } from './matchIssueToFilters'
import { issueTableService } from './model'
import { useIssueNavigation } from './useIssueNavigation'

type IssueListProps = {
  projectList: smApi.Project[]
}

export const IssueList = ({ projectList }: IssueListProps) => {
  const selectedSortField =
    (queryParser({ key: SORT_BY_FIELD_NAME_IL }) as string) || null
  const selectedSortOperator =
    (queryParser({ key: SORT_BY_OPERATOR_IL }) as string) || null
  const navigate = useNavigate()
  const projectUrn = queryParser({ key: PROJECT_URN }) as string
  const selectedIssueId = queryParser({
    key: ISSUE_ID,
    options: { parseNumbers: true },
  }) as number
  const isFilterExist = useStore(isFilterExist$)

  const {
    appliedFilters: { detailedFilters$, appliedFilters$, useUpdateFilter },
    savedFilters: {
      savedFilters$,
      deleteSavedFilter,
      updateSavedFilter,
      fetchSavedFilters,
      saveFilter,
    },
    filterActionsPending$,
  } = filterService

  const fetchAllUserListPending = useStore(fetchAllUserListPending$)

  const savedFilters = useStore(savedFilters$)
  const filterActionsPending = useStore(filterActionsPending$)
  const appliedFilters = useStore(appliedFilters$)
  const detailedFilters = useStore(detailedFilters$)
  const attributeValueById = useStore(attributesService.attributeValueById$)
  const userInfo = useStore(userInfo$)
  const filtersCount = countNonMatchingItems({
    dictionary: detailedFilters,
    predicates: [isEmpty, isNil, key => equals(key, DateFilterItemsCode.ALL)],
  })
  const { update, applyFilters } = useUpdateFilter()
  const [searchValue, setSearchValue] = useState(appliedFilters.filter)
  const selectedProject = useMemo(
    () => projectList.find(({ urn }) => urn === projectUrn) || null,
    [projectList, projectUrn],
  )
  const issueKeyMapAttributes = useCallback(
    (attributes: number[] = []) =>
      attributes.reduce((acc: Record<number, api.Attribute>, valueId) => {
        if (!attributeValueById?.[valueId]) {
          return acc
        }
        const { id, attribute } = attributeValueById?.[valueId]
        if (!acc[attribute.id]) {
          acc[attribute.id] = { id: attribute.id, valueIds: [id] }
          return acc
        }
        acc[attribute.id].valueIds.push(valueId)

        return acc
      }, {}),
    [attributeValueById],
  )

  const fetchIssueListWithExtraDataCb = useCallback(
    async (
      params: FetchIssueListWithExtraDataParams & { show?: FilterShowItemType },
    ) => {
      const response = await ((params?.show || appliedFilters.show) ===
      FilterShowItemType.SUBSCRIBED
        ? fetchIssuesWithSubscription(params.project.urn)
        : fetchIssueListWithExtraData({
            ...appliedFilters,
            showNotRelevant: true,
            ...params,
            ...((params?.show || appliedFilters.show) ===
              FilterShowItemType.FOR_ME && {
              assignees: [
                {
                  assigneeId: userInfo?.id || '',
                  source: api.AssigneeSource.USER,
                },
              ],
            }),
          }))
      return response
    },
    [appliedFilters, userInfo?.id],
  )

  const [openCreateIssuePopup, setOpenCreateIssuePopup] = useState(false)
  const [offset, setOffset] = useState(defaultOffset)
  const debouncedFetch = useDebounce({
    handler: async () => {
      const attributes = issueKeyMapAttributes(appliedFilters.attributeValueIds)
      const formattedSearchValue = searchValue.trim().toLowerCase()
      if (
        appliedFilters.filter.trim().toLowerCase() === formattedSearchValue ||
        !selectedProject
      ) {
        return
      }
      resetIssueList()
      setOffset(0)
      const { total } = await fetchIssueListWithExtraDataCb({
        offset: 0,
        limit,
        project: selectedProject,
        filter: formattedSearchValue,
        attributes: Object.values(attributes || {}),
      })
      seoEventManager.push({
        event: 'Gstation_Issues_Search',
        payload: {
          searchRequest: searchValue,
          searchResult: total,
        },
      })
      formattedSearchValue
        ? update({ [SEARCH_IL]: formattedSearchValue })
        : update({ [SEARCH_IL]: '' })
    },
    delay: 500,
  })
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const tableContainerRefCurrent = tableContainerRef.current
  const issuePending = useStore(issuePending$)
  const fetchXlsxIssuePending = useStore(fetchXlsxIssuePending$)
  const allUserList = useStore(allUserList$)
  const userList = useStore(projectUserList$)
  const issueStatusList = useStore(issueStatusList$)
  const [columns, setColumns] = useState(getIssueListColumnOrderFromStorage())
  const checked = useStore(issueTableService.checked$)
  const checkedCount = useStore(issueTableService.checkedCount$)

  const fetchIssueXlsxWithExtraDataCb = useCallback(
    (params: Omit<FetchIssueListXLSXWithExtraDataParams, 'fields'>) => {
      const attributes = issueKeyMapAttributes(appliedFilters.attributeValueIds)

      fetchIssueXlsxWithExtraData({
        ...appliedFilters,
        showNotRelevant: true,
        ...params,
        fields: columns.map(({ field }) => field),
        attributes: Object.values(attributes || {}),
        ...((params?.show || appliedFilters.show) ===
          FilterShowItemType.FOR_ME && {
          assignees: [
            {
              assigneeId: userInfo?.id || '',
              source: api.AssigneeSource.USER,
            },
          ],
        }),
      })
    },
    [appliedFilters, columns, issueKeyMapAttributes, userInfo?.id],
  )

  const getAssignees = useAssignees({
    assigneeRoleList$,
    assigneeUserList$: assigneeAllUserList$,
    assigneeCompanyList$,
  })

  const { issueList, total, byId } = useStoreMap({
    store: issuesList$,
    keys: [appliedFilters.filter?.trim().toLowerCase()],
    fn: ({ byId$, ids$, totalIssues$ }, [val]) => {
      const search = val || ZERO_SEARCH
      const idsList = ids$[search]
      if (idsList) {
        return {
          issueList: idsList.map(id => byId$[id]).filter(isNotEmpty),
          total: totalIssues$ || 0,
          byId: byId$,
        }
      }

      return { issueList: [], total: 0, byId: byId$ }
    },
  })
  const listIsNotOver = total > offset

  const idsToSubscribe = useMemo(
    () =>
      Object.keys(checked).filter(
        key =>
          checked[key] &&
          !byId[key].subscriptionPublicId &&
          byId[key].author !== userInfo?.id &&
          !byId[key].assignees.some(
            assignee => assignee.assigneeId === userInfo?.id,
          ),
      ),
    [checked, byId, userInfo?.id],
  )
  const idsToUnsubscribe = useMemo(
    () =>
      Object.keys(checked).filter(
        key =>
          checked[key] &&
          byId[key].subscriptionPublicId &&
          byId[key].author !== userInfo?.id &&
          !byId[key].assignees.some(
            assignee => assignee.assigneeId === userInfo?.id,
          ),
      ),
    [checked, byId, userInfo?.id],
  )

  useEffect(() => {
    const fetch = async () => {
      if (projectUrn) {
        await fetchSavedFilters(undefined)
        omniSubscriptionService.fetchList({
          projectUrn: projectUrn || '',
          moduleId: smApi.Omni.ModuleId.ISSUE_MANAGEMENT,
          resourceName: smApi.Omni.ResourceName.ISSUE,
        })
      }
    }

    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectUrn])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => debouncedFetch(), [searchValue])

  useEffect(() => {
    setColumnsToStorage(columns)
  }, [columns])

  useEffect(() => {
    seoEventManager.push({
      event: 'Gstation_Issues_Page_View',
      payload: {},
    })
  }, [])

  useEffect(() => {
    if (!selectedProject) {
      return
    }
    const attributes = issueKeyMapAttributes(appliedFilters.attributeValueIds)
    resetIssueList()
    fetchIssueListWithExtraDataCb({
      offset: 0,
      limit,
      project: selectedProject,
      attributes: Object.values(attributes || {}),
    })
    setOffset(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject])

  useEffect(() => {
    if (
      !selectedProject ||
      offset === 0 ||
      appliedFilters.show === FilterShowItemType.SUBSCRIBED
    ) {
      return
    }
    const attributes = issueKeyMapAttributes(appliedFilters.attributeValueIds)
    fetchIssueListWithExtraDataCb({
      offset,
      limit,
      project: selectedProject,
      attributes: Object.values(attributes || {}),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, selectedProject])

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const handleSubscribeToMultipleIssues = useCallback(async () => {
    if (!selectedProject) {
      return
    }
    const res = await Promise.all(
      idsToSubscribe.map(id =>
        omniSubscriptionService.createSubscription(
          getCreateOmniSubscriptionParams({
            id,
            projectUrn: selectedProject.urn,
          }),
        ),
      ),
    )
    omniSubscriptionService.updateSubscriptionsMap(
      res.reduce(
        (acc, sub) => ({
          ...acc,
          [sub.attributes[0].value]: sub.publicId,
        }),
        {},
      ),
    )
    enqueueSnackbar('', {
      content: key => (
        <Snackbar
          data-test-id={snackbarDataTestId}
          id={key}
          message={`${res.length} ${declensionsOfNum(res.length, [
            'новое замечание добавлено',
            'новых замечания добавлены',
            'новых замечаний добавлены',
          ])} в список отслеживаемых`}
          onClose={() => closeSnackbar(key)}
        />
      ),
      variant: 'info',
    })
  }, [idsToSubscribe, closeSnackbar, enqueueSnackbar, selectedProject])

  const handleUnsubscribeFromMultipleIssues = useCallback(async () => {
    if (!selectedProject) {
      return
    }

    const result = await Promise.all(
      idsToUnsubscribe.map(id =>
        omniSubscriptionService.removeSubscription({
          id: byId[id]?.subscriptionPublicId || '',
        }),
      ),
    )
    omniSubscriptionService.updateSubscriptionsMap(
      idsToUnsubscribe.reduce((acc, id) => ({ ...acc, [id]: '' }), {}),
    )
    enqueueSnackbar('', {
      content: key => (
        <Snackbar
          data-test-id={snackbarDataTestId}
          id={key}
          message={`${result.length} ${declensionsOfNum(result.length, [
            'замечание убрано',
            'замечания убраны',
            'замечаний убраны',
          ])} из списка отслеживаемых`}
          onClose={() => closeSnackbar(key)}
        />
      ),
      variant: 'info',
    })
  }, [byId, idsToUnsubscribe, closeSnackbar, enqueueSnackbar, selectedProject])

  const { ContextMenu, setCtxMenu, ctxMenu } = useContextMenu<{
    item: IssueTableRow
    event: MouseEvent
  }>([
    {
      tooltipTitle: () =>
        !idsToSubscribe.length ? subscriptionUnavailableText : '',
      title: `Отслеживать ${idsToSubscribe.length} ${declensionsOfNum(
        idsToSubscribe.length,
        ['замечание', 'замечания', 'замечаний'],
      )}`,
      onClick: handleSubscribeToMultipleIssues,
      disabled: () => !idsToSubscribe.length,
      show: () => !!checkedCount && Boolean(idsToSubscribe.length),
      customIcon: <SubscribeIcon />,
      dataTestId: 'issueListSubscribeCheckedCtxMenuBtn',
    },
    {
      tooltipTitle: () =>
        !idsToUnsubscribe.length ? unsubscriptionUnavailableText : '',
      title: `Не отслеживать ${idsToUnsubscribe.length} ${declensionsOfNum(
        idsToUnsubscribe.length,
        ['замечание', 'замечания', 'замечаний'],
      )}`,
      onClick: handleUnsubscribeFromMultipleIssues,
      disabled: () => !idsToUnsubscribe.length,
      show: () => !!checkedCount && Boolean(idsToUnsubscribe.length),
      customIcon: <UnsubscribeIcon />,
      dataTestId: 'issueListUnsubscribeCheckedCtxMenuBtn',
    },
    {
      title: 'Редактировать',
      onClick: props => {
        const prev = qs.parse(window.location.search)
        const search = qs.stringify({ ...prev, [ISSUE_ID]: props.item.id })
        navigate({ search })
      },
      icon: Icon.EDIT,
    },
    {
      title: 'Создать копию',
      // eslint-disable-next-line no-empty-function
      onClick: props => {},
      icon: Icon.COPY,
      disabled: () => true,
    },
    {
      title: 'Архивировать',
      // eslint-disable-next-line no-empty-function
      onClick: props => {},
      icon: Icon.ARCHIVE,
      disabled: () => true,
    },
  ])

  const resetInfinityScroll = () => {
    if (!selectedProject) {
      throw new Error(PROJECT_IS_NOT_SELECTED_ERROR)
    }
    const attributes = issueKeyMapAttributes(appliedFilters.attributeValueIds)
    tableContainerRefCurrent?.scrollTo(0, 0)
    resetIssueList()
    fetchIssueListWithExtraDataCb({
      offset: 0,
      limit,
      project: selectedProject,
      attributes: Object.values(attributes || {}),
    })
    setOffset(0)
  }

  const seoSortEventHandler = useCallback(
    (
      columnName: TableColumn<IssueTableRow>['field'],
      operator: api.SortByOperator,
    ) => {
      seoEventManager.push({
        event: 'Gstation_Issues_EditTable',
        payload: {
          columnName,
          columnAction: columnAction.sort,
          columnOrder: operator,
        },
      })
    },
    [],
  )

  const onSort = useCallback(
    (filters: IssueUrlKeyType) => {
      if (!selectedProject) {
        return null
      }
      if (
        SORT_BY_FIELD_NAME_IL in filters &&
        filters[SORT_BY_FIELD_NAME_IL] &&
        filters[SORT_BY_OPERATOR_IL]
      ) {
        seoSortEventHandler(
          filters[SORT_BY_FIELD_NAME_IL],
          filters[SORT_BY_OPERATOR_IL],
        )
      }

      update(filters)
      resetIssueList()
      const attributes = issueKeyMapAttributes(appliedFilters.attributeValueIds)
      fetchIssueListWithExtraDataCb({
        ...appliedFilters$.getState(),
        showNotRelevant: true,
        offset: 0,
        limit,
        project: selectedProject,
        attributes: Object.values(attributes || {}),
      })
      setOffset(0)
    },
    [
      appliedFilters$,
      appliedFilters.attributeValueIds,
      fetchIssueListWithExtraDataCb,
      issueKeyMapAttributes,
      selectedProject,
      update,
      seoSortEventHandler,
    ],
  )

  const seoColVisibilityEventHandler = useCallback(
    (column: TableColumn<IssueTableRow>) => {
      seoEventManager.push({
        event: 'Gstation_Issues_EditTable',
        payload: {
          columnName: column.field,
          columnAction: column.visible ? columnAction.show : columnAction.hide,
          columnOrder: 'none',
        },
      })
    },
    [],
  )

  const { renderTh, isSorted, SortContextMenu } = useSortTable({
    selectedSortField,
    selectedSortOperator,
    sortKeysMap: {
      fieldKey: SORT_BY_FIELD_NAME_IL,
      operatorKey: SORT_BY_OPERATOR_IL,
      asc: api.SortByOperator.ASC,
      desc: api.SortByOperator.DESC,
    },
    onSort,
    unSortableFields: columns
      .map(c => c.field)
      .filter(f => !(f in api.Issue.SortableField)),
    getFieldKey: (col: TableColumn<IssueTableRow>) => col.field,
  })

  const tableList = useMemo(
    (): IssueTableRow[] =>
      issueList.map((issue, idx) => ({
        ...issue,
        assignees: getAssignees(issue.assignees),
        author:
          allUserList.find(user => user.id === issue.author) ||
          ({} as smApi.Auth.UserData),
        status: issueStatusList.find(item => item.status === issue.status),
        subscriptionPublicId:
          issue.author !== userInfo?.id &&
          !issue.assignees.some(
            assignee => assignee.assigneeId === userInfo?.id,
          )
            ? issue.subscriptionPublicId
            : '',
      })),
    [getAssignees, issueList, issueStatusList, allUserList, userInfo?.id],
  )

  const isEnabledCol = useCallback(
    (col: TableColumn<IssueTableRow>) => {
      if (gStationDocumentManagementUrl && col.field === 'linkedEntity') {
        return selectedProject?.sourceType === 'GStation'
      }

      return true
    },
    [selectedProject?.sourceType],
  )

  const onChangeFilter = useCallback(
    (
      filter: Partial<
        Omit<IssueListFiltersType, 'assignees'> & {
          assignees: api.Assignee[]
        }
      >,
    ) => {
      if (!selectedProject) {
        throw new Error(PROJECT_IS_NOT_SELECTED_ERROR)
      }

      const attributes = issueKeyMapAttributes(filter.attributeValueIds)

      resetIssueList()
      fetchIssueListWithExtraDataCb({
        offset: 0,
        limit,
        project: selectedProject,
        attributes: Object.values(attributes || {}),
        ...filter,
      })

      setOffset(0)
    },
    [fetchIssueListWithExtraDataCb, issueKeyMapAttributes, selectedProject],
  )

  const notifyCreatedIssue = useCallback(
    (issue: api.Issue.IssuePopulated, isMatchIssueToFilters: boolean) => {
      let infoText: string | null =
        'Измените параметры фильтрации для отображения данного замечания в общем списке.'

      if (isMatchIssueToFilters) {
        infoText = null
      }

      enqueueSnackbar('', {
        content: key => (
          <EntityCreatedSnackbar
            id={key}
            message={`Новое замечание “${issue.name}” успешно создано.`}
            onClose={() => closeSnackbar()}
            infoText={infoText}
          />
        ),
      })
    },
    [closeSnackbar, enqueueSnackbar],
  )

  const onCreateIssue = useCallback(
    (issue: api.Issue.IssuePopulated) => {
      if (!selectedProject) {
        throw new Error(PROJECT_IS_NOT_SELECTED_ERROR)
      }

      const valueIds = issue.attributes.flatMap(attr => attr.valueIds)
      const isMatchIssueToFilters = matchIssueToFilters(issue, appliedFilters)

      if (isMatchIssueToFilters) {
        addIssueToIds({
          id: issue.id,
          search: appliedFilters.filter,
          sortByFieldName: appliedFilters.sortByFieldName,
          sortByOperator: appliedFilters.sortByOperator,
        })
      }
      const attributes = issueKeyMapAttributes(appliedFilters.attributeValueIds)
      fetchIssueListWithExtraDataCb({
        ...appliedFilters,
        showNotRelevant: true,
        offset: 0,
        limit,
        project: selectedProject,
        attributes: Object.values(attributes || {}),
      })
      attributesService.fetchAttributeValues({ valueIds })
      fetchAllowedFilters({
        projectUrn,
        showNotRelevant: true,
      })
      notifyCreatedIssue(issue, isMatchIssueToFilters)
    },
    [
      appliedFilters,
      fetchIssueListWithExtraDataCb,
      issueKeyMapAttributes,
      notifyCreatedIssue,
      projectUrn,
      selectedProject,
    ],
  )

  const isLoadingCol = useCallback(
    (col: TableColumn<IssueTableRow>) => {
      if (col.field === 'assignees' || col.field === 'author') {
        return fetchAllUserListPending
      }

      return false
    },
    [fetchAllUserListPending],
  )

  const serverFilters = useMemo(
    () =>
      savedFilters.map(filter => ({
        ...parsedFilters({
          filters: {
            ...filter,
            assignees: getAssignees(filter.assignees || []),
          },
          issueStatusList,
          userList,
        }),
        assignees: getAssignees(filter.assignees || []).map(el => el.label),
        id: filter.id,
        name: filter.name,
        publicId: filter.publicId,
        attributeValues: filter.attributeValueIds
          .map(id => attributeValueById[id])
          .filter(Boolean),
      })),
    [attributeValueById, getAssignees, issueStatusList, savedFilters, userList],
  )
  const disabledSubmitButton = useMemo(
    () => Object.values(detailedFilters).every(value => isEmpty(value)),
    [detailedFilters],
  )

  const onSaveFilters = useCallback(
    async name => {
      await saveFilter({
        authorId: userInfo!.id,
        name,
        ...appliedFilters,
      })

      enqueueSnackbar('', {
        content: key => (
          <Snackbar
            data-test-id={snackbarDataTestId}
            id={key}
            message={`Фильтр '${name}' сохранен.`}
            onClose={() => closeSnackbar(key)}
          />
        ),
        variant: 'info',
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [closeSnackbar, enqueueSnackbar, appliedFilters, userInfo?.id],
  )

  const onSubscribeToFilter = ({
    id,
    publicId,
  }: {
    id?: number
    publicId?: string | null
  }) => {
    const currentFilter = savedFilters.find(filter => filter.id === id)
    if (!currentFilter) {
      throw new Error('Filter not found')
    }

    const preparedFilters = convertClientToServerFilters({
      clientFilters: currentFilter,
      projectUrn: projectUrn || '',
      issueStatusList,
      userList,
      assignees: getAssignees(currentFilter.assignees || []),
      attributeValueById,
    })

    if (id) {
      publicId
        ? omniSubscriptionService
            .removeSubscription({ id: publicId })
            .then(() => {
              updateSavedFilter({
                id,
                filter: { publicId: null },
              })
              enqueueSnackbar('', {
                content: key => (
                  <Snackbar
                    data-test-id={snackbarDataTestId}
                    id={key}
                    message={`Вы отписались от замечания по фильтру "${currentFilter.name}"`}
                    onClose={() => closeSnackbar(key)}
                  />
                ),
                variant: 'info',
              })

              seoEventManager.push({
                event: 'Gstation_Issues_SavedFilters_Unsubscribe',
                payload: {
                  filterId: id,
                },
              })
            })
        : omniSubscriptionService
            .createSubscription(preparedFilters)
            .then(({ publicId }) => {
              updateSavedFilter({
                id,
                filter: { publicId },
              })
              enqueueSnackbar('', {
                content: key => (
                  <Snackbar
                    data-test-id={snackbarDataTestId}
                    id={key}
                    message={`Вы подписались на замечания по фильтру "${currentFilter.name}"`}
                    onClose={() => closeSnackbar(key)}
                  />
                ),
                variant: 'info',
              })
            })

      seoEventManager.push({
        event: 'Gstation_Issues_SavedFilters_Subscribe',
        payload: {
          filterId: id,
        },
      })
    }
  }

  const onDeleteFilter = ({
    id,
    publicId,
    name,
  }: {
    id?: number
    publicId?: string | null
    name?: string
  }) => {
    const popup = () =>
      enqueueSnackbar('', {
        content: key => (
          <Snackbar
            data-test-id={snackbarDataTestId}
            id={key}
            message={`Фильтр "${name}" удален`}
            onClose={() => closeSnackbar(key)}
          />
        ),
        variant: 'info',
      })
    if (id) {
      publicId
        ? omniSubscriptionService
            .removeSubscription({ id: publicId })
            .then(() => {
              deleteSavedFilter({ id })
              popup()
            })
        : deleteSavedFilter({ id }).then(() => popup())
    }
  }

  const onApplyFilter = (id?: number) => {
    const currentFilter = savedFilters.find(filter => filter.id === id)
    if (currentFilter) {
      applyFilters({
        ...currentFilter,
        createdDateCode: currentFilter.createdDateCode
          ? api.Filters.IssueDateCodeEnum[currentFilter.createdDateCode]
          : null,
        deadlineCode: currentFilter.deadlineCode
          ? api.Filters.IssueLDeadlineCodeEnum[currentFilter.deadlineCode]
          : null,
        updatedDateCode: currentFilter.updatedDateCode
          ? api.Filters.IssueDateCodeEnum[currentFilter.updatedDateCode]
          : null,
        assignees: getAssignees(currentFilter.assignees || []),
        attributeValueIds: currentFilter.attributeValueIds.map(String),
      })

      onChangeFilter({
        ...currentFilter,
        createdDateCode: currentFilter.createdDateCode
          ? DateFilterItemsCode[currentFilter.createdDateCode]
          : null,
        deadlineCode: currentFilter.deadlineCode
          ? IssueListFilterDeadlineOptionCode[currentFilter.deadlineCode]
          : null,
        updatedDateCode: currentFilter.updatedDateCode
          ? DateFilterItemsCode[currentFilter.updatedDateCode]
          : null,
      })
    }
  }

  const loadMoreIssues = useCallback(
    (offset: number) => {
      if (selectedProject && appliedFilters.show === FilterShowItemType.ALL) {
        const attributes = issueKeyMapAttributes(
          appliedFilters.attributeValueIds,
        )
        fetchIssueListWithExtraDataCb({
          ...appliedFilters,
          showNotRelevant: true,
          offset,
          limit,
          project: selectedProject,
          attributes: Object.values(attributes || {}),
        })
        setOffset(offset)
      }
    },
    [
      appliedFilters,
      fetchIssueListWithExtraDataCb,
      issueKeyMapAttributes,
      selectedProject,
    ],
  )

  const { onNextIssue, onPrevIssue, nextDisabled, prevDisabled } =
    useIssueNavigation({
      startIndex:
        issueList.find(({ id }) => id === selectedIssueId)?.id || null,
      list: issueList.map(({ id }) => id),
      loadMore: loadMoreIssues,
    })

  if (!userInfo) {
    return null
  }

  return (
    <TabContainer>
      <CreateIssuePopup
        open={openCreateIssuePopup}
        onCreateIssue={onCreateIssue}
        onClose={() => setOpenCreateIssuePopup(false)}
      />

      <EditIssuePopup
        selectedIssueId={selectedIssueId || null}
        issue={issueList.find(({ id }) => id === selectedIssueId) || null}
        onNextIssue={onNextIssue}
        onPrevIssue={onPrevIssue}
        nextDisabled={nextDisabled}
        prevDisabled={prevDisabled}
      />

      <TableContentContainer>
        <IssueListFilterPanel
          onChange={onChangeFilter}
          projectUrn={projectUrn}
        />

        <TableContent>
          <ActionPanel>
            <SearchContainer>
              <SearchInput
                value={searchValue}
                onChange={event => setSearchValue(event.target.value)}
                placeholder='Поиск'
                width='230px'
              />
              <Magnifier />
              {searchValue ? (
                <HighlightOffIcon onClick={() => setSearchValue('')} />
              ) : null}
            </SearchContainer>

            <IssueListAppliedFilters onChange={onChangeFilter} />
            {!!checkedCount && (
              <>
                <SelectedRowsChip>
                  <span>Выбрано {checkedCount}</span>
                  <Cross
                    data-test-id='issueListResetCheckedBtn'
                    onClick={() => issueTableService.resetChecked()}
                  />
                </SelectedRowsChip>
                {Boolean(idsToSubscribe.length) && (
                  <Tooltip
                    title={
                      !idsToSubscribe.length
                        ? subscriptionUnavailableText
                        : 'Отслеживать замечания'
                    }
                  >
                    <IconButton
                      data-test-id='issueListSubscribeCheckedBtn'
                      onClick={handleSubscribeToMultipleIssues}
                      disabled={!idsToSubscribe.length}
                    >
                      <SubscribeIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {Boolean(idsToUnsubscribe.length) && (
                  <Tooltip
                    title={
                      !idsToUnsubscribe.length
                        ? unsubscriptionUnavailableText
                        : 'Не отслеживать замечания'
                    }
                  >
                    <StyledIconButton
                      data-test-id='issueListUnsubscribeCheckedBtn'
                      onClick={handleUnsubscribeFromMultipleIssues}
                      disabled={!idsToUnsubscribe.length}
                    >
                      <UnsubscribeIcon />
                    </StyledIconButton>
                  </Tooltip>
                )}
              </>
            )}
            <SaveFilter
              appliedFilterElements={detailedFilters}
              renderAppliedFilterElements={el => (
                <SavedFilter
                  {...el}
                  attributeValueById$={attributesService.attributeValueById$}
                  seoEventManager={seoEventManager}
                />
              )}
              onSubmit={onSaveFilters}
              disableSubmitButton={disabledSubmitButton}
              disable={isFilterExist}
              visible={!!filtersCount}
              isExist={isFilterExist}
              seoEventManager={seoEventManager}
            />
            <SavedFiltersList
              existingFilters={serverFilters}
              renderListItem={filter => (
                <SavedFilterWrapper key={filter.id}>
                  <SavedFilter
                    {...filter}
                    onRemove={onDeleteFilter}
                    onSubscribe={onSubscribeToFilter}
                    disabledActions={filterActionsPending}
                    applyFilter={onApplyFilter}
                    attributeValueById$={attributesService.attributeValueById$}
                    seoEventManager={seoEventManager}
                  />
                </SavedFilterWrapper>
              )}
              seoEventManager={seoEventManager}
            />

            <Tooltip
              placement='top'
              title='Обновить список замечаний'
              styleContent={{ marginLeft: 'auto' }}
            >
              <IconButton
                onClick={resetInfinityScroll}
                disabled={issuePending || !projectUrn}
                seoEvent={() => {
                  seoEventManager.push({
                    event: 'Gstation_Issues_RefreshList',
                    payload: {
                      activeFiltersCount: filtersCount,
                    },
                  })
                }}
              >
                <Update color='rgba(53, 59, 96, 0.5)' />
              </IconButton>
            </Tooltip>

            <Tooltip title='Выгрузить список замечаний в XLS'>
              <IconButton
                data-test-id='downloadXlsx'
                onClick={() => {
                  selectedProject &&
                    fetchIssueXlsxWithExtraDataCb({
                      project: selectedProject,
                    })
                }}
                seoEvent={() => {
                  const filtersCount = countNonMatchingItems({
                    dictionary: appliedFilters,
                    predicates: [
                      isEmpty,
                      isNil,
                      key => equals(key, DateFilterItemsCode.ALL),
                    ],
                  })

                  seoEventManager.push({
                    event: 'Gstation_Issues_DownloadList',
                    payload: {
                      activeFiltersCount: filtersCount,
                      issuesCount: total,
                    },
                  })
                }}
                disabled={fetchXlsxIssuePending}
              >
                {fetchXlsxIssuePending ? (
                  <CircularProgress size={24} />
                ) : (
                  <Download />
                )}
              </IconButton>
            </Tooltip>

            <Button
              data-test-id='createIssue'
              onClick={() => setOpenCreateIssuePopup(true)}
              leftIcon={<PlusCircle width='24px' height='24px' />}
              seoEvent={() =>
                seoEventManager.push({
                  event: 'Gstation_Issues_Issue_CreateButtonClick',
                  payload: {
                    interface: interfaceName.issueManagement,
                  },
                })
              }
              disabled={issuePending}
            >
              Создать замечание
            </Button>
          </ActionPanel>

          {!issuePending && <ContextMenu />}
          <SortContextMenu />

          <InfiniteScroll
            hasMore={tableList.length !== 0 && listIsNotOver}
            next={() => setOffset(prevValue => prevValue + limit)}
            triggersObserve={[tableList]}
          >
            <Table
              columns={columns}
              rows={tableList}
              tableService={issueTableService}
              onRowCtxMenu={(e, item) => {
                e.preventDefault()

                setCtxMenu({
                  coords: { x: e.clientX, y: e.clientY },
                  item: { item, event: e },
                })
              }}
              totalRows={total || 0}
              pending={issuePending}
              activeRowKey={ctxMenu.item?.item.id}
              getRowKey={row => row.id}
              onChangeColumns={setColumns}
              onClick={(_, item) => {
                const prev = qs.parse(window.location.search)
                const search = qs.stringify({ ...prev, [ISSUE_ID]: item.id })
                navigate({ search })

                seoEventManager.push({
                  event: 'Gstation_Issues_Issue_Open',
                  payload: {
                    interface: interfaceName.issueManagement,
                    page: Math.floor((tableList.length - limit) / limit + 1),
                  },
                })
              }}
              isEnabledCol={isEnabledCol}
              isLoadingCol={isLoadingCol}
              isSelectedCol={isSorted}
              renderTh={renderTh}
              seoEvent={seoColVisibilityEventHandler}
              data-test-id='issueList'
              trProps={rowKey => ({
                'data-test-id': `issueListItem_${rowKey}`,
              })}
            />
          </InfiniteScroll>
        </TableContent>
      </TableContentContainer>
    </TabContainer>
  )
}

type IssueTableRow = Omit<PreparedIssue, 'status' | 'assignees' | 'author'> & {
  assignees: AssigneeListItem[]
  author?: smApi.Auth.UserData
  status?: PreparedIssueStatus
  subscriptionPublicId?: string
}

const columns: TableColumn<IssueTableRow>[] = [
  {
    field: 'id',
    name: 'ID',
    type: 'number',
    visible: true,
  },
  {
    field: 'status',
    name: 'Статус',
    visible: true,
    style: {
      width: '145px',
    },
    renderCell: ({ row }) => (
      <StatusWrapper>
        <StatusContainer>
          <StatusIndicator
            style={{
              background: row.status?.color,
            }}
          />
          <span>{row.status?.name}</span>
        </StatusContainer>
        {row?.subscriptionPublicId && (
          <StyledEye color='rgba(53, 59, 96, 0.5)' />
        )}
      </StatusWrapper>
    ),
  },
  {
    field: 'name',
    name: 'Название',
    visible: true,
    cellStyle: { width: 'auto' },
    style: { width: 'auto' },
    renderCell: ({ row }) => (
      <>
        {row.name.length > 25 ? (
          <Tooltip placement='bottom' title={row.name}>
            <>{row.name.substring(0, 25)}...</>
          </Tooltip>
        ) : (
          row.name
        )}
      </>
    ),
  },
  {
    name: 'Атрибуты',
    visible: true,
    field: 'attributes',
    renderCell: ({ row }) => {
      if (!row.attributes) {
        return null
      }

      return (
        <RenderAttributes
          attributeValueIds={row.attributes.flatMap(({ valueIds }) => valueIds)}
          attributeValueById$={attributesService.attributeValueById$}
        />
      )
    },
    cellStyle: { overflow: 'hidden' },
    style: { width: '155px' },
  },
  {
    renderCell: ({ row }) => {
      let result: ReactNode = null

      if (row.linkedEntity === null) {
        result = 'Не привязано'
      }

      if (row.linkedEntity) {
        result = (
          <LinkedEntityTableItem>
            <LinkedEntityTableItemName>
              {row.linkedEntity.moduleName} /
            </LinkedEntityTableItemName>
            <Tooltip
              styleContent={{ display: 'inline-block' }}
              title={`Открыть в новом окне ${row.linkedEntity.moduleName}/${row.linkedEntity.entityName}`}
            >
              <AnchorLink
                onClick={event => {
                  window.open(row.linkedEntity?.link, '_blank')
                  event.preventDefault()
                  event.stopPropagation()
                }}
                style={{ paddingLeft: '3px', whiteSpace: 'nowrap' }}
              >
                {row.linkedEntity.entityName.length > 10
                  ? `${row.linkedEntity.entityName.substring(0, 10)}...`
                  : row.linkedEntity.entityName}
              </AnchorLink>
            </Tooltip>
          </LinkedEntityTableItem>
        )
      }
      return result
    },
    style: {
      width: '230px',
    },
    cellStyle: { width: '230px' },
    name: 'Привязано к',
    field: 'linkedEntity',
    visible: true,
  },
  {
    renderCell: ({ row }) => (
      <div data-test-id='createDate'>
        {moment(row.createdAt).format(DEFAULT_DISPLAY_DATE_FORMAT)}
      </div>
    ),
    name: 'Создан',
    field: 'createdAt',
    visible: true,
  },
  {
    renderCell: ({ row }) =>
      row.updatedAt ? (
        <div data-test-id='updatedDate'>
          {moment(row.updatedAt).format(DEFAULT_DISPLAY_DATE_FORMAT)}
        </div>
      ) : (
        'Не изменялось'
      ),
    name: 'Изменен',
    field: 'updatedAt',
    visible: true,
  },
  {
    renderCell: ({ row }) => {
      const isIssueClosedInTime =
        row.status?.status === api.IssueStatus.Enum.CLOSED &&
        moment(row.deadline, moment.ISO_8601)
          .startOf('d')
          .add(1, 'd')
          .diff(moment(row.updatedAt, moment.ISO_8601), 'day', true) > 0

      const cellValue = row.deadline
        ? moment(row.deadline).format(DEFAULT_DISPLAY_DATE_FORMAT)
        : 'Не указан'

      if (isIssueClosedInTime) {
        return <span>{cellValue}</span>
      }

      const daysNumberBeforeOrAfterDeadline =
        getDaysNumberBeforeOrAfterDeadline(row.deadline)

      const deadlineStatus = getDeadlineStatus(daysNumberBeforeOrAfterDeadline)

      const deadlineStyle = getDeadlineStyle(deadlineStatus)

      const deadlineBadgeText = getDeadlineNameDay(
        daysNumberBeforeOrAfterDeadline,
      )

      return (
        <div data-test-id='deadlineDate'>
          <span>{cellValue}</span>
          {deadlineStatus && deadlineStatus !== 'ok' && (
            <Tooltip title='Данный срок рассчитан без учета сегодняшней даты'>
              <Tag text={deadlineBadgeText} color={deadlineStyle?.color} />
            </Tooltip>
          )}
        </div>
      )
    },
    name: 'Срок исполнения',
    field: 'deadline',
    visible: true,
    cellStyle: ({ row }) => {
      const isIssueClosedInTime =
        row.status?.status === api.IssueStatus.Enum.CLOSED &&
        moment(row.deadline, moment.ISO_8601)
          .startOf('d')
          .add(1, 'd')
          .diff(moment(row.updatedAt, moment.ISO_8601), 'day', true) > 0

      if (isIssueClosedInTime) {
        return {}
      }

      const daysNumberBeforeOrAfterDeadline =
        getDaysNumberBeforeOrAfterDeadline(row.deadline)

      const deadlineStatus = getDeadlineStatus(daysNumberBeforeOrAfterDeadline)

      const deadlineStyle = getDeadlineStyle(deadlineStatus)

      return {
        ...deadlineStyle,
        ...(deadlineStatus && deadlineStatus !== 'ok'
          ? {
              display: 'flex',
              'flex-direction': 'column',
              'align-items': 'flex-start',
            }
          : {}),
      }
    },
  },
  {
    field: 'assignees',
    renderCell: ({ row }) => {
      if (!row.assignees.length) {
        return <>{'Не назначен'}</>
      }
      return <RenderAssignees assignees={row.assignees} />
    },
    name: 'Назначено на',
    visible: true,
    cellStyle: { width: '145px', overflow: 'hidden' },
    style: { width: '155px' },
  },
  {
    field: 'author',
    renderCell: ({ row }) => {
      if (!row.author) {
        return <>Не найден</>
      }
      return <>{row.author.name}</>
    },
    name: 'Автор',
    visible: true,
    cellStyle: { width: '145px', overflow: 'hidden' },
    style: { width: '155px' },
  },
]

const newKey = 'issueListColumnOrderV2'
const oldKey = 'issueListColumnOrder'

const getIssueListColumnOrderFromStorage = () =>
  getListColumnOrderFromStorage(newKey, oldKey, columns)

const setColumnsToStorage = (next: TableColumn<IssueTableRow>[]) => {
  localStorage.setItem(
    newKey,
    JSON.stringify(next.map(({ field, visible }) => ({ field, visible }))),
  )
}

const subscriptionUnavailableText =
  'Нельзя подписаться на замечания, где вы являетесь автором или ответственным, или подписка уже есть'
const unsubscriptionUnavailableText =
  'Нельзя отписаться от замечаний, где вы являетесь автором или ответственным, или вы не подписаны'
