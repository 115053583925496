import styled, { css } from 'styled-components'

export const FieldHint = styled.div`
  display: flex;
  gap: 0 4px;
  margin-top: 8px;
  margin-left: 10px;
  && {
    svg {
      min-width: 20px;
    }
  }
`

type FieldHintTextProps = {
  transparent?: boolean
}

export const FieldHintText = styled.div`
  font-size: 13px;
  line-height: 20px;
  font-family: Roboto, sans-serif;
  color: #000335;
  font-weight: 400;

  ${(props: FieldHintTextProps) =>
    props.transparent &&
    css`
      opacity: 0.6;
    `}
`
