import styled from 'styled-components'

import { ArrowThinUp, ArrowThinDown } from '@gmini/ui-kit'

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0 5px;
  cursor: pointer;
`

export const Text = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #80819a;
  letter-spacing: 0.15px;
`

export const ArrowUp = styled(ArrowThinUp)`
  path {
    fill: #80819a;
  }
`

export const ArrowDown = styled(ArrowThinDown)`
  path {
    fill: #80819a;
  }
`
