import styled from 'styled-components'
import { IconButton } from '@gmini/ui-kit'

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 6px;
`

export const SavedFilterWrapper = styled.div`
  padding: 16px 24px;
  border-bottom: 1px solid #e5e7f1;
  &:hover {
    background-color: rgba(0, 5, 100, 0.04);
    cursor: pointer;
  }
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
export const Title = styled.p`
  font-size: 15px;
  line-height: 18px;
  margin: 0 0 20px 0;
`

export const FilterActions = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

export const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`

export const StatusContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`

export const Status = styled.div`
  display: flex;
  align-items: center;
`

type StatusColorProps = {
  color: string
}

export const StatusColor = styled.div`
  width: 4px;
  height: 18px;
  margin-right: 6px;
  border-radius: 4px;
  background-color: ${({ color }: StatusColorProps) => color};
`

export const Text = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 61, 0.6);
`

export const StyledIconButton = styled(IconButton)`
  &:hover path {
    fill: rgba(53, 59, 96, 1);
  }
`
