import { Button, ScrollBar } from '@gmini/ui-kit'

import { RESET_ALL } from '@gmini/helpers'

import { SeoEventManager } from '@gmini/common'

import { Bubble } from '../../atoms'

import {
  AppliedFiltersScrollableContent,
  AppliedFiltersScrollableWrapper,
  ResetAllButtonWrapper,
} from './AppliedFilters.styled'

import { AppliedFilter } from './AppliedFilters.types'

type AppliedFiltersProps<T> = {
  items: Array<T>
  seoEventManager?: SeoEventManager
  onChange?: (
    filter: Record<string, string | (string | number)[] | null>,
  ) => void
}

export const AppliedFilters = <T extends AppliedFilter>({
  items,
  seoEventManager,
  onChange,
}: AppliedFiltersProps<T>) => {
  const onResetAll = () => {
    seoEventManager?.push({
      event: 'Gstation_Issues_Filter_ClearAllFilters',
      payload: {},
    })
    onChange?.({ [RESET_ALL]: null })
  }

  const onResetFilter = (item: T) => {
    if (Array.isArray(item.urlKey)) {
      item.urlKey.forEach(key => onChange?.({ [key]: item.value }))
    } else {
      onChange?.({ [item.urlKey]: item.value })
    }

    seoEventManager?.push({
      event: 'Gstation_Issues_Filter_DeleteFilter',
      payload: {
        filterName: item.name,
        activeFiltersCount: items.length - 1,
      },
    })
  }

  return (
    <AppliedFiltersScrollableWrapper>
      <ScrollBar contentStyled={AppliedFiltersScrollableContent}>
        {items.map((item, idx) => (
          <Bubble
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            title={item.name}
            value={item.value}
            onResetFilter={onResetFilter ? () => onResetFilter(item) : null}
          />
        ))}
        <ResetAllButtonWrapper hidden={!onResetAll || items.length < 2}>
          <Button onClick={onResetAll} color='tertiary'>
            Очистить фильтры
          </Button>
        </ResetAllButtonWrapper>
      </ScrollBar>
    </AppliedFiltersScrollableWrapper>
  )
}
