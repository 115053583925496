import { useMemo } from 'react'

import { v4 as uuid } from 'uuid'

import { DateFilterItemsCode } from '@gmini/helpers'

import { FilterItem, FilterWrapper } from '../../atoms'

import { FilterDateOption } from './FilterDate.types'

import { filterDateItems } from './filterDateItems'

type DataTestProps = {
  filterItem: { name: string }
}

type FilterStatusProps<T> = {
  allowedRange: [string, string] | null
  urlKeyCode: string
  urlKeyValue: string
  disabled?: boolean
  loading?: boolean
  countSkeletonItems?: number
  onChange: (value: Record<string, string>) => void
  value: T | null
  options?: FilterDateOption[]
  dataTestIdFor?: Partial<DataTestProps>
}

export const FilterDate = <T,>({
  allowedRange,
  urlKeyCode,
  urlKeyValue,
  disabled,
  loading,
  countSkeletonItems,
  onChange,
  value,
  options,
  dataTestIdFor,
}: FilterStatusProps<T>) => {
  // const dateFilterValueFromUrl = query.getArray(urlKeyValue) as [string, string] //TODO вернуть, когда добавлю datePicker

  const preparedFilterDateItems = useMemo(() => {
    const currentDate = new Date()

    return (options ? options : filterDateItems(currentDate)).map(item => {
      let selected = value === item.code

      if (item.code === DateFilterItemsCode.ALL && !value) {
        selected = true
      }

      return {
        ...item,
        uniqId: `${uuid()}__${urlKeyCode}`,
        selected,
        disabled: disabled || !allowedRange,
      }
    })
  }, [options, value, urlKeyCode, disabled, allowedRange])

  const filterName = dataTestIdFor?.filterItem?.name
  return (
    <FilterWrapper
      loading={!preparedFilterDateItems.length && loading}
      countSkeletonItems={countSkeletonItems}
    >
      {preparedFilterDateItems.map(item => (
        <FilterItem
          key={item.uniqId}
          item={item}
          onChange={el => onChange({ [urlKeyCode]: el.code })}
          component='radio'
          dataTestId={
            filterName && `filter${filterName}By_${item.code.toLowerCase()}`
          }
        />
      ))}
      {value === DateFilterItemsCode.CUSTOM && (
        // eslint-disable-next-line no-empty-function
        <div onClick={() => {}}></div>
      )}
    </FilterWrapper>
  )
}
