import styled from 'styled-components'

export const TooltipTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  margin-bottom: 4px;
`

export const TooltipTitleText = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  margin-left: 4px;
`

export const TooltipItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px 0;
`

export const TooltipItem = styled.div`
  display: flex;
  gap: 0 4px;
`

export const TooltipItemValue = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
`

export const TooltipItemName = styled(TooltipItemValue)`
  color: rgba(255, 255, 255, 0.5);
`

export const ItemAttributeValueWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: #00003d;
`
