type ColumnSettings = {
  field: string
  visible: boolean
}

const insertFieldInCorrectOrder = (
  columnSettings: ColumnSettings[],
  anchorFieldName: string = 'name',
  fieldToInsertName: string = 'attributes',
): ColumnSettings[] => {
  const fieldToinsert = columnSettings.find(
    ({ field }) => field === fieldToInsertName,
  )

  return columnSettings.reduce(
    (acc: ColumnSettings[], column: ColumnSettings) => {
      if (fieldToinsert && column.field === anchorFieldName) {
        return [...acc, column, fieldToinsert]
      }

      if (column.field === fieldToInsertName) {
        return acc
      }

      return [...acc, column]
    },
    [],
  )
}

const proccessingColumns = <T extends { field: string }>(
  columns: T[],
  parsedData: ColumnSettings[],
): T[] =>
  columns
    .slice()
    .sort((a, b) => {
      const aIdx = parsedData.findIndex(s => s.field === a.field)
      const bIdx = parsedData.findIndex(s => s.field === b.field)

      // В случае если в localStorage не было настройки колонки (например: в коде добавили новую)
      if (aIdx < 0 || bIdx < 0) {
        return 0
      }

      return aIdx - bIdx
    })
    .map(col => {
      const colFromLocalStorage = parsedData.find(
        ({ field }) => field === col.field,
      )
      return {
        ...col,
        visible:
          colFromLocalStorage === undefined
            ? true
            : colFromLocalStorage.visible,
      }
    })

export const getListColumnOrderFromStorage = <T extends { field: string }>(
  newKey: string,
  oldKey: string,
  columns: T[],
): T[] => {
  const data = localStorage.getItem(newKey)

  if (typeof data === 'string') {
    try {
      const parsedData = JSON.parse(data) as ColumnSettings[]
      return proccessingColumns(columns, parsedData)
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
    }
  } else {
    // TODO: Удалить после перехода всех старых пользователей на новую версию ключа
    const oldData = localStorage.getItem(oldKey)
    if (typeof oldData === 'string') {
      try {
        const parsedData = JSON.parse(oldData) as ColumnSettings[]
        const updatedOrderData = insertFieldInCorrectOrder(parsedData)
        localStorage.removeItem(oldKey)

        return proccessingColumns(columns, updatedOrderData)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err)
      }
    }
  }

  return columns
}
