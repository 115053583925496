import styled from 'styled-components'

export const ClickableImageName = styled.div`
  cursor: pointer;
  color: #4c5ecf;
  max-width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
