import { ReactNode, useEffect } from 'react'

import { WithHeader } from '@gmini/components'

import { getModulesLinkFromEnv, useNavbar } from '@gmini/common'

import { Tabs } from '@gmini/ui-kit'

import { queryParser } from '@gmini/helpers'

import * as smApi from '@gmini/sm-api-sdk'

import { envLinks, seoEventManager } from '../../../config'

import { PROJECT_URN } from '../../../constants'
import { notificationService } from '../../../services/omniNotificationsService'
import { attributesService } from '../../attribute.store'
import { fetchCompanyList } from '../../company.store'
import { fetchStatusList } from '../../issueStatus.store'
import { fetchRoleList } from '../../role.store'
import { fetchAllUserList, fetchProjectUserList } from '../../user.store'

type IssueManagerWrapperProps = {
  children: ReactNode | ReactNode[]
  loading?: boolean
  brandSecondary?: ReactNode
  selectedProject: smApi.Project | null
}

export const IssueManagerWrapper = ({
  children,
  loading,
  brandSecondary,
  selectedProject,
}: IssueManagerWrapperProps) => {
  const projectUrn = queryParser({ key: PROJECT_URN }) as string

  useEffect(() => {
    fetchRoleList.submit()
    fetchCompanyList.submit()
    fetchAllUserList.submit({})
    fetchStatusList.submit()
    attributesService.fetchAttributeList({ projectUrn })
  }, [projectUrn])

  useEffect(() => {
    if (projectUrn) {
      fetchProjectUserList.submit({ projectUrn })
    }
  }, [projectUrn])

  const { NavbarDropDown, opened } = useNavbar({
    navModules: getModulesLinkFromEnv(
      envLinks,
      projectUrn,
      selectedProject?.sourceType,
    ),
    seoEventHandler: (selectedExplorer: string) => {
      seoEventManager.push({
        event: 'ExplorersNavigation_SelectExplorer',
        payload: {
          originalExplorer: 'Замечания',
          selectedExplorer,
        },
      })
    },
  })

  const onChangeTab = (tabIndex: number) => {
    try {
      const links = JSON.parse(envLinks)

      if (tabIndex === 1 || !links.chm) {
        return
      }

      window.location.href = `https://${links.chm}${
        projectUrn ? `?projectUrn=${projectUrn}` : ''
      }`
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <WithHeader
      NavbarDropDown={NavbarDropDown}
      openedNavbarDropDown={opened}
      loading={loading}
      brandSecondary={brandSecondary}
      Tabs={
        <Tabs
          onChangeTab={onChangeTab}
          activeTabIndex={1}
          headerStyles={{
            fontSize: '14px',
            height: '49px',
            gap: 19,
            marginTop: 'auto',
            marginBottom: '-1px',
            borderBottom: 'none',
          }}
          contentStyles={{
            height: '100%',
            paddingTop: '0',
            display: 'flex',
          }}
          tabs={[
            {
              title: 'Чек-листы',
              content: null,
            },
            {
              title: 'Замечания',
              content: null,
            },
          ]}
        />
      }
      title='Замечания'
      notificationService={notificationService}
    >
      {children}
    </WithHeader>
  )
}
