import moment from 'moment'
import { useMemo } from 'react'

type useAppliedFilterDateDataProps<T> = {
  urlKeyCode: string
  dateFormat: string
  name: string
  value: T[]
  valueName: string
}

export const useAppliedFilterDateData = <T,>({
  urlKeyCode,
  dateFormat,
  name,
  value,
  valueName,
}: useAppliedFilterDateDataProps<T>) =>
  useMemo(() => {
    const data = {
      name,
      urlKey: urlKeyCode,
      value: [],
    }
    if (!value.length) {
      return data
    }
    const [startDate, endDate] = value

    if (endDate && startDate) {
      return {
        name,
        urlKey: urlKeyCode,
        value: [
          `${moment(startDate).format(dateFormat)} - ${moment(endDate).format(
            dateFormat,
          )}`,
        ],
      }
    }

    return {
      ...data,
      value: [valueName],
    }
  }, [dateFormat, name, urlKeyCode, value, valueName])
