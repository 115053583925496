import { createStore } from 'effector'

import * as api from '@gmini/ism-api-sdk'

import {
  PreparedIssueStatus,
  defaultIssueStatusColor,
  defaultIssueStatusTextColor,
  hoveredDefaultIssueStatusColor,
  hoveredIssueStatusBgColorMap,
  issueStatusBgColorMap,
  issueStatusTextColorMap,
} from '@gmini/helpers'

export const fetchStatusList = api.IssueStatus.fetchList.createContext()
export const fetchStatusListPending$ = fetchStatusList.pending$

export const issueStatusList$ = createStore<PreparedIssueStatus[]>([]).on(
  fetchStatusList.doneData,
  (state, result) =>
    result.map(item => {
      const color =
        issueStatusBgColorMap[item.status] || defaultIssueStatusColor

      return {
        ...item,
        bgColor: color,
        hoveredBgColor:
          hoveredIssueStatusBgColorMap[item.status] ||
          hoveredDefaultIssueStatusColor,
        textColor:
          issueStatusTextColorMap[item.status] || defaultIssueStatusTextColor,
        color,
      }
    }),
)
