import styled from 'styled-components'

export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 13px;
  font-family: Roboto, sans-serif;
  flex-grow: 1;
  min-height: 0;
`
