import { Dispatch, SetStateAction, KeyboardEvent } from 'react'

export type RenderEditModeProps = {
  mode: Mode
  setMode: Dispatch<SetStateAction<Mode>>
  handlers: { onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void }
}

export enum Mode {
  preview = 'preview',
  edit = 'edit',
}
