import { IssueAnswer, IssueStatus } from '@gmini/ism-api-sdk'

export enum MapQueryBy {
  UrlKey,
  StoreKey,
}

export enum DateFilterItemsCode {
  ALL = 'ALL',
  LAST_WEEK = 'LAST_WEEK',
  LAST_2_WEEK = 'LAST_2_WEEK',
  LAST_MONTH = 'LAST_MONTH',
  CUSTOM = 'CUSTOM',
}

export enum IssueDateFilter {
  ALL = 'За весь период',
  ONE_WEEK_BEFORE = 'За последнюю неделю',
  TWO_WEEKS_BEFORE = 'За последние 2 недели',
  ONE_MONTH_BEFORE = 'За последний месяц',
  CUSTOM = 'Выбрать даты',
}

export enum IssueDeadlineFilter {
  ALL = 'Все',
  ONE_WEEK_BEFORE = 'Приближающийся срок',
  TODAY = 'Только просроченные',
}

export enum IssueListFilterDeadlineOptionCode {
  ALL = 'ALL',
  ALMOST_EXPIRED = 'ALMOST_EXPIRED',
  ONLY_EXPIRED = 'ONLY_EXPIRED',
}

export enum ExpiredDateFilterCode {
  ALL = '300 years before,300 years after',
  ONE_WEEK_BEFORE = '1 week before,now',
  TWO_WEEKS_BEFORE = '2 weeks before,now',
  ONE_MONTH_BEFORE = '1 month before,now',
  CUSTOM = 'Custom',
}

export enum ExpiredDeadlineFilter {
  ALL = '300 years before,300 years after',
  ONE_WEEK_BEFORE = '1 week after,now',
  TODAY = 'today',
}

export const issueStatusBgColorMap = {
  [IssueStatus.Enum.CLOSED]: '#0BBB7B',
  [IssueStatus.Enum.DRAFT]: '#E5E7F1',
  [IssueStatus.Enum.IN_PROCESS]: '#F5A623',
  [IssueStatus.Enum.NOT_RELEVANT]: '#E5E7F1',
  [IssueStatus.Enum.NOT_STARTED]: '#A2A3B7',
  [IssueStatus.Enum.ON_REVIEW]: '#4C5ECF',
}

export const defaultIssueStatusColor = '#A2A3B7'

export const hoveredIssueStatusBgColorMap = {
  [IssueStatus.Enum.CLOSED]: '#08A069',
  [IssueStatus.Enum.DRAFT]: '#CED1E3',
  [IssueStatus.Enum.IN_PROCESS]: '#E29411',
  [IssueStatus.Enum.NOT_RELEVANT]: '#CED1E3',
  [IssueStatus.Enum.NOT_STARTED]: '#8A8CAF',
  [IssueStatus.Enum.ON_REVIEW]: '#3144BB',
}

export const hoveredDefaultIssueStatusColor = '#8A8CAF'

export const issueStatusTextColorMap = {
  [IssueStatus.Enum.CLOSED]: '#fff',
  [IssueStatus.Enum.DRAFT]: '#353B60',
  [IssueStatus.Enum.IN_PROCESS]: '#fff',
  [IssueStatus.Enum.NOT_RELEVANT]: '#353B60',
  [IssueStatus.Enum.NOT_STARTED]: '#fff',
  [IssueStatus.Enum.ON_REVIEW]: '#fff',
}

export const issueStatusTextMap = {
  [IssueStatus.Enum.CLOSED]: 'Завершен',
  [IssueStatus.Enum.DRAFT]: 'Черновик',
  [IssueStatus.Enum.IN_PROCESS]: 'В работе',
  [IssueStatus.Enum.NOT_RELEVANT]: 'Не актуально',
  [IssueStatus.Enum.NOT_STARTED]: '#Не начат',
  [IssueStatus.Enum.ON_REVIEW]: 'На проверке',
}

export const defaultIssueStatusTextColor = '#fff'

export const issueAnswerStatusDescMap: Record<IssueAnswer.StatusEnum, string> =
  {
    [IssueAnswer.StatusEnum.DECLINED]: 'отклонен',
    [IssueAnswer.StatusEnum.ACCEPTED]: 'принят',
    [IssueAnswer.StatusEnum.PENDING]: 'ожидает принятия',
    [IssueAnswer.StatusEnum.DELETED]: 'удален',
  }

export const relativeDateFilterMap = {
  [DateFilterItemsCode.ALL]: ExpiredDateFilterCode.ALL,
  [DateFilterItemsCode.LAST_WEEK]: ExpiredDateFilterCode.ONE_WEEK_BEFORE,
  [DateFilterItemsCode.LAST_2_WEEK]: ExpiredDateFilterCode.TWO_WEEKS_BEFORE,
  [DateFilterItemsCode.LAST_MONTH]: ExpiredDateFilterCode.ONE_MONTH_BEFORE,
  [DateFilterItemsCode.CUSTOM]: ExpiredDateFilterCode.CUSTOM,
}

export const relativeDeadlineFilterMap = {
  [IssueListFilterDeadlineOptionCode.ALL]: ExpiredDeadlineFilter.ALL,
  [IssueListFilterDeadlineOptionCode.ALMOST_EXPIRED]:
    ExpiredDeadlineFilter.ONE_WEEK_BEFORE,
  [IssueListFilterDeadlineOptionCode.ONLY_EXPIRED]: ExpiredDeadlineFilter.TODAY,
}

export const serverSideDateFilterMap = {
  [ExpiredDateFilterCode.ALL]: IssueDateFilter.ALL,
  [ExpiredDateFilterCode.ONE_WEEK_BEFORE]: IssueDateFilter.ONE_WEEK_BEFORE,
  [ExpiredDateFilterCode.TWO_WEEKS_BEFORE]: IssueDateFilter.TWO_WEEKS_BEFORE,
  [ExpiredDateFilterCode.ONE_MONTH_BEFORE]: IssueDateFilter.ONE_MONTH_BEFORE,
  [ExpiredDateFilterCode.CUSTOM]: IssueDateFilter.CUSTOM,
}

export const serverSideDeadlineFilterMap = {
  [ExpiredDeadlineFilter.ALL]: IssueDeadlineFilter.ALL,
  [ExpiredDeadlineFilter.ONE_WEEK_BEFORE]: IssueDeadlineFilter.ONE_WEEK_BEFORE,
  [ExpiredDeadlineFilter.TODAY]: IssueDeadlineFilter.TODAY,
}

export const appliedDateFilterItemsCodesMap = {
  [DateFilterItemsCode.ALL]: IssueDateFilter.ALL,
  [DateFilterItemsCode.LAST_WEEK]: IssueDateFilter.ONE_WEEK_BEFORE,
  [DateFilterItemsCode.LAST_2_WEEK]: IssueDateFilter.TWO_WEEKS_BEFORE,
  [DateFilterItemsCode.LAST_MONTH]: IssueDateFilter.ONE_MONTH_BEFORE,
  [DateFilterItemsCode.CUSTOM]: IssueDateFilter.CUSTOM,
}

export const appliedDeadlineOptionCodeMap = {
  [IssueListFilterDeadlineOptionCode.ALL]: IssueDeadlineFilter.ALL,
  [IssueListFilterDeadlineOptionCode.ALMOST_EXPIRED]:
    IssueDeadlineFilter.ONE_WEEK_BEFORE,
  [IssueListFilterDeadlineOptionCode.ONLY_EXPIRED]: IssueDeadlineFilter.TODAY,
}
