import styled from 'styled-components'

export const FilterUserWrapper = styled.div`
  padding-right: 10px;
`

export const Separator = styled.div`
  background: #e5e7f1;
  height: 1px;
  width: calc(100% - 48px);
  margin: 10px auto;
`
