import * as api from '@gmini/chm-api-sdk'
import { assertNever } from '@gmini/utils'
import { Tooltip, AllPeople, Contractor, LongText } from '@gmini/ui-kit'
import { AssigneeListItem } from '@gmini/helpers'

import { AvatarIcon } from '../AvatarIcon'

import {
  Bubble,
  Count,
  TooltipContainer,
  TooltipIconSubstrateCircle,
  TooltipItem,
  TooltipIconSubstrateSquare,
} from './AssigneeCounter.styled'

type AssigneeCounterProps = {
  assignees: AssigneeListItem[]
  isCrossOutedTooltipText?: (assignee: AssigneeListItem) => boolean
}

export const AssigneeCounter = ({
  assignees,
  isCrossOutedTooltipText,
}: AssigneeCounterProps) => (
  <Tooltip
    title={
      <TooltipContainer>
        {assignees.map((assignee, idx) => {
          let icon = null

          switch (assignee.source) {
            case api.AssigneeSource.USER: {
              icon = (
                <TooltipIconSubstrateCircle>
                  <AvatarIcon color='#fff' width='11px' />
                </TooltipIconSubstrateCircle>
              )
              break
            }
            case api.AssigneeSource.ROLE: {
              icon = (
                <TooltipIconSubstrateCircle>
                  <AllPeople color='#fff' width='12px' />
                </TooltipIconSubstrateCircle>
              )
              break
            }
            case api.AssigneeSource.COMPANY: {
              icon = (
                <TooltipIconSubstrateSquare>
                  <Contractor color='#fff' width='12px' />
                </TooltipIconSubstrateSquare>
              )
              break
            }
            default: {
              return assertNever('Unexpected assignee source', assignee.source)
            }
          }
          return (
            <TooltipItem
              crossOuted={isCrossOutedTooltipText?.(assignee)}
              // eslint-disable-next-line react/no-array-index-key
              key={idx}
            >
              {icon}
              <LongText partSize={20} withoutRightText>
                {assignee.label}
              </LongText>
            </TooltipItem>
          )
        })}
      </TooltipContainer>
    }
  >
    <Bubble>
      <Count>+{assignees.length}</Count>
    </Bubble>
  </Tooltip>
)
