import {
  CheckMark as CheckMarkInit,
  GTechSimpleLogo as GTechSimpleLogoInit,
  IconButton,
  OpenInBrowser,
  Avatar,
  Eye,
  Button,
} from '@gmini/ui-kit'
import { LinearProgress as LinearProgressInit } from '@gmini/ui-kit/lib/LinearProgress'
import { CSSProperties } from 'react'
import styled, { css } from 'styled-components'

type SideProps = {
  styles: CSSProperties | undefined
  id?: string
}

export const Container = styled.div`
  display: flex;
  position: relative;
  height: 100%;

  ${({ styles }: SideProps) => ({ ...(styles ? styles : {}) })};
`

export const BaseContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px 0;
  flex: 1;
  padding: 30px 0 0 24px;
  min-width: 400px;

  ${({ styles }: SideProps) => ({ ...(styles ? styles : {}) })};
`
type SwitchIssuesProps = {
  small: boolean
}
export const SwitchIssues = styled.div`
  padding-right: 30px;
  display: flex;
  justify-content: ${({ small }: SwitchIssuesProps) =>
    small ? 'flex-end' : 'space-between'};
`

export const LeftSideContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-right: 30px;
  /* max-height: 80vh; */
  padding-bottom: 30px;
`

export const ShortInfoBar = styled.div`
  display: flex;
  align-items: center;
`
export const AdditionalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 1px solid #e5e7f1;
  min-width: 300px;

  ${({ styles }: SideProps) => ({ ...(styles ? styles : {}) })};
`

export const RightSideContent = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  gap: 32px 0;
`

export const FieldName = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.15px;
  color: #00003d;
  margin-bottom: 16px;
`

export const AttachmentFieldName = styled(FieldName)`
  margin-bottom: 0;
`

export const AdditionalFieldName = styled(FieldName)`
  margin-bottom: 7px;
`

export const AdditionalFieldNameCapitalized = styled(AdditionalFieldName)`
  text-transform: capitalize;
`

export const Subtitle = styled.div`
  display: flex;
  gap: 0 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #00003d;
  margin-right: 4px;
`

export const AttachmentTitlePanel = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
`

export const AttachmentCount = styled(AttachmentFieldName)`
  opacity: 0.6;
  font-weight: 500;
`

export const AttachIconsWrapper = styled.div`
  flex: 1;
  display: flex;
  height: 36px;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
`

export type SimpleScrollbarProps = {
  isCompact?: boolean
  compactHeight?: number
  height?: number
}

export const SimpleScrollbar = styled.div`
  overflow: auto;
  position: relative;

  ${({ isCompact, compactHeight, height }: SimpleScrollbarProps) =>
    isCompact
      ? css`
          max-height: ${compactHeight}px;
        `
      : css`
          max-height: ${height}px;
        `}

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #e5e7f1;
    border-radius: 30px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }
`

export const LinearProgress = styled(LinearProgressInit)`
  && {
    border-radius: 4px 4px 0 0;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
`

export const CopyPathIssueButton = styled(IconButton)`
  margin-right: 12px;

  && svg path {
    fill: rgba(
      53,
      59,
      96,
      0.8
    ); //TODO разобраться с тем почему 0.5 opacity на самой иконке визуально выглядит как 0.2
  }
`

export const ActivityWrapper = styled.div`
  max-height: 300px;
`

export const CheckMark = styled(CheckMarkInit)`
  && {
    & svg path {
      fill: #42ab85;
    }
  }
`

export const ShowAllWrapper = styled.div`
  display: flex;
  height: 36px;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
`

export const DownloadButton = styled(Button)`
  width: 124px;
  height: 36px;
`

export const DeleteButton = styled(DownloadButton)`
  width: 124px;
  height: 36px;
`

export const GTechSimpleLogo = styled(GTechSimpleLogoInit)``

type LinkedEntityValueProps = {
  disabled: boolean
}

export const LinkedEntityValue = styled.div`
  display: flex;
  gap: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #00003d;
  letter-spacing: 0.15px;

  ${(props: LinkedEntityValueProps) =>
    props.disabled &&
    css`
      opacity: 0.4;
    `}

  ${GTechSimpleLogo} {
    min-width: 18px;
    width: 18px;
    height: 18px;
  }
`

export const LinkedEntityValueText = styled.div`
  display: grid;
`

export const OpenInBrowserButton = styled(OpenInBrowser)`
  cursor: pointer;

  & path {
    fill: rgba(53, 59, 96, 0.7);
  }

  &:hover {
    & path {
      fill: rgba(53, 59, 96, 1);
    }
  }
`

export const DeadlineEditMode = styled.div`
  && {
    legend {
      display: none;
    }
    .MuiOutlinedInput-notchedOutline {
      top: 0;
    }
  }
`
export const DeadlineWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const EndAdornmentWrapper = styled.div`
  display: inline-block;
  margin-left: 8px;
`

type ItemValueProps = {
  size?: 'small' | 'medium'
  m?: string
}

export const ItemValue = styled.div`
  display: flex;
  align-items: center;
  gap: 0 2px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: #00003d;

  ${(props: ItemValueProps) =>
    props.size === 'medium' &&
    css`
      font-size: 14px;
    `}

  ${(props: ItemValueProps) =>
    props.m &&
    css`
      margin: ${props.m};
    `}
`

export const ItemAttributeValueWrapper = styled(ItemValue)`
  display: flex;
  flex-flow: row wrap;
  gap: 4px;
`

export const ItemPlaceholder = styled.div`
  color: 'rgba(0, 0, 61, 0.6)';
`

type IssueSubscriptionsWrapperProps = {
  disabled?: boolean
}

export const IssueSubscriptionsWrapper = styled.div`
  position: relative;
  margin-left: 5px;
  ${({ disabled }: IssueSubscriptionsWrapperProps) =>
    disabled
      ? css`
          pointer-events: none;
          filter: opacity(0.3);
        `
      : ''}
`

export const IssueSubscriptionsPopup = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  right: 0;
  border-radius: 4px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  padding: 7px 0;
  background: #fff;
`

type IssueSubscriptionsPopupListItemProps = {
  disabled: boolean
}

export const IssueSubscriptionsPopupListItem = styled.div`
  padding: 6px 16px;
  display: flex;
  gap: 12px;
  align-items: center;
  color: #000335;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.1px;
  white-space: nowrap;
  &:hover {
    cursor: pointer;
    background: rgba(0, 5, 100, 0.04);
  }
  ${({ disabled }: IssueSubscriptionsPopupListItemProps) =>
    disabled
      ? css`
          pointer-events: none;
          opacity: 0.2;
        `
      : ''}
`
export const Divider = styled.div`
  background: #e5e7f1;
  height: 1px;
  margin: 7px 16px;
`

export const IssueSubscribersTitle = styled.div`
  padding: 7.5px 16px;
  color: #000335;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.1px;
  opacity: 0.5;
  white-space: nowrap;
`
export const StyledAvatar = styled(Avatar)`
  width: 24px;
  height: 24px;
`

export const StyledIconButton = styled(IconButton)`
  color: #353b60;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  gap: 4px;
  padding: 7px 8px 5px 7px;
`

type StyledEyeProps = {
  active: boolean
}

export const StyledEye = styled(Eye)`
  && path {
    ${({ active }: StyledEyeProps) =>
      active
        ? css`
            fill: #4c5ecf;
            opacity: 1;
          `
        : ''};
  }
`
