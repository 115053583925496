import { useOnScreen } from '@gmini/helpers'
import { createRef, DependencyList, ReactNode, useEffect, useRef } from 'react'

import { ScrollBar } from '@gmini/ui-kit'

type InfiniteScrollProps = {
  next?: () => void
  children: ReactNode | ReactNode[]
  hasMore?: boolean
  triggersObserve?: DependencyList
  rootMargin?: string
}

const offsetBottomTable = 20
const defaultRootMargin = '400px 0px 400px 0px'

export const InfiniteScroll = ({
  next,
  children,
  hasMore,
  triggersObserve,
  rootMargin,
}: InfiniteScrollProps) => {
  const nextDataRef = createRef<HTMLDivElement>()
  const scrollbarRef = useRef<HTMLDivElement | null>(null)
  const scrolledToEndTable = useOnScreen({
    ref: nextDataRef,
    triggers: triggersObserve,
    observerOptions: {
      rootMargin: rootMargin || defaultRootMargin,
      root: scrollbarRef.current,
    },
  })

  const windowHeight = window.innerHeight
  const windowWidth = window.innerWidth

  const bundingClientRect = scrollbarRef.current?.getBoundingClientRect()

  const scrollHeight =
    windowHeight - Number(bundingClientRect?.top) - offsetBottomTable
  const scrollWidth = windowWidth - Number(bundingClientRect?.left) - 24

  useEffect(() => {
    if (next && scrolledToEndTable) {
      next()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrolledToEndTable])
  return (
    <ScrollBar
      height={scrollHeight ? `${scrollHeight}px` : ''}
      width={scrollWidth ? `${scrollWidth}px` : ''}
      scrollableNodeProps={{ ref: scrollbarRef }}
    >
      {children}
      {hasMore && <div ref={nextDataRef} />}
    </ScrollBar>
  )
}
