import React, { SVGAttributes } from 'react'

export const SubscribeIcon = ({
  color = '#353B60',
  ...otherProps
}: {
  color?: string
} & SVGAttributes<SVGSVGElement>) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...otherProps}
  >
    <g id='ic_subscribe'>
      <path
        id='Vector'
        d='M20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H9C9.55228 20 10 19.5523 10 19C10 18.4477 9.55228 18 9 18H4.5C4.22386 18 4 17.7761 4 17.5V8L11.735 12.8344C11.8971 12.9357 12.1029 12.9357 12.265 12.8344L20 8V12.5C20 13.0523 20.4477 13.5 21 13.5C21.5523 13.5 22 13.0523 22 12.5V6C22 4.9 21.1 4 20 4ZM12.265 10.8344C12.1029 10.9357 11.8971 10.9357 11.735 10.8344L4 6H20L12.265 10.8344Z'
        fill={color}
      />
      <path
        id='Vector_2'
        d='M19.5 19C19.5 19.83 18.83 20.5 18 20.5C17.17 20.5 16.5 19.83 16.5 19C16.5 18.17 17.17 17.5 18 17.5C18.83 17.5 19.5 18.17 19.5 19ZM18 15C15.27 15 12.94 16.66 12 19C12.94 21.34 15.27 23 18 23C20.73 23 23.06 21.34 24 19C23.06 16.66 20.73 15 18 15ZM18 21.5C16.62 21.5 15.5 20.38 15.5 19C15.5 17.62 16.62 16.5 18 16.5C19.38 16.5 20.5 17.62 20.5 19C20.5 20.38 19.38 21.5 18 21.5Z'
        fill={color}
      />
    </g>
  </svg>
)
